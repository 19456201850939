import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import BillboardIndicatorCard from './BillboardIndicatorCard';
import usePrevious from '../../../common/hooks/usePrevious';

const SLIDE_SPEED = 750;

const BillboardThumbnailIndicator = (props, context) => {
  const listRootRef = useRef<HTMLUListElement>(null);
  const prevActivePage = usePrevious(props.activePage);
  const prevItems = usePrevious(props.items);
  const [isLeftEdge, setIsLeftEdge] = useState(false);
  const [isRightEdge, setIsRightEdge] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [animatingCallbackTimerId, setAnimatingCallbackTimerId] = useState(null);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [hovering, setHovering] = useState(false);
  const [clicked, setClicked] = useState(false);

  const onClickCard = useCallback(
    e => {
      props.goToPage(e);
      setClicked(true);
    },
    [props],
  );

  const onClickArrow = useCallback(
    type => {
      return e => {
        e.preventDefault();
        e.stopPropagation();
        if (animating) return;
        setAnimating(true);

        const isLeft = type === 'left';
        const scrollLeft = listRootRef.current.scrollLeft;
        const scrollWidth = listRootRef.current.scrollWidth;
        const clientWidth = listRootRef.current.clientWidth;
        let _scrollWidth = clientWidth;
        if (isLeft) {
          _scrollWidth = scrollLeft > _scrollWidth ? _scrollWidth : scrollLeft;
        } else {
          _scrollWidth =
            scrollWidth > scrollLeft + clientWidth + _scrollWidth
              ? _scrollWidth
              : scrollWidth - (scrollLeft + clientWidth);
        }

        setScrollWidth(isLeft ? _scrollWidth : -_scrollWidth);
        const timerId = setTimeout(() => {
          listRootRef.current.scrollLeft += type == 'left' ? -_scrollWidth : _scrollWidth;
          setAnimating(false);
          setScrollWidth(0);
          setAnimatingCallbackTimerId(null);
        }, SLIDE_SPEED);
        setAnimatingCallbackTimerId(timerId);
      };
    },
    [animating],
  );

  const onScroll = useCallback(() => {
    const scrollLeft = listRootRef.current.scrollLeft;
    const scrollWidth = listRootRef.current.scrollWidth;
    const clientWidth = listRootRef.current.clientWidth;
    setIsLeftEdge(scrollLeft === 0);
    setIsRightEdge(scrollWidth - 1 <= scrollLeft + clientWidth);
  }, []);

  const onMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHovering(false);
  }, []);

  const centering = useCallback(() => {
    if (animating) return;
    setAnimating(true);
    const totalItem = props.items.length;
    const scrollWidth = listRootRef.current.scrollWidth;
    const clientWidth = listRootRef.current.clientWidth;

    const centerClientScrollLeft = Math.floor(clientWidth / 2);
    const itemWidth = Math.floor(scrollWidth / totalItem);
    let scrollLeft = itemWidth * props.activePage + itemWidth / 2 - centerClientScrollLeft;
    if (scrollLeft < 0) {
      scrollLeft = 0;
    } else if (scrollLeft > scrollWidth - clientWidth) {
      scrollLeft = scrollWidth - clientWidth;
    }
    const diff = scrollLeft - listRootRef.current.scrollLeft;
    const timerId = setTimeout(() => {
      if (listRootRef.current) listRootRef.current.scrollLeft += diff;
      setAnimating(false);
      setScrollWidth(0);
      setAnimatingCallbackTimerId(null);
    }, SLIDE_SPEED);
    setAnimatingCallbackTimerId(timerId);
    setScrollWidth(-diff);
    setClicked(false);
  }, [animating, props.activePage, props.items]);

  useEffect(() => {
    // @ts-ignore TS2339
    if (!prevItems || props.items.length !== prevItems.length) {
      const scrollWidth = listRootRef.current.scrollWidth;
      const clientWidth = listRootRef.current.clientWidth;
      const canScroll = scrollWidth > clientWidth;
      if (canScroll) {
        const scrollLeft = listRootRef.current.scrollLeft;
        setIsLeftEdge(scrollLeft === 0);
        setIsRightEdge(scrollWidth <= scrollLeft + clientWidth);
      } else {
        setIsLeftEdge(true);
        setIsRightEdge(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  useEffect(() => {
    if (!prevActivePage || props.activePage !== prevActivePage) {
      if (!hovering || clicked) centering();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activePage]);

  useEffect(() => {
    return () => {
      if (animatingCallbackTimerId) {
        clearTimeout(animatingCallbackTimerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cards = _.map(props.items, (item, idx) => {
    let thumbnailUrl = item.thumbnailUrl || _.get(item, 'creatives[0].url');
    let active = false;
    let progress = null;
    if (props.activePage == idx) {
      active = true;
      progress = props.activePageProgress;
    }
    return (
      <BillboardIndicatorCard
        key={`billboard_indicator_card_${idx}`}
        // @ts-ignore TS2322
        page={idx}
        progress={progress}
        active={active}
        thumbnailUrl={thumbnailUrl}
        onClick={onClickCard}
        slideSpeed={SLIDE_SPEED}
        animating={animating}
        scrollWidth={scrollWidth}
      />
    );
  });

  return (
    <div
      className={classnames('pagination-indicator', {
        'fade-left': isLeftEdge,
        'fade-right': isRightEdge,
        animating: animating,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span
        aria-label="前の作品を観る"
        className="handle handlePrev active"
        role="button"
        // @ts-ignore TS2322
        tabIndex="-1"
        onClick={onClickArrow('left')}
      >
        <b className="indicator-icon icon-leftCaret"></b>
      </span>
      <ul ref={listRootRef} onScroll={onScroll}>
        {cards}
      </ul>
      <span
        aria-label="次の作品を観る"
        className="handle handleNext active"
        role="button"
        // @ts-ignore TS2322
        tabIndex="-1"
        onClick={onClickArrow('right')}
      >
        <b className="indicator-icon icon-rightCaret"></b>
      </span>
    </div>
  );
};

BillboardThumbnailIndicator.contextTypes = {
  getModelData: PropTypes.func.isRequired,
  routeHandler: PropTypes.object,
};

export default React.memo(BillboardThumbnailIndicator);
