import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import settingsDataStore from '../../../../utils/settingsDataStore';
import { AUDIO_LANGUAGE } from '../../../../common/PlayerApp';

class AudioTextSettings extends React.PureComponent {
  static get propTypes() {
    return {
      player: PropTypes.object.isRequired,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
      enableTextTracks: PropTypes.array,
      enableAudioTracks: PropTypes.array,
      showTextTracksChange: PropTypes.bool,
      showAudioTracksChange: PropTypes.bool,
      textTrack: PropTypes.object,
      audioTrack: PropTypes.object,
    };
  }

  static get defaultProps() {
    return {
      showTextTracksChange: true,
      showAudioTracksChange: true,
      textTrack: 'none',
      audioTrack: 'none',
      enableTextTracks: [],
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.textTracksMenuRef = React.createRef();
    // @ts-ignore TS2339
    this.audioTracksMenuRef = React.createRef();

    // @ts-ignore TS2339
    this.textTracksRefs = {};
    // @ts-ignore TS2339
    this.audioTracksRefs = {};

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.handleTextTracksMenu = this.handleTextTracksMenu.bind(this);
    this.handleTextTracksBack = this.handleTextTracksBack.bind(this);
    this.handleAudioTracksMenu = this.handleAudioTracksMenu.bind(this);
    this.handleAudioTracksBack = this.handleAudioTracksBack.bind(this);

    this.handleTextTracksMenuKeyDown = this.handleTextTracksMenuKeyDown.bind(this);
    this.handleTextTracksKeyDown = this.handleTextTracksKeyDown.bind(this);
    this.handleAudioTracksMenuKeyDown = this.handleAudioTracksMenuKeyDown.bind(this);
    this.handleAudioTracksKeyDown = this.handleAudioTracksKeyDown.bind(this);

    this.onClickTextTrack = this.onClickTextTrack.bind(this);
    this.onSelectTextTrack = this.onSelectTextTrack.bind(this);
    this.onChangeTextTrack = this.onChangeTextTrack.bind(this);

    this.onClickAudioTrack = this.onClickAudioTrack.bind(this);
    this.onSelectAudioTrack = this.onSelectAudioTrack.bind(this);
    this.onChangeAudioTrack = this.onChangeAudioTrack.bind(this);

    this.mainMenukeyDown = this.mainMenukeyDown.bind(this);
    this.subMenukeyDown = this.subMenukeyDown.bind(this);
    this.getTextTracksNode = this.getTextTracksNode.bind(this);
    this.getAudioTracksNode = this.getAudioTracksNode.bind(this);

    this.state = {
      showMainMenu: false,
      showTextTracksMenu: false,
      showAudioTracksMenu: false,
      textTrack: props.textTrack,
      audioTrack: props.audioTrack,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.setState({ showMainMenu: true });

    // @ts-ignore TS2339
    const focusElement = this.textTracksMenuRef.current;
    // setTimeoutしないとフォーカスが移動しない
    // @ts-ignore TS2339
    if (focusElement && this.props.shouldFocus) setTimeout(() => focusElement.focus(), 0);
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  componentDidUpdate(beforeProps, beforeState) {
    // @ts-ignore TS2339
    if (this.props.drowTooltip) this.props.drowTooltip();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover();
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover();
    }
  }

  handleTextTracksMenu(e) {
    this.setState({ showTextTracksMenu: true });
    const textTracksNode = this.getTextTracksNode();
    const index = _.findIndex(textTracksNode, node => {
      return node.props['aria-checked'];
    });
    // @ts-ignore TS2339
    setTimeout(() => this.textTracksRefs[textTracksNode[index].key].focus(), 0);
  }

  handleAudioTracksMenu(e) {
    this.setState({ showAudioTracksMenu: true });
    const audioTracksNode = this.getAudioTracksNode();
    const index = _.findIndex(audioTracksNode, node => {
      return node.props['aria-checked'];
    });
    // @ts-ignore TS2339
    setTimeout(() => this.audioTracksRefs[audioTracksNode[index].key].focus(), 0);
  }

  handleTextTracksBack(e) {
    this.setState({ showTextTracksMenu: false });
    // @ts-ignore TS2339
    setTimeout(() => this.textTracksMenuRef.current.focus(), 0);
  }

  handleAudioTracksBack(e) {
    this.setState({ showAudioTracksMenu: false });
    // @ts-ignore TS2339
    setTimeout(() => this.audioTracksMenuRef.current.focus(), 0);
  }

  onClickTextTrack(e, textTrack) {
    if (textTrack.disable) return;
    this.onChangeTextTrack(textTrack);
    this.handleTextTracksBack(e);
  }

  onSelectTextTrack(e) {
    // @ts-ignore TS2339
    const textTracks = this.props.player.textTracks();
    const textTrack = _.find(textTracks, track => track.language === e.target.value);
    this.onChangeTextTrack(textTrack);
  }

  onChangeTextTrack(textTrack) {
    this.setState({ textTrack: textTrack.language });
    // @ts-ignore TS2339
    if (this.props.player.isGCasted()) {
      // @ts-ignore TS2339
      this.props.player.settings({ subtitleLanguage: textTrack.language });
    } else {
      // @ts-ignore TS2339
      const textTracks = this.props.enableTextTracks;
      _.forEach(textTracks, track => {
        if (track.id === textTrack.id) {
          track.mode = 'showing';
        } else if (track.mode != 'disabled') {
          track.mode = 'disabled';
        }
      });
    }
    const settingsData = settingsDataStore.get() || {};
    settingsData.textTrack = textTrack.language;
    settingsDataStore.set(settingsData);
  }

  onClickAudioTrack(e, audioTrack) {
    if (audioTrack.disable) return;
    this.onChangeAudioTrack(audioTrack);
    this.handleAudioTracksBack(e);
  }

  onSelectAudioTrack(e) {
    // @ts-ignore TS2339
    const audioTracks = this.props.player.audioTracks();
    const audioTrack = _.find(audioTracks, track => track.language === e.target.value);
    this.onChangeAudioTrack(audioTrack);
  }

  onChangeAudioTrack(audioTrack) {
    this.setState({ audioTrack: audioTrack.language });
    // @ts-ignore TS2339
    if (this.props.player.isGCasted()) {
      // @ts-ignore TS2339
      this.props.player.settings({ audioLanguage: audioTrack.language });
    } else {
      // @ts-ignore TS2339
      const audioTracks = this.props.player.audioTracks();
      _.forEach(audioTracks, track => {
        if (track.id === audioTrack.id) {
          track.enabled = true;
        } else if (track.enabled != false) {
          track.enabled = false;
        }
      });
    }
    const settingsData = settingsDataStore.get() || {};
    settingsData.audioTrack = audioTrack.language;
    settingsDataStore.set(settingsData);
  }

  handleTextTracksMenuKeyDown(event) {
    // @ts-ignore TS2339
    this.mainMenukeyDown(event, this.handleTextTracksMenu, null, this.audioTracksMenuRef.current, true);
  }

  handleTextTracksKeyDown(event, keyName) {
    const textTracksNode = this.getTextTracksNode();
    const index = _.findIndex(textTracksNode, node => {
      return node.key == keyName;
    });
    // @ts-ignore TS2339
    this.subMenukeyDown(event, index, textTracksNode, this.textTracksRefs, this.handleTextTracksBack);
  }

  handleAudioTracksMenuKeyDown(event) {
    // @ts-ignore TS2339
    this.mainMenukeyDown(event, this.handleAudioTracksMenu, this.textTracksMenuRef.current, null, true);
  }

  handleAudioTracksKeyDown(event, keyName) {
    const audioTracksNode = this.getAudioTracksNode();
    const index = _.findIndex(audioTracksNode, node => {
      return node.key == keyName;
    });
    // @ts-ignore TS2339
    this.subMenukeyDown(event, index, audioTracksNode, this.audioTracksRefs, this.handleAudioTracksBack);
  }

  mainMenukeyDown(event, enterFunc, prevElement, nextElement, enableRight = false) {
    // Right Arrows and Enter and Space
    if ((enableRight && event.which === 39) || event.which === 13 || event.which === 32) {
      event.preventDefault();
      enterFunc(event);

      // Down Arrows
    } else if (event.which === 40) {
      event.preventDefault();
      if (nextElement) {
        setTimeout(() => nextElement.focus(), 0);
      }

      // Up Arrows
    } else if (event.which === 38) {
      event.preventDefault();
      if (prevElement) {
        setTimeout(() => prevElement.focus(), 0);
      }

      // Tab
    } else if (event.which === 9 && !event.shiftKey) {
      // @ts-ignore TS2339
      if (!nextElement && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      // @ts-ignore TS2339
      if (!prevElement && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Esc and BackSpace
    } else if (event.which === 27 || event.which === 8) {
      // @ts-ignore TS2339
      if (this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }
    }

    // @ts-ignore TS2339
    if (this.props.onMouseMove) this.props.onMouseMove();
  }

  subMenukeyDown(event, index, nodes, refs, backFunc) {
    // Down Arrows
    if (event.which === 40) {
      index++;
      if (nodes[index]) {
        event.preventDefault();
        setTimeout(() => refs[nodes[index].key].focus(), 0);
      }

      // Up Arrows
    } else if (event.which === 38) {
      index--;
      if (nodes[index]) {
        event.preventDefault();
        setTimeout(() => refs[nodes[index].key].focus(), 0);
      }

      // Tab
    } else if (event.which === 9 && !event.shiftKey) {
      index++;
      // @ts-ignore TS2339
      if (!nodes[index] && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      index--;
      // @ts-ignore TS2339
      if (!nodes[index] && this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }

      // Left Arrows and BackSpace
    } else if (event.which === 37 || event.which === 8) {
      event.preventDefault();
      backFunc(event);

      // Enter and Space
    } else if (event.which === 13 || event.which === 32) {
      event.preventDefault();
      refs[nodes[index].key].click();

      // Esc
    } else if (event.which === 27) {
      // @ts-ignore TS2339
      if (this.props.onEscape) {
        event.preventDefault();
        // @ts-ignore TS2339
        this.props.onEscape();
      }
    }

    // @ts-ignore TS2339
    if (this.props.onMouseMove) this.props.onMouseMove();
  }

  getTextTracksNode() {
    // @ts-ignore TS2339
    const textTracks = this.props.enableTextTracks;

    if (!((textTracks || []).length > 0)) {
      return [];
    }

    // 同じ言語が複数ある場合は最初にある方をつかむ
    // @ts-ignore TS2339
    const defaultTextTrack = _.get(_.find(textTracks, { language: this.props.textTrack }), 'id') || 'none';

    return _.map(textTracks, textTrack => {
      const { id, disable, label } = textTrack || {};
      const keyName = `textTracks_${id}`;

      return (
        <div
          // @ts-ignore TS2339
          ref={el => (this.textTracksRefs[keyName] = el)}
          className={classnames('wod-menuitem', { disable })}
          // @ts-ignore TS2322
          tabIndex="0"
          role="menuitemradio"
          onClick={e => this.onClickTextTrack(e, textTrack)}
          key={keyName}
          aria-checked={defaultTextTrack == id}
          onKeyDown={e => this.handleTextTracksKeyDown(e, keyName)}
        >
          <div className="wod-menuitem-label">
            <i className="fa fa-ok" />
            {label}
          </div>
        </div>
      );
    });
  }

  getAudioTracksNode() {
    // @ts-ignore TS2339
    const audioTracks = this.props.enableAudioTracks;

    if (!((audioTracks || []).length > 0)) {
      return [];
    }

    // 同じ言語が複数ある場合は最初にある方をつかむ
    const defaultAudioTrack =
      // @ts-ignore TS2339
      _.get(_.find(audioTracks, { language: this.props.audioTrack }), 'id') || AUDIO_LANGUAGE.UND;

    return _.map(audioTracks, audioTrack => {
      const { id, disable, label } = audioTrack || {};
      const keyName = `audioTrack_${id}`;

      return (
        <div
          // @ts-ignore TS2339
          ref={el => (this.audioTracksRefs[keyName] = el)}
          className={classnames('wod-menuitem', { disable })}
          // @ts-ignore TS2322
          tabIndex="0"
          role="menuitemradio"
          onClick={e => this.onClickAudioTrack(e, audioTrack)}
          key={keyName}
          aria-checked={defaultAudioTrack == id}
          onKeyDown={e => this.handleAudioTracksKeyDown(e, keyName)}
        >
          <div className="wod-menuitem-label">
            <i className="fa fa-ok" />
            {label}
          </div>
        </div>
      );
    });
  }

  render() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    // @ts-ignore TS2339
    const textTracks = this.props.enableTextTracks;
    const textTracksIndex = _.findIndex(textTracks, textTrack => {
      // @ts-ignore TS2339
      return textTrack.language == this.props.textTrack;
    });
    const textTracksNode = this.getTextTracksNode();
    let disableTextCount = 0;
    const textTracksOptionNode = _.map(textTracks, textTrack => {
      const { id, disable, label, language } = textTrack || {};

      if (language == 'none' || disable) disableTextCount++;
      return (
        <option key={`textTracks-${id}`} value={language}>
          {label}
        </option>
      );
    });
    const textTrackDisable = textTracks.length == disableTextCount;

    // @ts-ignore TS2339
    const audioTracks = this.props.enableAudioTracks;
    const audioTracksIndex = _.findIndex(audioTracks, audioTrack => {
      // @ts-ignore TS2339
      return audioTrack.language == this.props.audioTrack;
    });
    const audioTracksNode = this.getAudioTracksNode();
    let disableAudioCount = 0;
    const audioTracksOptionNode = _.map(audioTracks, audioTrack => {
      const { id, disable, label, language } = audioTrack || {};

      if (language == 'none' || disable) disableAudioCount++;
      return (
        <option key={`audioTracks-${id}`} value={language}>
          {label}
        </option>
      );
    });
    const audioTrackDisable = (audioTracks || []).length == disableAudioCount || (audioTracks || []).length === 1;

    return (
      <div
        className={classnames('settings-wapper', {
          // @ts-ignore TS2339
          'show-main-menu': this.state.showMainMenu,
          // @ts-ignore TS2339
          'show-text-tracks-menu': this.state.showTextTracksMenu,
          // @ts-ignore TS2339
          'show-audio-tracks-menu': this.state.showAudioTracksMenu,
        })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="wod-popup wod-settings-menu">
          <div className="wod-panel main-menu">
            <div className="wod-panel-menu" role="menu">
              {/*
               // @ts-ignore TS2339 */}
              {this.props.showTextTracksChange && !textTrackDisable && (
                <div
                  // @ts-ignore TS2339
                  ref={this.textTracksMenuRef}
                  className={classnames('wod-menuitem', { disable: textTrackDisable })}
                  aria-haspopup="true"
                  role="menuitem"
                  // @ts-ignore TS2322
                  tabIndex="0"
                  onClick={browserInfo.isIOS || browserInfo.isAndroid ? null : this.handleTextTracksMenu}
                  onKeyDown={this.handleTextTracksMenuKeyDown}
                >
                  <div className="wod-menuitem-label">字幕</div>
                  {browserInfo.isIOS || browserInfo.isAndroid ? (
                    <div className="wod-menuitem-content select">
                      <label className="select-group">
                        {/*
                         // @ts-ignore TS2339 */}
                        <select value={this.state.textTrack} onChange={this.onChangeTextTrack}>
                          {textTracksOptionNode}
                        </select>
                        <i className="fa fa-angle_right" />
                      </label>
                    </div>
                  ) : (
                    <div className="wod-menuitem-content">
                      {_.get(textTracks[textTracksIndex], 'label')}
                      <i className="fa fa-angle_right" />
                    </div>
                  )}
                </div>
              )}

              {/*
               // @ts-ignore TS2339 */}
              {this.props.showAudioTracksChange && !audioTrackDisable && (
                <div
                  // @ts-ignore TS2339
                  ref={this.audioTracksMenuRef}
                  className={classnames('wod-menuitem', { disable: audioTrackDisable })}
                  aria-haspopup="true"
                  role="menuitem"
                  // @ts-ignore TS2322
                  tabIndex="0"
                  onClick={browserInfo.isIOS || browserInfo.isAndroid ? null : this.handleAudioTracksMenu}
                  onKeyDown={this.handleAudioTracksMenuKeyDown}
                >
                  <div className="wod-menuitem-label">音声</div>
                  {browserInfo.isIOS || browserInfo.isAndroid ? (
                    <div className="wod-menuitem-content select">
                      <label className="select-group">
                        {/*
                         // @ts-ignore TS2339 */}
                        <select value={this.state.audioTrack} onChange={this.onSelectAudioTrack}>
                          {audioTracksOptionNode}
                        </select>
                        <i className="fa fa-angle_right" />
                      </label>
                    </div>
                  ) : (
                    <div className="wod-menuitem-content">
                      {_.get(audioTracks[audioTracksIndex], 'label')}
                      <i className="fa fa-angle_right" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="wod-panel text-tracks-menu">
            <div className="wod-panel-header">
              <i className="fa fa-angle_left" />
              <button type="button" className="wod-button wod-panel-title" onClick={this.handleTextTracksBack}>
                字幕
              </button>
            </div>
            <div className="wod-panel-menu" role="menu">
              {textTracksNode}
            </div>
          </div>

          <div className="wod-panel audio-tracks-menu">
            <div className="wod-panel-header">
              <i className="fa fa-angle_left" />
              <button type="button" className="wod-button wod-panel-title" onClick={this.handleAudioTracksBack}>
                音声
              </button>
            </div>
            <div className="wod-panel-menu" role="menu">
              {audioTracksNode}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AudioTextSettings;
