import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import SubscriptionPlan from './elements/SubscriptionPlan';
import useShowSubscriptionPlan from './hooks/useShowSubscriptionPlan';
import PPVPlan from './elements/PPVPlan';
import NoPPVView from './elements/NoPPVPlan';
import activeProfile from 'src/utils/activeProfile';
import { Meta } from 'src/types/context/Meta';
import { Event } from 'src/types/context/Event';
import { Course } from 'src/types/context/Course';
import { Product } from 'src/types/context/Product';
import useEnableProducts from './hooks/useEnableProducts';
import url from 'url';

type ViewingPlanProps = {
  checkModal: () => void;
  meta?: Meta;
  event?: Event;
  courses?: Course[];
  products?: Product[];
  isProductPage?: boolean;
  isDisabled?: boolean;
  spMode?: boolean;
  doAction: (option: { selected: { type: string; id: number | string }; returnTo: string }) => void;
};

const ViewingPlan = (props: ViewingPlanProps, context) => {
  const profile = useMemo(() => activeProfile(context.models), [context.models]);
  const showSubscriptionView = useShowSubscriptionPlan({
    meta: props.meta,
    event: props.event,
    courses: props.courses,
    profile,
  });
  const enableProducts = useEnableProducts({ products: props.products, isProductPage: false });

  const showPPVPlan = useMemo(() => !_.isEmpty(enableProducts), [enableProducts]);
  const showNoPPVPlan = useMemo(() => showPPVPlan && props.isProductPage, [props.isProductPage, showPPVPlan]);
  const browserInfo = useMemo(() => context.getModelData('browserInfo'), [context]);
  const host = useMemo(() => context.getModelData('hosts', 'host'), [context]);
  const service = useMemo(() => context.getModelData('services', 'qrcode'), [context]);
  const redirectUrl = host + context.routeHandler.url;

  const containsSubOnlyTvod = props.products.some(p => p.saleCourseRule && p.saleCourseRule.length > 0);

  service.query = { text: redirectUrl };
  return showSubscriptionView || showPPVPlan || showNoPPVPlan ? (
    browserInfo.isCarMonitor ? (
      <>
        <div className="plan-info__title">他デバイスで視聴手続きを行う</div>
        <div className="plan-info__item qr">
          <img className="plan-info__qr-code" src={url.format(service)} />
          <div className="plan-info__item__li qr">
            <div className="plan-info__msg">QRコード読み込み、またはURLを直接入力してご確認ください</div>
            <div className="plan-info__url">{redirectUrl}</div>
          </div>
        </div>
      </>
    ) : (
      <>
        {showSubscriptionView && !containsSubOnlyTvod ? (
          <SubscriptionPlan
            // @ts-ignore
            meta={props.meta}
            event={props.event}
            courses={props.courses}
            containsSubOnlyTvod={containsSubOnlyTvod}
          />
        ) : null}
        {showPPVPlan ? (
          <PPVPlan
            products={enableProducts}
            meta={props.meta}
            checkModal={props.checkModal}
            doAction={props.doAction}
            isDisabled={props.isDisabled}
            isProductPage={props.isProductPage}
            spMode={props.spMode}
            containsSubOnlyTvod={containsSubOnlyTvod}
          />
        ) : showNoPPVPlan ? (
          <NoPPVView />
        ) : null}
      </>
    )
  ) : null;
};

ViewingPlan.contextTypes = {
  models: PropTypes.object,
  getModelData: PropTypes.func,
  routeHandler: PropTypes.object,
};

ViewingPlan.defaultProps = {
  isProductPage: false,
  isDisabled: false,
};

export default React.memo<ViewingPlanProps>(ViewingPlan);
