import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import GalleryContent from './GalleryContent';
import ViewingBookmarkDeleteApp from '../../../../common/ViewingBookmarkDeleteApp';
import MyListGalleryHeader from './MyListGallaryHeader';
import GalleryDeleteContent from './GalleryDeleteContent';
import { PAGING_INITIAL_KEY } from '../../../../constants';

class ContinueWatching extends React.Component {
  static getPrefetchPaths = function(models, options, props) {
    return GalleryContent.getPaths(
      models,
      options,
      Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'continuewatching' }),
    );
  };
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = props.pathEvaluator || props.model.pathEvaluator;
    // @ts-ignore TS2339
    this._deleteApp = new ViewingBookmarkDeleteApp(context.getModelData('services', 'cms'), this.context.gtmApp);
    this.handleDeleted = this.handleDeleted.bind(this);
    this.handleFetchedCount = this.handleFetchedCount.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.state = {
      showDeleteContent: false,
    };
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this._deleteApp.on('onDeleted', this.handleDeleted);
    this.sendToGtm();
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    this._deleteApp.off('onDeleted', this.handleDeleted);
    // 視聴中の初回キャッシュ、
    // fetchから5秒以内だと残ってしまうので消す
    // TODO 暫定対応 falcorの$expirexのみで対応したい
    // @ts-ignore TS2339
    if (_.has(this.model, `_root.cache.continuewatching.${PAGING_INITIAL_KEY}`)) {
      // @ts-ignore TS2339
      this.model.invalidate(['continuewatching', PAGING_INITIAL_KEY]);
    }
  }
  handleDeleted() {
    // キャッシュされている視聴中一覧を削除する
    // @ts-ignore TS2339
    this.model.invalidate(['continuewatching']);
  }
  handleFetchedCount(count) {
    this.setState({ showDeleteContent: count > 0 });
  }
  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pageView('マイリスト');
  }
  render() {
    // @ts-ignore TS2339
    const deleteContent = this.state.showDeleteContent ? <GalleryDeleteContent deleteApp={this._deleteApp} /> : null;
    return (
      <GalleryContent
        // @ts-ignore TS2322
        header={<MyListGalleryHeader deleteContent={deleteContent} />}
        // @ts-ignore TS2322
        deleteApp={this._deleteApp}
        onFetchedCount={this.handleFetchedCount}
        // @ts-ignore TS2339
        model={this.model}
        keyPrefix={'continuewatching'}
        listContext={'mylist'}
      />
    );
  }
}

export default ContinueWatching;
