import React from 'react';
import PropTypes from 'prop-types';
import { sepDigit } from '@wowow/front-common/common/utils/sepDigit';

import _ from 'src/domain/libs/util';
import validations from '../../../../../utils/validations';

import VoucherConfirm from './SubscribeConfirm/VoucherConfirm';
import SubmitErrorView from './common/SubmitErrorView';
import SubmitButtonArea from './common/SubmitButtonArea';
import PaymentInfoForm from './common/PaymentInfoForm';
import AccountOwnerOnlyView from './common/AccountOwnerOnlyView';
import PlanAgreementCheckbox from '../common/PlanAgreementCheckbox';
import SubscriptionTrialInfo from '../common/SubscriptionTrialInfo';
import SubscriptionPriceNote from '../common/SubscriptionPriceNote';
import ViewingConfirmation from '../../howto_play/ViewingConfirmation';

export default class SubscribeConfirm extends React.PureComponent {
  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      course: PropTypes.object,
      meta: PropTypes.object,
      accountInfo: PropTypes.object,
      paymentMethods: PropTypes.array,
      paymentInstruments: PropTypes.array,
    };
  }

  constructor(props, context) {
    super(props, context);

    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._applyVoucherData = this._applyVoucherData.bind(this);
    this.setNextStep = this.setNextStep.bind(this);

    // @ts-ignore TS2339
    this.paymentInfoFormRef = React.createRef();
    // @ts-ignore TS2339
    this.inputAgreeFlagRef = React.createRef();

    this.state = {
      voucherData: null,
      nextStep: _.isEmpty(
        _.filter(props.paymentInstruments, paymentInstrument => {
          return paymentInstrument.isAvailable;
        }),
      ),
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.submitError) {
      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ submitting: false });
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  //
  // submitしたとき
  //
  _handleSubmit(e) {
    // @ts-ignore TS2339
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    // バリデーション
    const messages = {};
    // @ts-ignore TS2339
    if (this.inputAgreeFlagRef.current) {
      // @ts-ignore TS2339
      validations.agreeFlag(messages, this.inputAgreeFlagRef.current.getChecked());
    }
    if (!_.isEmpty(messages)) {
      this.setState({ messages: messages, submitting: false });
      e.preventDefault();
      return false;
    }

    let args = {
      // @ts-ignore TS2339
      metaId: this.props.meta.id,
      type: 'subscribe',
      // @ts-ignore TS2339
      courseId: this.props.course.id,
      // @ts-ignore TS2339
      nextStep: this.state.nextStep,
      voucherCode: _.get(this.state, 'voucherData.voucherCode.code'),
    };
    // @ts-ignore TS2339
    if (this.paymentInfoFormRef.current) {
      // @ts-ignore TS2339
      if (this.state.nextStep) {
        // @ts-ignore TS2339
        args['paymentMethodInfo'] = this.paymentInfoFormRef.current.getSelectedPaymentMethodInfo();
      }
    }
    // @ts-ignore TS2339
    this.props.nextAction(args);
  }

  //
  // キャンセルボタンを押したとき
  //
  _handleCancel(e) {
    // @ts-ignore TS2339
    this.props.prevAction(e);
  }

  _applyVoucherData(data) {
    this.setState({ voucherData: data });
  }

  //
  // 現在の支払い方法で購入するか
  // 支払い情報を登録して購入するか
  // をセット
  //
  setNextStep(bool) {
    // @ts-ignore TS2339
    if (this._isMounted) {
      this.setState({ nextStep: bool });
    }
  }

  render() {
    // @ts-ignore TS2339
    const course = this.props.course;

    return (
      <div className="upsell-confirm contract-confirm">
        <p className="message">プランの内容をご確認ください。</p>
        <div className="course-info">
          <p className="course-name">{course.displayName}</p>
          <p className="course-description">{course.description}</p>
        </div>
        {/*
           // @ts-ignore TS2322 */}
        <AccountOwnerOnlyView handleCancel={this._handleCancel}>
          <ViewingConfirmation actionType="subscribe" />
          <SubmitErrorView {...this.props} />
          <div className="recipe-table">
            {/*
               // @ts-ignore TS2339 */}
            {this.props.course && this.props.course.isTrialUsed ? null : (
              <div className="recipe-table-row">
                <div className="title">
                  <p>クーポン</p>
                </div>
                {/*
                   // @ts-ignore TS2322 */}
                <VoucherConfirm course={this.props.course} applyVoucherData={this._applyVoucherData} />
              </div>
            )}

            <div className="recipe-table-row">
              <div className="title">
                <p>無料期間</p>
              </div>
              {this._renderFreeTrialInfo()}
            </div>

            <PaymentInfoForm
              // @ts-ignore TS2339
              ref={this.paymentInfoFormRef}
              // @ts-ignore TS2339
              paymentInstruments={this.props.paymentInstruments}
              // @ts-ignore TS2339
              paymentMethods={this.props.paymentMethods}
              setNextStep={this.setNextStep}
              // @ts-ignore TS2339
              nextStep={this.state.nextStep}
              actionName="契約"
              changeable={false}
              // @ts-ignore TS2322
              model={this.props.model}
            />

            <div className="recipe-table-row">
              <div className="title">
                <p>価格</p>
              </div>
              <div className="item">
                <p>
                  <span className="text">{sepDigit(course.activePricing.paymentAmount)}</span>
                  <span>{course.activePricing.displayUnit}（税込）/月</span>
                </p>
                <SubscriptionPriceNote subscription={_.get(this.props, 'estimateInfo.subscription')} />
              </div>
            </div>
          </div>
          <this.PlanAgreementInfo
            course={course}
            // @ts-ignore TS2339
            inputAgreeFlagRef={this.inputAgreeFlagRef}
            // @ts-ignore TS2339
            messages={this.state.messages}
          />
          <SubmitButtonArea
            actionName="契約"
            // @ts-ignore TS2339
            nextStep={this.state.nextStep}
            // @ts-ignore TS2339
            submitting={this.state.submitting}
            handleSubmit={this._handleSubmit}
            handleCancel={this._handleCancel}
          />
        </AccountOwnerOnlyView>
      </div>
    );
  }

  PlanAgreementInfo(props) {
    const content = <PlanAgreementCheckbox {...props} message={_.get(props, 'messages.agreed_contract_version')} />;
    if (!content) {
      return null;
    }

    return <div className="terms-block">{content}</div>;
  }

  _renderFreeTrialInfo() {
    const content = (
      <SubscriptionTrialInfo
        // @ts-ignore TS2339
        course={this.props.course}
        subscription={_.get(this.props, 'estimateInfo.subscription')}
        updatedSubscription={_.get(this.state, 'voucherData.subscription')}
      />
    );

    if (!content) {
      return <div>なし</div>;
    }
    return content;
  }
}
