import React, { Component } from 'react';
import _ from 'src/domain/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import HtmlContext from '../../../common/context/HtmlContext';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
// @ts-ignore TS1192
import reactNl2br from '../../../../common/reactNl2br';
import MainViewLink from '../../../common/components/MainViewLink';
import panelHOC from '../../../common/hocs/panelHOC';
import JsonLd from '../../../common/components/JsonLd';
import routes from '../../../common/routes';

class InfoDetail extends Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    return [rootPath.concat([['id', 'title', 'thumbnailUrl', 'postAt', 'url', 'text', 'redirectUrl']])];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['link', props.id];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.sendToGtm = this.sendToGtm.bind(this);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.state = {};
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    this.infoData = this.model.getSync(rootPath) || {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    if (!this.infoData || Object.keys(this.infoData).length === 0) {
      // componentWillMountでスローしたいが、SSRのタイミングでも呼ばれるので不可
      // https://reactjs.org/docs/error-boundaries.html
      // @ts-ignore TS2554
      throw new NotFoundError();
    }
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
    this.sendToGtm();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-ignore TS2339
    this.context.gtmApp.pageView(_.get(this.infoData, 'title'));
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.infoData = _.omit(_.get(res.json, rootPath), ['$__path']);
        const newState = {
          dispose: null,
          fetchDataError: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          dispose: null,
          fetchDataError: e,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    if (!this.infoData) return null;
    // console.log(this.infoData)

    const jsonLdProps = {};
    // @ts-expect-error TS2339
    const host = this.context.getModelData('hosts', 'host');
    const itemListElement = [];

    // @ts-ignore TS2554
    const url = routes.info.makePath();
    itemListElement.push({ name: 'お知らせ', item: host + url });

    // @ts-ignore TS2339
    if (this.infoData.id && this.infoData.title) {
      // @ts-ignore TS2554
      const _url = routes.infoDetail.makePath({ id: this.infoData.id });
      // @ts-ignore TS2339
      itemListElement.push({ name: this.infoData.title, item: host + _url });
    }

    // @ts-ignore TS2339
    jsonLdProps.breadcrumbList = { itemListElement };
    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ keywords }) => (
            <HelmetProvider>
              {/* @ts-ignore TS2339 */}
              <Helmet title={this.infoData.title} />
            </HelmetProvider>
          )}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        <div className="infoDetail-wrapper">
          {/*
           // @ts-ignore TS2339 */}
          <h2 className="infoDetail-title">{this.infoData.title}</h2>
          {/*
           // @ts-ignore TS2339 */}
          {this.infoData.postAt ? <div className="infoDetail-date">{this.infoData.postAt}</div> : null}
          <div className="infoDetail-box">
            {/*
             // @ts-ignore TS2339 */}
            {this.infoData.thumbnailUrl ? (
              // @ts-ignore TS2339
              <img src={this.infoData.thumbnailUrl} className="infoDetail-box__thumb" />
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            {this.infoData.text && <p className="infoDetail-box__text">{reactNl2br(this.infoData.text)}</p>}
            {/*
             // @ts-ignore TS2339 */}
            {this.infoData.redirectUrl && (
              // @ts-ignore TS2322
              <MainViewLink href={this.infoData.redirectUrl} className="infoDetail-box__link">
                {/*
                 // @ts-ignore TS2339 */}
                {this.infoData.redirectUrl}
              </MainViewLink>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default panelHOC(InfoDetail);
