import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import * as DOMUtils from '../../../sketch-platform/utils/DOMUtils';
import * as browserEvents from '../../../sketch-platform/utils/browserEvents';
import getOrientation from '../utils/getOrientation';

class DrawerContent extends React.PureComponent {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  static get propTypes() {
    return {
      top: PropTypes.number,
      targetElement: PropTypes.element,
    };
  }

  static get defaultProps() {
    return {
      top: 0,
    };
  }

  static initialOpacity = 0.7;

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.rootRef = React.createRef();
    // @ts-ignore TS2339
    this.containerRef = React.createRef();
    // @ts-ignore TS2339
    this.contentRef = React.createRef();
    // @ts-ignore TS2339
    this.drawerHeaderWrapperRef = React.createRef();

    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.showBehindElement = this.showBehindElement.bind(this);
    this.hideBehindElement = this.hideBehindElement.bind(this);
    this.resetByOrientation = this.resetByOrientation.bind(this);
    this.setByOrientation = this.setByOrientation.bind(this);
    this.setDrawerTop = this.setDrawerTop.bind(this);
    this.setTransition = this.setTransition.bind(this);
    this.close = this.close.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    // SSRする想定ないのでここでよぶ
    const orientation = getOrientation();
    this.state = {
      opened: true,
      top: props.top,
      orientation,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (this.props.spFooterRef.current) {
      // @ts-ignore TS2339
      this.props.spFooterRef.current.style.display = 'none';
    }
    // @ts-ignore TS2339
    if (this.props.pagetopRef.current) {
      // @ts-ignore TS2339
      this.props.pagetopRef.current.style.display = 'none';
    }
    // @ts-ignore TS2339
    this.setByOrientation(this.state.orientation);
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isAndroid || browserInfo.isIOS || browserInfo.isRequestDesktopWebsite) {
      browserEvents.addEventListener('orientationchange', this.handleOrientationChange);
    }
    // アニメーションでドロワーを開かせるため
    // @ts-ignore TS2339
    this.containerRef.current.style.transform = 'translateY(0)';
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.props.spFooterRef.current) {
      // @ts-ignore TS2339
      this.props.spFooterRef.current.style.display = '';
    }
    // @ts-ignore TS2339
    if (this.props.pagetopRef.current) {
      // @ts-ignore TS2339
      this.props.pagetopRef.current.style.display = '';
    }
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isAndroid || browserInfo.isIOS || browserInfo.isRequestDesktopWebsite) {
      browserEvents.removeEventListener('orientationchange', this.handleOrientationChange);
    }
    this.resetByOrientation();
  }

  componentDidUpdate(prevProps, prevState) {
    // @ts-ignore TS2339
    if (this.state.orientation !== prevState.orientation) {
      // @ts-ignore TS2339
      this.setByOrientation(this.state.orientation);
    }
  }

  get contentHeight() {
    // @ts-ignore TS2339
    if (this.state.orientation === 'horizontal') {
      let headerHeight = 0;
      // @ts-ignore TS2339
      if (this.props.headerRef && this.props.headerRef.current) {
        // @ts-ignore TS2339
        headerHeight = DOMUtils.getRect(this.props.headerRef.current).height;
      }
      return window.innerHeight - headerHeight;
    }
    return null;
  }

  resetByOrientation() {
    // @ts-ignore TS2339
    if (this.state.orientation === 'horizontal') {
      this.showBehindElement();
    } else {
      const body = document.getElementsByTagName('body')[0];
      if (body.classList.contains('no_scroll')) body.classList.remove('no_scroll');
    }
  }

  setByOrientation(orientation) {
    window.scroll({ top: 0 });
    const body = document.getElementsByTagName('body')[0];
    if (orientation === 'vertical') {
      if (!body.classList.contains('no_scroll')) body.classList.add('no_scroll');
      this.showBehindElement();
      // @ts-ignore TS2339
      this.containerRef.current.style.transform = 'translateY(0%)';
    }

    // diaplay: noneを消してあげてから
    this.setDrawerTop();

    if (orientation === 'horizontal') {
      if (body.classList.contains('no_scroll')) body.classList.remove('no_scroll');
      this.hideBehindElement();
    }
  }

  handleOrientationChange(e, orientation) {
    this.setState({ orientation });
  }

  hideBehindElement() {
    // @ts-ignore TS2339
    if (this.props.behindElementRefs) {
      // @ts-ignore TS2339
      this.props.behindElementRefs.forEach(ref => {
        if (ref.current) {
          ref.current.style.display = 'none';
        }
      });
    }
  }

  showBehindElement() {
    // @ts-ignore TS2339
    if (this.props.behindElementRefs) {
      // @ts-ignore TS2339
      this.props.behindElementRefs.forEach(ref => {
        if (ref.current) {
          ref.current.style.display = '';
        }
      });
    }
  }

  setDrawerTop() {
    // @ts-ignore TS2339
    if (this.props.targetElement) {
      // @ts-ignore TS2339
      const top = DOMUtils.getRect(this.props.targetElement).top;
      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ top });
      } else {
        // @ts-ignore TS2339
        this.state.top = top;
      }
    }
  }

  setTransition(second) {
    // @ts-ignore TS2339
    this.containerRef.current.style.WebkitTransition = `transform ${second}s ease`;
    // @ts-ignore TS2339
    this.containerRef.current.style.MozTransition = `transform ${second}s ease`;
    // @ts-ignore TS2339
    this.containerRef.current.style.OTransition = `transform ${second}s ease`;
    // @ts-ignore TS2339
    this.containerRef.current.style.transition = `transform ${second}s ease`;
  }

  close() {
    setTimeout(() => {
      this.setState({ opened: false }, () => {
        // @ts-ignore TS2339
        this.props.onClose();
      });
    }, 1);
  }

  handleTouchStart(e) {
    // @ts-ignore TS2339
    this.touchMove = true;
    this.setTransition(0);
  }

  handleTouchMove(e) {
    if (!e.currentTarget) return;

    // @ts-ignore TS2339
    const { y } = DOMUtils.getPointerPosition(this.rootRef.current, e);
    const current = (1 - y) * 100;
    // @ts-ignore TS2339
    this.containerRef.current.style.transform = `translateY(${current}%)`;
    // @ts-ignore TS2339
    this.rootRef.current.style.background = `rgba(0,0,0,${this.constructor.initialOpacity * y})`;
    // @ts-ignore TS2339
    this.current = current;
  }

  handleTouchEnd(e) {
    // @ts-ignore TS2339
    this.touchMove = false;
    this.setTransition(0.3);
    // this.rootRef.current.style.top = this.defaultTop;
    let y = 0;
    // @ts-ignore TS2339
    if (parseInt(this.current) > 20) {
      y = 100;
    }
    // @ts-ignore TS2339
    this.containerRef.current.style.transform = `translateY(${y}%)`;
    if (y === 100) {
      this.close();
    }
  }

  handleClickClose(e) {
    e.preventDefault();
    e.stopPropagation();
    this.close();
  }

  render() {
    // @ts-ignore TS2339
    if (!this.state.opened) return null;
    // @ts-ignore TS2339
    const drawerHeaderWrapperProps = { ref: this.drawerHeaderWrapperRef };
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    const wrapperStyle = { top: this.state.top };
    const containerStyle = {};
    // @ts-ignore TS2339
    if (this.state.orientation === 'vertical') {
      if (browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite) {
        // @ts-ignore TS2339
        drawerHeaderWrapperProps.onTouchStart = this.handleTouchStart;
        // @ts-ignore TS2339
        drawerHeaderWrapperProps.onTouchMove = this.handleTouchMove;
        // @ts-ignore TS2339
        drawerHeaderWrapperProps.onTouchEnd = this.handleTouchEnd;
      }
      // @ts-ignore TS2339
      if (!this._isMounted) containerStyle.transform = 'translateY(100%)';
    }
    if (browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite) {
    }
    return (
      <div
        // @ts-ignore TS2339
        className={classnames('drawer-wrapper', { horizontal: this.state.orientation === 'horizontal' })}
        // @ts-ignore TS2339
        ref={this.rootRef}
        style={wrapperStyle}
      >
        {/*
         // @ts-ignore TS2339 */}
        <div className="drawer-container" ref={this.containerRef} style={containerStyle}>
          <div className="drawer-header-wrapper" {...drawerHeaderWrapperProps}>
            <div className="drawer-drag-line" />
            <div className="drawer-header">
              <h2 className="drawer-header-title">
                {/*
                 // @ts-ignore TS2339 */}
                <div>{this.props.title}</div>
                <div className="fa-remove closebtn" onClick={this.handleClickClose}></div>
              </h2>
            </div>
          </div>
          {/*
           // @ts-ignore TS2339 */}
          <div className="drawer-content" ref={this.contentRef} style={{ height: this.contentHeight }}>
            {/*
             // @ts-expect-error TS2339 */}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default DrawerContent;
