import _ from 'src/domain/libs/util';
import datetime from 'src/domain/libs/datetime';
import { PaymentMethodData, PaymentMethod } from 'src/domain/models/paymentMethod';

const PAYMENT_METHOD_LABEL = {
  CARD: 'クレジットカード',
  DOCOMO: 'd払い',
  AU: 'auかんたん決済',
  SOFTBANK: 'ソフトバンクまとめて支払い',
  AMAZON: 'Amazonアプリ内決済',
  APPLE: 'Apple App内課金',
  MERPAY: 'メルペイ',
};

const PAY_METHOD = {
  CARD: 'card',
  DOCOMO: 'docomo',
  AU: 'au',
  SOFTBANK: 'softbank',
  AMAZON: 'amazon',
  APPLE: 'apple',
  MERPAY: 'merpay',
};

export const PAY_METHOD_TO_LABEL = {
  [PAY_METHOD.CARD]: PAYMENT_METHOD_LABEL.CARD,
  [PAY_METHOD.DOCOMO]: PAYMENT_METHOD_LABEL.DOCOMO,
  [PAY_METHOD.AU]: PAYMENT_METHOD_LABEL.AU,
  [PAY_METHOD.SOFTBANK]: PAYMENT_METHOD_LABEL.SOFTBANK,
  [PAY_METHOD.AMAZON]: PAYMENT_METHOD_LABEL.AMAZON,
  [PAY_METHOD.APPLE]: PAYMENT_METHOD_LABEL.APPLE,
  [PAY_METHOD.MERPAY]: PAYMENT_METHOD_LABEL.MERPAY,
};

const filterByDisplayPaymentMethod = (paymentMethod, appConfig) => {
  const displayPaymentMethodIds: number[] = appConfig.display_payment_method_ids || [];
  return displayPaymentMethodIds.some(id => id === paymentMethod.id);
};

const isRegistable = data => {
  const now = datetime();
  if (!data.register_acceptable_start_at && !data.register_acceptable_end_at) {
    return true;
  } else if (!data.register_acceptable_start_at) {
    return now < datetime(data.register_acceptable_end_at, 'YYYY/MM/DD HH:mm:ss');
  } else if (!data.register_acceptable_end_at) {
    return datetime(data.register_acceptable_start_at, 'YYYY/MM/DD HH:mm:ss') < now;
  } else {
    return (
      datetime(data.register_acceptable_start_at, 'YYYY/MM/DD HH:mm:ss') < now &&
      now < datetime(data.register_acceptable_end_at, 'YYYY/MM/DD HH:mm:ss')
    );
  }
};

export default function paymentMethod(data: PaymentMethodData): PaymentMethod {
  if (data == null) return null;
  const isAppleIAP = data.payment_method_id == 6 || data.pay_method == 'apple';
  if (isAppleIAP) {
    data.name = PAYMENT_METHOD_LABEL.APPLE;
    data.display_name = PAYMENT_METHOD_LABEL.APPLE;
  }
  const isMerpay = data.payment_method_id == 7 || data.pay_method == 'merpay';
  if (isMerpay) {
    data.name = PAYMENT_METHOD_LABEL.MERPAY;
    data.display_name = PAYMENT_METHOD_LABEL.MERPAY;
  }
  const isCredit3ds = data.payment_method_id == 8 || data.pay_method == 'credit3ds';
  // 3dsカードはクレジットカードとして扱う（payment_method_idは8のまま）
  if (isCredit3ds) {
    data.name = PAYMENT_METHOD_LABEL.CARD;
    data.display_name = PAYMENT_METHOD_LABEL.CARD;
    data.pay_method = PAY_METHOD.CARD;
  }

  return {
    id: data.payment_method_id,
    isAppleIAP: isAppleIAP,
    isAmazonIAP: data.payment_method_id == 5,
    name: data.name,
    service: data.service,
    displayName: data.display_name ? data.display_name : data.name,
    paymentMethodTypeName: data.payment_method_type_name,
    paymentMethodType: data.payment_method_type,
    payMethod: data.pay_method,
    mode: data.mode,
    priority: data.priority, // 優先度
    limit: data.limit, // 同時登録許可数
    registable: isRegistable(data),
  };
}

/**
 * 支払い方法に関する処理を行います。
 */
const PaymentMethodUtils = {
  filterByDisplayPaymentMethod: filterByDisplayPaymentMethod,
};
export { PaymentMethodUtils };
