import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import SubscriptionInfo from '../common/SubscriptionInfo';
import EnqueteForm from './SubscribeFinish/EnqueteForm';
import SuggestForm from './SubscribeFinish/SuggestForm';
import PaymentInstrumentInfo from '../common/PaymentInstrumentInfo';
import datetime from 'src/domain/libs/datetime';

const SubscribeFinish = props => {
  const FreeTrialInfo = props => {
    if (_.get(props, 'subscription.status') != 'trial') {
      return null;
    }

    // トライアルキャンセル不可/最低課金回数がある/最低契約期間がある場合は非表示
    const course = props.course || {};
    if (!course.trialCancelableFlag || course.minTransactionCount || course.minContractTerm) {
      return null;
    }

    const displayEndDate = datetime(_.get(props, 'subscription.trialEndAt'), 'YYYY/MM/DD HH:mm:ss').format('YYYY/M/D');
    return (
      <React.Fragment>
        <h3>無料トライアルがスタートしました。</h3>
        {displayEndDate}までに解約すれば、料金が請求されることはありません。
        <br />
        {props.children}
      </React.Fragment>
    );
  };

  const PaymentInfo = props => {
    if (!props.paymentInstrument) {
      return null;
    }

    return (
      <div className="recipe-table-row clearfix">
        <div className="title">お支払い方法</div>
        <div className="item">
          <PaymentInstrumentInfo {...props} />
        </div>
      </div>
    );
  };

  const StartArea = props => {
    if (props.question) {
      return (
        <React.Fragment>
          <hr className="hr-line margin-long" />
          <EnqueteForm
            question={props.question}
            returnTo={props.returnTo}
            // @ts-ignore TS2322
            formData={props.formData}
            subscription={props.subscription}
          />
        </React.Fragment>
      );
    } else if (props.redirectTo) {
      return (
        <div className="action-buttons">
          <a className="btn btn-fill" href="javascript:void(0)" onClick={props.redirectTo}>
            視聴する
          </a>
        </div>
      );
    } else if (props.returnTo) {
      return (
        <div className="action-buttons">
          <a className="btn btn-fill" href={props.returnTo}>
            視聴する
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const cancelNode = (
    <React.Fragment>
      <p className="introduction-text">
        キャンセルするには、<a href="/account">契約状態の確認・解約</a>へ進み、解約をクリックしてください。
      </p>
      <p className="introduction-text">
        お楽しみいただいている場合は、特に何もお手続きしなくても自動的に継続されます。
      </p>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      {props.header ? props.header : <h2>契約完了</h2>}
      <FreeTrialInfo {...props}>
        {props.suggest && _.get(props, 'subscription.status') == 'trial' ? null : cancelNode}
      </FreeTrialInfo>

      {props.suggest && _.get(props, 'subscription.status') == 'trial' ? (
        <React.Fragment>
          {/*
           // @ts-expect-error TS2322 */}
          <SuggestForm
            returnTo={props.returnTo}
            suggest={props.suggest}
            // @ts-ignore TS2322
            formData={props.formData}
            subscription={props.subscription}
          >
            <hr className="hr-line margin-long" />
            {/* <AccountInfo {...props} /> */}
            <div className="section-title">
              <h2 className="contract-info">お客様のご契約内容</h2>
            </div>
            <SubscriptionInfo {...props} />
            <hr className="hr-line margin-long" />
            {cancelNode}
          </SuggestForm>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <AccountInfo {...props} /> */}
          <div className="section-title">
            <h2 className="contract-info">お客様のご契約内容</h2>
          </div>
          <div className="section-body">
            <SubscriptionInfo {...props} />
          </div>
          <StartArea {...props} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

SubscribeFinish.propTypes = {
  subscription: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  paymentInstrument: PropTypes.object,
  question: PropTypes.object,
  returnTo: PropTypes.string,
};

export default SubscribeFinish;
