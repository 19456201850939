import React, { Component } from 'react';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import window from 'global/window';

import * as DOMUtils from '../../../sketch-platform/utils/DOMUtils';
import * as browserEvents from '../../../sketch-platform/utils/browserEvents';
import MainViewLink from './MainViewLink';

const FadeLeft = props => {
  const classNames = classnames('fade-left', {
    scrolling: !props.isLeftEdge && props.canScroll,
  });
  return (
    <div {...props} className={classNames}>
      <i className="fa fa-angle_left"></i>
    </div>
  );
};

const FadeRight = props => {
  const classNames = classnames('fade-right', {
    scrolling: !props.isRightEdge && props.canScroll,
  });
  return (
    <div {...props} className={classNames}>
      <i className="fa fa-angle_right"></i>
    </div>
  );
};

const SLIDE_SPEED = 100;
const SLIDE_SPEED_s = 0.1;

export default class ExclusiveUiTab extends Component {
  static get propTypes() {
    return {
      navLinks: PropTypes.arrayOf(PropTypes.element),
      showSecondaryNavigationLinks: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.exclusiveUiTabNaviRef = React.createRef();

    this.clearTimer = this.clearTimer.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.getActiveTabIndex = this.getActiveTabIndex.bind(this);
    this.getTabList = this.getTabList.bind(this);
    this.renderTabLinks = this.renderTabLinks.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleDropdownClick = this.handleDropdownClick.bind(this);

    this.state = {
      isLeftEdge: true,
      isRightEdge: false,
      canScroll: false,
      animating: false,
      activeTabIndex: null,
    };

    const browserInfo = context.getModelData('browserInfo');
    // @ts-ignore TS2339
    this.tabs = _.map(this.props.tabs, (item, i) => {
      if (!item) return null;

      if (item.type === 'dropdown') {
        const active = item.active;
        const dropdownRef = React.createRef();
        const dropdownProps = {
          className: classnames('dropdown-menu', { active }),
          ref: dropdownRef,
        };

        // @ts-ignore TS2339
        dropdownProps.tabIndex = 0;
        // @ts-ignore TS2339
        dropdownProps.onClick = () => {
          this.handleDropdownClick({ ...item, ref: dropdownRef });
        };
        if (!browserInfo.isIOS && !browserInfo.isAndroid) {
          // @ts-ignore TS2339
          dropdownProps.onFocus = () => {
            this.handleFocus({ ...item, ref: dropdownRef });
          };
          // @ts-ignore TS2339
          dropdownProps.onMouseEnter = () => {
            this.handleFocus({ ...item, ref: dropdownRef });
          };
          // @ts-ignore TS2339
          dropdownProps.onMouseLeave = () => {
            // @ts-ignore TS2554
            this.handleMouseLeave();
          };
        }

        let label = item.name;
        if (active) {
          const selected = _.find(item.items, _item => _item.active);
          if (selected) label = selected.name;
        }
        // @ts-ignore TS2322
        return <span {...dropdownProps}>{label}</span>;
      } else {
        const onClick = () => {
          if (item.onClick) item.onClick();
        };
        const linkProps = item.linkProps;
        return (
          <MainViewLink
            // className={classnames({ active: linkProps.active })}
            key={`tablinks-${i}`}
            onClick={onClick}
            {...linkProps}
          >
            {item.name}
          </MainViewLink>
        );
      }
    });
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.handleOrientationChange);
    } else {
      browserEvents.addEventListener('resize', this.handleResize);
    }

    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    this.setState({ canScroll: this.canScroll() });
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.handleOrientationChange);
    } else {
      browserEvents.removeEventListener('resize', this.handleResize);
    }
    // @ts-ignore TS2339
    if (this.animatingEndCallback) clearTimeout(this.animatingEndCallback);
    this.clearTimer();
  }

  componentDidUpdate(prevProps, prevState) {
    // @ts-ignore TS2339
    const { width } = DOMUtils.getRect(this.exclusiveUiTabNaviRef.current);
    // @ts-ignore TS2339
    this.offsetLeft = width;
    this.getActiveTabIndex();
    // @ts-ignore TS2339
    if (this.state.activeTabIndex != prevState.activeTabIndex) this.scrollToActiveTab();
    this.getTabsWidth();
    // @ts-ignore TS2339
    if (this.state.canScroll !== this.canScroll()) {
      // @ts-ignore TS2554
      this.setState({ canScroll: this.canScroll() });
    }
  }

  clearTimer() {
    // @ts-ignore TS2339
    if (this.showMenuTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.showMenuTimeoutId);
      // @ts-ignore TS2339
      delete this.showMenuTimeoutId;
    }
  }

  handleScroll(e) {
    // @ts-ignore TS2339
    const maxScrollLeft = (e.target.scrollWidth || 0) - this.offsetLeft;
    this.setState({
      isLeftEdge: 0 > e.target.scrollLeft - 5,
      isRightEdge: maxScrollLeft < e.target.scrollLeft + 5,
    });
  }

  handleOrientationChange(e) {
    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    this.setState({ canScroll: this.canScroll() });
  }

  handleResize(e) {
    // @ts-ignore TS2554
    this.scrollToActiveTab();
    // @ts-ignore TS2554
    const canScroll = this.canScroll();
    this.setState({ canScroll });
    // @ts-ignore TS2339
    if (!canScroll && this.state.isRightEdge) {
      this.setState({ isRightEdge: false });
    }
  }

  getTabList() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    let navLinks = this.props.navLinks;
    let sportsTabLinks;

    // @ts-ignore TS2339
    if (navLinks && (!this.props.showSecondaryNavigationLinks || browserInfo.isIOS || browserInfo.isAndroid)) {
      //1024px以下
      //スマホorタブレット時
      // @ts-ignore TS2339
      sportsTabLinks = navLinks.concat(this.tabs);
      // @ts-ignore TS2339
    } else if (this.props.showSecondaryNavigationLinks) {
      // @ts-ignore TS2339
      sportsTabLinks = this.tabs;
    }
    return sportsTabLinks;
  }
  getTabsWidth() {
    // @ts-ignore TS2339
    let sportsTabNaviChild = this.exclusiveUiTabNaviRef.current.children;
    let sportsTab = [];
    // @ts-ignore TS2339
    if (this.props.showSecondaryNavigationLinks) {
      _.map(sportsTabNaviChild, (NaviChild, j) => {
        if (NaviChild.classList.contains('genremenu-list')) {
          sportsTab.push(NaviChild);
        }
      });
      sportsTabNaviChild = null;
      sportsTabNaviChild = sportsTab;
    }
    return _.map(sportsTabNaviChild, (_, i) => {
      const { width } = DOMUtils.getRect(sportsTabNaviChild[i]);
      if (width > 0) {
        return width;
      }
    });
  }

  canScroll(windowWidth) {
    if (!windowWidth) {
      windowWidth = DOMUtils.getWindowRect().width;
    }
    if (document) {
      let scrollbarWidth = windowWidth - document.body.clientWidth;
      windowWidth = windowWidth - scrollbarWidth;

      // @ts-ignore TS2339
      const navRef = getComputedStyle(this.exclusiveUiTabNaviRef.current);
      const navRefWidth = Number(navRef.width.replace('px', ''));
      const tabsWidth = this.getTabsWidth();
      const tabWidthTotal = this.sumWidth(tabsWidth);

      return navRefWidth < tabWidthTotal;
    }
  }

  sumWidth(widthList) {
    return _.reduce(widthList, (sum, width) => {
      return sum + width;
    });
  }

  getActiveTabIndex() {
    let structureIdKey;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    let tabList = this.getTabList();
    if (tabList) {
      _.map(tabList, (link, i) => {
        const linkChild = link.props;
        if (linkChild.to) {
          // @ts-expect-error TS2339
          if (linkChild.to.makePath(linkChild.params) === this.context.routeHandler.path) {
            structureIdKey = i;
          }
        } else if (linkChild.children) {
          // @ts-expect-error TS2339
          if (linkChild.children.props && linkChild.children.props.href === this.context.routeHandler.path) {
            structureIdKey = i;
          }
        }
      });
    }
    // @ts-expect-error TS2339
    if (!structureIdKey || ((browserInfo.isIOS || browserInfo.isAndroid) && this.context.routeHandler.path === '/')) {
      structureIdKey = -1;
    }
    // @ts-ignore TS2339
    if (structureIdKey !== this.state.activeTabIndex) {
      this.setState({ activeTabIndex: structureIdKey });
    }
    return structureIdKey;
  }

  scrollToActiveTab(windowWidth) {
    if (typeof window === 'undefined') return;

    if (!windowWidth) {
      windowWidth = DOMUtils.getWindowRect().width;
    }
    const tabsWidth = this.getTabsWidth();
    const tabWidthTotal = this.sumWidth(tabsWidth);
    // @ts-ignore TS2339
    let activeTabIndex = this.state.activeTabIndex;
    if (!activeTabIndex) {
      activeTabIndex = this.getActiveTabIndex();
    }
    if (activeTabIndex === -1) {
      // メニューにないページでもスクロール位置は変えない
      return;
    }
    // @ts-ignore TS2339
    if (this.props.center) {
      // @ts-ignore TS2339
      const { width, left } = DOMUtils.getRect(this.exclusiveUiTabNaviRef.current.children[activeTabIndex]);
      // @ts-ignore TS2339
      this.exclusiveUiTabNaviRef.current.scrollLeft =
        // @ts-ignore TS2339
        this.exclusiveUiTabNaviRef.current.scrollLeft + left - windowWidth / 2 + width / 2;
      return;
    }

    if (0 < activeTabIndex && windowWidth < tabWidthTotal) {
      const tabWidthToLeftOfActiveTabs = _.transform(tabsWidth, (result, width, i) => {
        // @ts-ignore TS2339
        result.push(Math.round(width));
        return activeTabIndex == i;
      });
      // @ts-ignore TS2339
      this.exclusiveUiTabNaviRef.current.scrollLeft = this.sumWidth(tabWidthToLeftOfActiveTabs);
    }
  }

  /**日数分 or 7日分の少ない方をスクロール */
  handleButtonClick(type) {
    // @ts-ignore TS2339
    if (this.state.animating) return;
    /**表示されている日付の一番左 */
    let leftEdgeIdx;
    /**表示されている日付の一番右 */
    let rightEdgeIdx;
    // @ts-ignore TS2339
    const children = this.exclusiveUiTabNaviRef.current.children;
    // @ts-ignore TS2339
    const parentRect = this.exclusiveUiTabNaviRef.current.getBoundingClientRect();
    for (let i = 0; i < children.length; i++) {
      if (children[i].getBoundingClientRect().left > parentRect.left) {
        if (leftEdgeIdx == undefined) leftEdgeIdx = i;
        if (children[i].getBoundingClientRect().right > parentRect.right) {
          rightEdgeIdx = i - 1;
          break;
        }
      }
    }
    const tabsWidth = this.getTabsWidth();
    /**表示されている日数 */
    const displayDate = (rightEdgeIdx || tabsWidth.length - 1) - leftEdgeIdx + 1;
    const BASIC_SCROll_DATE = 1;
    // @ts-ignore TS2339
    const currentScrollLeft = this.exclusiveUiTabNaviRef.current.scrollLeft;
    // @ts-ignore TS2339
    const maxScrollLeft = (this.exclusiveUiTabNaviRef.current.scrollWidth || 0) - this.offsetLeft;
    /**スクロールする日数 */
    const scrollDate = displayDate > BASIC_SCROll_DATE ? BASIC_SCROll_DATE : displayDate;
    let scrollWidth = 0;
    // それぞれの端のidxの次の数字からn日数分のwidthスクロールする
    for (let i = 0; i < scrollDate; i++) {
      if (type == 'left' && leftEdgeIdx - i - 1 >= 0) {
        scrollWidth += tabsWidth[leftEdgeIdx - i - 1];
      } else if (type == 'right' && rightEdgeIdx + i + 1 < tabsWidth.length) {
        scrollWidth += tabsWidth[rightEdgeIdx + i + 1];
      } else {
        scrollWidth = type == 'left' ? currentScrollLeft : maxScrollLeft - currentScrollLeft;
        break;
      }
    }
    // 絶妙なスクロール位置によってはスクロールされ過ぎてしまう場合があるので
    if (type == 'left') {
      scrollWidth = currentScrollLeft > scrollWidth ? scrollWidth : currentScrollLeft;
    } else {
      scrollWidth = maxScrollLeft - currentScrollLeft > scrollWidth ? scrollWidth : maxScrollLeft - currentScrollLeft;
    }
    // @ts-ignore TS2339
    this.scrollWidth = type == 'left' ? scrollWidth : -scrollWidth;

    let callback;
    this.setState({ animating: true });

    callback = () => {
      // @ts-ignore TS2339
      this.scrollWidth = 0;
      // @ts-ignore TS2339
      this.exclusiveUiTabNaviRef.current.scrollLeft += type == 'left' ? -scrollWidth : scrollWidth;
      this.setState({ animating: false });
      // @ts-ignore TS2339
      delete this.animatingEndCallback;
    };
    // @ts-ignore TS2339
    if (this.animatingEndCallback) clearTimeout(this.animatingEndCallback);
    // @ts-ignore TS2339
    this.animatingEndCallback = setTimeout(callback, SLIDE_SPEED);
  }

  doScroll(step) {
    const loop = () => {
      // @ts-ignore TS2339
      this.exclusiveUiTabNaviRef.current.scrollLeft = this.exclusiveUiTabNaviRef.current.scrollLeft + step;
      // @ts-ignore TS2339
      if (this.onPress) requestAnimationFrame(loop);
    };
    requestAnimationFrame(loop);
  }

  renderTabLinks() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    let sliderContentStyle = {};
    // @ts-ignore TS2339
    if (this.state.animating) {
      let sliderContentTransform = '';
      // @ts-ignore TS2339
      if (this.scrollWidth !== 0) {
        // @ts-ignore TS2339
        sliderContentTransform = `translate(${this.scrollWidth}px, 0px)`;
      }
      sliderContentStyle = {
        MsTransform: sliderContentTransform,
        WebkitTransform: sliderContentTransform,
        transform: sliderContentTransform,
        WebkitTransition: `-webkit-transform ${SLIDE_SPEED}ms ease 0s`,
        OTransition: `-o-transform ${SLIDE_SPEED}ms ease 0s`,
        MozTransition: `transform ${SLIDE_SPEED}ms ease 0s, -moz-transform ${SLIDE_SPEED_s}s ease 0s`,
        transition: `transform ${SLIDE_SPEED}ms ease 0s, -webkit-transform ${SLIDE_SPEED_s}s ease 0s, -moz-transform ${SLIDE_SPEED_s}s ease 0s, -o-transform ${SLIDE_SPEED_s}s ease 0s`,
        pointerEvents: 'none',
      };
    }
    const tabLinks = [];

    // @ts-ignore TS2339
    _.forEach(this.tabs, (linkComponent, i) => {
      tabLinks.push(
        <li key={`exclusive-ui-list-${i}`} className="exclusive-ui-list" style={sliderContentStyle}>
          {linkComponent}
        </li>,
      );
    });
    return tabLinks;
  }

  handleFocus(item) {
    this.clearTimer();
    this.setState({ openDropdown: item });
  }

  handleDropdownClick(item = {}) {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    const body = document.getElementsByTagName('body')[0];
    // @ts-ignore TS2339
    if (!this.state.openDropdown || item.key !== this.state.openDropdown.key) {
      this.clearTimer();
      this.setState({ openDropdown: item });
      if ((browserInfo.isIOS || browserInfo.isAndroid) && !body.classList.contains('no_scroll')) {
        body.classList.add('no_scroll');
      }
    } else {
      this.setState({ openDropdown: null });
      if ((browserInfo.isIOS || browserInfo.isAndroid) && body.classList.contains('no_scroll')) {
        body.classList.remove('no_scroll');
      }
    }
  }

  handleMouseLeave(e) {
    // @ts-ignore TS2339
    if (!this.showMenuTimeoutId) {
      // @ts-ignore TS2339
      this.showMenuTimeoutId = setTimeout(() => {
        this.setState({ openDropdown: null });
      }, 200);
    }
  }

  render() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    let dropdown = null;
    // @ts-ignore TS2339
    if (this.state.openDropdown) {
      // @ts-ignore TS2339
      const linkList = _.map(this.state.openDropdown.items, item => {
        return (
          <li>
            <MainViewLink {...item.linkProps}>{item.name}</MainViewLink>
          </li>
        );
      });
      const dropdownProps = {};
      // @ts-ignore TS2339
      const dropDownEl = _.get(this.state.openDropdown, 'ref.current');
      if (dropDownEl) {
        // @ts-ignore TS2339
        const tabRect = this.exclusiveUiTabNaviRef.current.getBoundingClientRect();
        const dropDownRect = dropDownEl.getBoundingClientRect();
        const left = Math.abs(dropDownRect.left - tabRect.left);
        const right = Math.abs(dropDownRect.right - tabRect.right);
        const style = {};
        if (left > right) {
          // @ts-ignore TS2339
          style.right = right;
        } else {
          // @ts-ignore TS2339
          style.left = left;
        }
        // @ts-ignore TS2339
        dropdownProps.style = style;
      }
      if (!browserInfo.isIOS && !browserInfo.isAndroid) {
        // @ts-ignore TS2339
        dropdownProps.onFocus = () => {
          // @ts-ignore TS2339
          this.handleFocus(this.state.openDropdown);
        };
        // @ts-ignore TS2339
        dropdownProps.onMouseEnter = () => {
          // @ts-ignore TS2339
          this.handleFocus(this.state.openDropdown);
        };
        // @ts-ignore TS2339
        dropdownProps.onMouseLeave = () => {
          // @ts-ignore TS2554
          this.handleMouseLeave();
        };
      }
      dropdown = (
        <div className="dropdown-menu-open" {...dropdownProps}>
          <ul className="dropdown-menu-open-" role="list">
            {linkList}
          </ul>
        </div>
      );
    }
    let tabContent = (
      <React.Fragment>
        {browserInfo.isIOS || browserInfo.isAndroid ? null : (
          <React.Fragment>
            <FadeLeft
              // @ts-ignore TS2339
              isLeftEdge={this.state.isLeftEdge}
              // @ts-ignore TS2339
              canScroll={this.state.canScroll}
              onClick={() => this.handleButtonClick('left')}
            />
            <FadeRight
              // @ts-ignore TS2339
              isRightEdge={this.state.isRightEdge}
              // @ts-ignore TS2339
              canScroll={this.state.canScroll}
              onClick={() => this.handleButtonClick('right')}
            />
          </React.Fragment>
        )}
        <ul
          // @ts-ignore TS2339
          ref={this.exclusiveUiTabNaviRef}
          // @ts-ignore TS2339
          className={classnames('gallery-tab-navi', { 'on-animating': this.state.animating })}
          onScroll={this.handleScroll}
        >
          {this.renderTabLinks()}
        </ul>
        {dropdown}
      </React.Fragment>
    );

    return (
      <div className="exclusive-ui-tab">
        <div className="exclusive-ui-tab-wrapper gallery-tab-wrapper">{tabContent}</div>
      </div>
    );
  }
}
