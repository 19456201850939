import React from 'react';
import MainViewLink from 'src/apps/common/components/MainViewLink';
import routes from '../../../common/routes';

type SvButtonLangProps = {
  svMetaId: string;
  svType: string;
};

const SvLangButton = function SvLangButton(props: SvButtonLangProps) {
  let svBtnText;
  if (props.svType === 'jp') {
    svBtnText = '日本語実況に切り替える';
  } else {
    svBtnText = '現地音声に切り替える';
  }

  return (
    // @ts-expect-error TS2322
    <MainViewLink
      to={routes.content}
      params={{ id: props.svMetaId }}
      className="player-control-element player-button-control"
    >
      <i className="fa fa-microphone"></i>
      <span>{svBtnText}</span>
    </MainViewLink>
  );
};

export default React.memo<SvButtonLangProps>(SvLangButton);
