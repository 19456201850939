import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import EventInfo from '../../../browse/Epg/EventInfo';
import WatchInfo from '../WatchInfo';
import useShowPlanInfo from '../../hooks/useShowPlanInfo';
import ViewingPlan from '../../../../../common/components/UpSellFlow/ViewingPlan';
import PlanInfoTab from '../PlanInfoTab';
import activeProfile from '../../../../../../utils/activeProfile';

const PlanInfoContent = (props, context) => {
  const onChangeTab = useCallback(
    tabKey => {
      props.onChangeTab(tabKey);
    },
    [props],
  );
  const profile = activeProfile(context.models);
  const showPlanInfo = useShowPlanInfo({
    meta: props.meta,
    event: props.activeEvent,
    products: props.products,
    courses: props.courses,
    error: props.error,
    profile,
  });

  return (
    <>
      {showPlanInfo && <PlanInfoTab onChange={onChangeTab} tabKey={props.tabKey} />}
      {showPlanInfo && props.tabKey === 'plan' ? (
        <div className="plan-info">
          <ViewingPlan
            meta={props.meta}
            event={props.activeEvent}
            products={props.products}
            courses={props.courses}
            doAction={props.doAction}
            checkModal={props.checkModal}
            spMode={props.spMode}
          />
        </div>
      ) : props.showWatchInfo ? (
        <WatchInfo {...props} scheduleButton={null} planInfoRenderCheck={showPlanInfo} hidePPVLimit={true} />
      ) : (
        <EventInfo {...props} myListButton={null} planInfoRenderCheck={showPlanInfo} />
      )}
    </>
  );
};

PlanInfoContent.contextTypes = {
  models: PropTypes.object,
};

export default React.memo(PlanInfoContent);
