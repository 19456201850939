import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckboxDiv from '../../../CheckboxDiv';
import SelectBox from '../../../SelectBox';

export default class EnqueteForm extends Component {
  static get propTypes() {
    return {
      question: PropTypes.object,
      returnTo: PropTypes.string,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.formRef = React.createRef();
    // @ts-ignore TS2339
    this.campaignRef = React.createRef();

    this._handleSubmit = this._handleSubmit.bind(this);
    this.handleCampaignClick = this.handleCampaignClick.bind(this);
    this.state = {
      isSubmittable: true,
    };
  }

  _handleSubmit(e) {
    // @ts-ignore TS2339
    if (this.state.isSubmittable) {
      this.setState({ isSubmittable: false });
      setTimeout(() => {
        // @ts-ignore TS2339
        this.formRef.current.submit();
      }, 100);
    }
    e.preventDefault();
    return false;
  }

  handleCampaignClick(e) {
    // @ts-ignore TS2339
    this.campaignRef.current.value = true;
    this._handleSubmit(e);
  }

  render() {
    // @ts-ignore TS2339
    const question = this.props.question;
    // @ts-ignore TS2339
    const returnTo = this.props.returnTo || '';

    if (!question) {
      return null;
    }

    return (
      // @ts-ignore TS2339
      <form action="/plans/answer_question" ref={this.formRef} method="post" onSubmit={this._handleSubmit}>
        <input type="hidden" name="return_to" value={returnTo} />
        <input type="hidden" value={question.id || question.question_id} name="question_id" />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.campaignRef} type="hidden" name="campaign" />
        <div>
          <p className="introduction-text">サービス利用前に簡単なアンケートにご協力をお願いします。(任意)</p>
        </div>
        <div className="enquete-block">
          <div className="input-block short-margin">{this._renderQuestionSchema(question.schema)}</div>
          <div className="form-btn-block center-btn">
            {/*
             // @ts-ignore TS2339 */}
            {this.state.isSubmittable ? (
              <input type="submit" className="btn btn-fill btn-large" value="開始する" />
            ) : (
              <div className="btn btn-fill btn-large waiting">開始する</div>
            )}
          </div>
        </div>
      </form>
    );
  }

  _renderQuestionSchema(schema) {
    return schema.map(
      ((row, i) => {
        if (row.type == 'const' && row.unique == false) {
          return this._renderQuestionCheckbox(row, i);
        } else if (row.type == 'const' && row.unique == true) {
          return this._renderQuestionSelect(row, i);
          return null;
        } else if (row.type == 'text') {
          return this._renderQuestionText(row, i);
        }
      }).bind(this),
    );
  }

  _renderQuestionText(row, i) {
    return (
      <div className="question-section" key={`${row.key_name}-${i}`}>
        <p className="question-text">{row.label}</p>
        <textarea defaultValue="" name={row.key_name} className="answer-other"></textarea>
      </div>
    );
  }

  _renderQuestionCheckbox(row, i) {
    const nodes = row.options.map((o, j) => {
      return (
        <CheckboxDiv
          key={`${row.key_name}-${i}-${j}`}
          name={`${row.key_name}[]`}
          value={o.value}
          checked={o.default_flag}
        >
          {o.label}
        </CheckboxDiv>
      );
    });

    return (
      <div className="question-section" key={`${row.key_name}-${i}`}>
        <p className="question-text">{row.label}</p>
        {nodes}
      </div>
    );
  }

  _renderQuestionSelect(row, i) {
    const options = row.options.map(o => {
      return [o.label, o.value];
    });
    options.unshift(['選択してください', null]);

    return (
      <div className="question-section" key={`${row.key_name}-${i}`}>
        <p className="question-text">{row.label}</p>
        <label htmlFor="selectA2" className="select-group">
          {/*
           // @ts-ignore TS2322 */}
          <SelectBox className="select" name={row.key_name} defaultValue="" options={options} />
        </label>
      </div>
    );
  }
}
