import React, { PureComponent } from 'react';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Axios from '../../../../common/Axios';
import * as ERROR from '../../../../constants/error';
import { withModalContext } from '../../../common/context/ModalContext';
import { PRODUCT_TYPE } from '../../../../constants/product';
// @ts-ignore TS2339
import nl2br from '../../../../common/nl2br';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';

import ProductEpisodeRow from './ProductEpisodeRow';
import ViewingPlan from '../../../common/components/UpSellFlow/ViewingPlan';
import CheckModal from '../../../common/components/modal/CheckModal';
import BackgroundImage from '../../../common/components/BackgroundImage';
import ProductUpSellFlow from '../../../common/components/ProductUpSellFlow';
import ErrorBoundary from '../../../common/components/ErrorBoundary';
import SetDate from '../../../bluerose/components/browse/SetDate';
import NotFound from '../../../generic/components/errors/NotFound';
import { CONTENT_EVENTS } from '../../../../common/GtmApp';
import ProductDetailModal from '../../../common/components/modal/ProductDetailModal';
import { Product as ProductType } from 'src/types/context/Product';

class Product extends PureComponent {
  static getPaths = function(models, options, props = {}) {
    let paths = [];
    const rootPath = this.getRootPath(models, options, props);
    paths = paths.concat([
      rootPath.concat([
        [
          'id',
          'activePricing',
          'buttonLabel',
          'displayProductType',
          'name',
          'notesOnPurchase',
          'onSale',
          'pricings',
          'productDescription',
          'productType',
          'rights',
          'salesEndAt',
          'salesStartAt',
          'thumbnailUrl',
          'salesLimitExceeded',
          'paymentMethodRule',
          'tvodDisplayUnit',
          'saleCourseRule',
        ],
      ]),
    ]);
    paths = paths.concat(
      ProductEpisodeRow.getPaths(models, options, { ...props, perPage: ProductEpisodeRow.defaultProps.perPage }),
    );
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.id) {
      // @ts-ignore TS2339
      return ['product', props.id, true];
    }
    return [];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const product = _.get(data.json, this.getRootPath(models, options, props));
      const rights = _.get(product, 'rights');
      const rightIds = _.uniq(_.map(rights, right => right.id)) || [];
      props = Object.assign({}, props, {
        rightIds,
        tvodDisplayUnit: _.get(product, 'tvodDisplayUnit'),
      });

      return this.getPaths(models, options, props);
    };
  };

  static afterPrefetch = function(models, options, props) {
    return data => {
      const rootPath = this.getRootPath(models, options, props);
      const product = _.get(data.json, rootPath) || {};

      if (!product) {
        // @ts-ignore TS2554
        return { error: new ERROR.NotfoundError() };
      }
    };
  };

  private product: ProductType;
  constructor(props, context) {
    super(props, context);

    this.showCheckModal = this.showCheckModal.bind(this);
    this.doAction = this.doAction.bind(this);
    this.checkLinkMetas = this.checkLinkMetas.bind(this);
    this.handeInfoOpen = this.handeInfoOpen.bind(this);

    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.product = this.model.getSync(rootPath) || {};
    // @ts-ignore TS2339
    this.rightIds = _.uniq(_.map(this.product.rights, right => right.id)) || [];
    // @ts-ignore TS2339
    this.authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2339
    this.axios = new Axios({ xhr: true });
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });

    this.state = {
      loading: true,
      isNoLinkRights: false,
      hasEntitlement: false,
      isPurchaseLimit: false,
      planSwitch: 'plan',
      spMode: false,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
      routeHandler: PropTypes.object,
    };
  }

  get isSpBrowser() {
    // @ts-expect-error TS2339
    const { isAndroid, isIOS, isRequestDesktopWebsite } = this.context.getModelData('browserInfo') || {};
    return isAndroid || isIOS || isRequestDesktopWebsite;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    // @ts-ignore TS2339
    if (_.isEmpty(this.product)) {
      this.fetchData();
    } else {
      this.checkProductPurchase();
    }

    // @ts-expect-error TS2339
    this.context.gtmApp.pageView();

    if (this.isSpBrowser) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
    this.checkMediaQuery();

    if (_.get(this.context, 'routeHandler.query.payment_instrument_id')) {
      // @ts-ignore TS2339
      this.props.showModal(
        <ErrorBoundary>
          <ProductUpSellFlow
            // @ts-ignore TS2322
            model={this.model}
            // @ts-ignore TS2339
            product={this.product}
            // @ts-ignore TS2339
            rightIds={this.rightIds}
            // @ts-expect-error TS2339
            defaultPaymentInstrumentId={_.get(this.context.routeHandler, 'query.payment_instrument_id')}
          />
        </ErrorBoundary>,
      );
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;

    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();

    if (this.isSpBrowser) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
  }

  checkMediaQuery() {
    let spMode = false;
    if (window.matchMedia('screen and (max-width:767px)').matches) {
      spMode = true;
    } else {
      spMode = false;
    }
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ spMode });
    // @ts-ignore TS2339
    else this.state.spMode = spMode;
  }

  showCheckModal() {
    // @ts-ignore TS2339
    this.props.showModal(<CheckModal closeModal={this.props.closeModal} />);
  }

  handleError(error) {
    const commonNewState = { loading: false };
    const basicErrors = [ERROR.AVAILABLE_ENTITLEMENT, ERROR.PURCHASE_LIMIT];

    // APIエラーまるめる
    if (_.includes(basicErrors, _.get(error, 'commitError.message'))) {
      // @ts-ignore TS2339
      this.error = new Error(error.commitError.message);
    } else {
      // @ts-ignore TS2339
      this.error = error;
    }

    // @ts-ignore TS2339
    if (_.get(this.error, 'message') === ERROR.AVAILABLE_ENTITLEMENT) {
      this.setState({ ...commonNewState, hasEntitlement: true });
      return;
    }

    // @ts-ignore TS2339
    if (_.get(this.error, 'message') === ERROR.PURCHASE_LIMIT) {
      this.setState({ ...commonNewState, isPurchaseLimit: true });
      return;
    }

    // @ts-ignore TS2339
    this.setState({ ...commonNewState, error: this.error });
    return;
  }

  /**
   * 既に商品の権利を持っているかのチェック
   * @returns
   */
  checkProductPurchase() {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // @ts-ignore TS2339
    if (!this.authContext || _.isEmpty(this.product)) {
      this.setState({ loading: false });
      return;
    }

    // @ts-ignore TS2339
    if (_.isEmpty(this.rightIds)) {
      // 商品に紐づく権利がない場合
      this.setState({ isNoLinkRights: true, loading: false });
      return;
    }

    return (
      // @ts-ignore TS2339
      this.axios
        // @ts-ignore TS2339
        .get('/api/entitlement/users/query', { right_id: this.rightIds })
        .then(result => {
          if (_.get(result, 'data.result') === false) {
            throw _.get(result, 'data.error');
          }

          // @ts-ignore TS2339
          this.entitlements = _.get(result, 'data.entitlements');

          // @ts-ignore TS2339
          if (_.size(this.entitlements) > 0) {
            throw new Error(ERROR.AVAILABLE_ENTITLEMENT);
          }

          return this.checkPurchaseLimit();
        })
        .catch(e => {
          this.handleError(e);
        })
    );
  }

  /**
   * 商品が販売上限数に達していないかのチェック
   * @returns
   */
  checkPurchaseLimit() {
    // @ts-ignore TS2339
    if (!this._isMounted || this.state.hasEntitlement) return;

    // 商品全体ですでに上限に達している場合は確認不要
    if (this.product && this.product.salesLimitExceeded) {
      this.handleError(new Error(ERROR.PURCHASE_LIMIT));
      return;
    }

    return (
      // @ts-ignore TS2339
      this.axios
        // @ts-ignore TS2339
        .post('/api/account/purchase/check', { product_id: this.product.id })
        .then(result => {
          if (_.get(result, 'data.result') === false) {
            const errorCode = _.get(result, 'data.error.code');
            const purchaseLimitErrorCode = [21012, 21013]; // 販売上限エラー
            const isPurchaseLimitError = _.some(purchaseLimitErrorCode, code => errorCode == code);

            if (isPurchaseLimitError) {
              throw new Error(ERROR.PURCHASE_LIMIT);
            }

            throw _.get(result, 'data.error');
          }

          this.setState({ loading: false });
        })
        .catch(e => {
          this.handleError(e);
        })
    );
  }

  doAction(options: { returnTo: string }) {
    // @ts-ignore TS2339
    if (this.authContext) {
      // @ts-expect-error TS2339
      const browserInfo = this.context.getModelData('browserInfo');
      const isSP = browserInfo.isAndroid || (browserInfo.isIOS && !browserInfo.isiPad);
      // @ts-ignore TS2339
      const productId = this.product.id;

      // スマホの場合はGenericのUpSellFlowへ遷移させる
      if (isSP) {
        window.location.href = `/pusf/${productId}/confirm?return_to=${options.returnTo}`;
      } else {
        // @ts-ignore TS2339
        this.props.showModal(
          <ErrorBoundary>
            {/*
             // @ts-ignore TS2322 */}
            <ProductUpSellFlow model={this.model} product={this.product} rightIds={this.rightIds} />
          </ErrorBoundary>,
        );
      }
    } else {
      if (!_.get(this.context, 'gtmApp')) return;

      // @ts-expect-error TS2339
      this.context.gtmApp.pushDataLayerOnContentPageClick(CONTENT_EVENTS.LOGIN_REQUEST_CLICK);
    }
  }

  checkLinkMetas(linkMetaCount) {
    if (linkMetaCount == 0) {
      this.setState({ isNoLinkMetas: true });
    } else {
      this.setState({ isNoLinkMetas: false });
    }
  }

  fetchData(props = this.props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    this.setState({ dispose, loading: true });
    const newState = {
      dispose: null,
    };

    evaluator
      .then(res => {
        const product = _.get(res, ['json'].concat(rootPath)) || {};
        // @ts-ignore TS2339
        this.product = _.omit(product, ['$__path']);
        // @ts-ignore TS2339
        this.rightIds = _.uniq(_.map(this.product.rights, right => right.id)) || [];

        // @ts-ignore TS2339
        newState.isNoLinkRights = _.isEmpty(this.rightIds);
        // @ts-ignore TS2339
        newState.fetchDataError = null;
      })
      .catch(e => {
        // @ts-ignore TS2339
        newState.fetchDataError = e;
        newState.dispose = null;
      })
      .finally(() => {
        delete this.state[JSON.stringify(paths)];
        this.setState(newState);
        this.checkProductPurchase();
      });
  }

  renderMetadataBox() {
    // @ts-ignore TS2339
    const { isNoLinkRights, isNoLinkMetas, hasEntitlement, error } = this.state;
    const onClickProductDetail = () => {
      // @ts-ignore TS2339
      this.props.showModal(<ProductDetailModal onClickClose={this.props.closeModal} product={this.product} />);
    };

    // 商品に権利が紐づいていない場合 or 権利にメタが紐づいていない場合 or エラーの場合
    // 下記エラー無視 {status: 422, code: 21018, message: '販売対象コースの契約がありません', type: 'Unprocessable Entity'}
    if (isNoLinkRights || isNoLinkMetas || (error && error.status !== 422 && error.code !== 21018)) {
      return (
        <div className="plan-info">
          <div className="plan-info__item ppv">
            <div className="plan-info__item__li product-btn">
              <div className="plan-info__item__li__name">こちらの商品は購入いただけません。</div>
            </div>
          </div>
        </div>
      );
    }

    // 既に購入済みの場合
    if (hasEntitlement) {
      // @ts-ignore TS2339
      const maxEntitlementEndAt = _.get(_.maxBy(this.entitlements, 'endAt'), 'endAt');

      return (
        <div className="plan-info">
          <div className="plan-info__item ppv">
            <div className="plan-info__item__li product-btn">
              <div className="plan-info__item__li__name">すでに購入済みの商品です。</div>
              <div className="plan-info__item__li date">
                {maxEntitlementEndAt && (
                  <div className="plan-info__item__li__date">
                    <span>PPV期限</span>
                    {/*
                    // @ts-ignore TS2322 */}
                    <SetDate format="fullja" date={maxEntitlementEndAt} txt=" まで" />
                  </div>
                )}
              </div>
            </div>
            {/*
            // @ts-ignore TS2339 */}
            {this.product.productDescription ? (
              <div className="plan-info__item__li">
                <div className="btn-container text-right">
                  <button type="button" className="plan-description-btn" onClick={onClickProductDetail}>
                    <i className="fa-info-circle"></i>商品詳細
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    // その他(未ログイン,未購入)
    return (
      <div className="plan-info">
        <ViewingPlan
          checkModal={this.showCheckModal}
          // @ts-ignore TS2339
          products={[this.product]}
          doAction={this.doAction}
          isProductPage={true} // 商品ページかどうか
          // @ts-ignore TS2339
          isDisabled={this.state.isPurchaseLimit}
          // @ts-ignore TS2339
          spMode={this.state.spMode}
        />
      </div>
    );
  }
  renderProductInfo() {
    return (
      <div className="watch-info wide">
        <div className="watch-info__colum_left">
          <div className="detail-text">
            <div className="detail-text___descritpion">
              {/* @ts-ignore TS2339 */}
              <p dangerouslySetInnerHTML={{ __html: nl2br(this.product.productDescription) }}></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handeInfoOpen(e) {
    this.setState({ planSwitch: e });
  }

  render() {
    // @ts-ignore TS2339
    if (this.state.loading) {
      return <div className="loading-spinner"></div>;
    }

    // @ts-ignore TS2339
    if (_.isEmpty(this.product) || !this.product.id) return <NotFound />;

    let tvodTag;

    // @ts-ignore TS2339
    if (this.product.productType === PRODUCT_TYPE.RENTAL) {
      tvodTag = 'PPV';
      // @ts-ignore TS2339
    } else if (this.product.productType === PRODUCT_TYPE.PURCHASE) {
      tvodTag = '購入商品';
    }

    return (
      <div className="watch-content">
        <div className="watch-main-visual">
          <div className="watch-main-visual__metadata">
            {/*
             // @ts-ignore TS2339 */}
            <h1 className="playable-title">{this.product.name}</h1>
            {tvodTag && (
              <div className="tag-list">
                <span className="tag tvod" key="ppv-tag">
                  {tvodTag}
                </span>
              </div>
            )}
            {/* @ts-ignore TS2339 */}
            {!this.state.spMode && this.renderMetadataBox()}
          </div>
          <div className="main-visual">
            <div className="video-preload-title">
              {/*
               // @ts-ignore TS2339 */}
              <div className="video-preload-title-label">{this.product.name}</div>
            </div>
            <BackgroundImage
              // @ts-ignore TS2322
              className={classnames('artwork', { noImage: !this.product.thumbnailUrl })}
              // @ts-ignore TS2339
              url={this.product.thumbnailUrl}
            />
          </div>
        </div>
        <div className="below-player">
          {/* @ts-ignore TS2339 */}
          {this.state.spMode && (
            <>
              <div className="below-tab">
                <div
                  className={classnames('below-tab__item', { active: _.get(this.state, 'planSwitch') == 'plan' })}
                  onClick={() => this.handeInfoOpen('plan')}
                >
                  購入
                </div>
                <div
                  className={classnames('below-tab__item', {
                    // @ts-ignore TS2322
                    noData: !this.product.productDescription,
                    active: _.get(this.state, 'planSwitch') == 'info',
                  })}
                  // @ts-ignore TS2322
                  onClick={() => (this.product.productDescription ? this.handeInfoOpen('info') : null)}
                >
                  商品詳細
                </div>
              </div>
              {/* @ts-ignore TS2339 */}
              {this.state.planSwitch == 'plan' ? this.renderMetadataBox() : this.renderProductInfo()}
            </>
          )}
          <ProductEpisodeRow
            //@ts-ignore TS2339
            model={this.model}
            //@ts-ignore TS2339
            rightIds={this.rightIds}
            checkLinkMetas={this.checkLinkMetas}
            //@ts-ignore TS2339
            tvodDisplayUnit={this.product.tvodDisplayUnit}
          />
        </div>
      </div>
    );
  }
}

export default withModalContext(Product);
