'use strict';

import _ from 'src/domain/libs/util';
import datetime from 'src/domain/libs/datetime';
import { QUESTION_TYPE } from '../models/questionSchema';
import { QuestionSchema } from 'src/types/context/Question';

const CREDIT_CARD_NUMBER_LENGTH_MIN = 14; // カード番号桁数（最小値）
const CREDIT_CARD_NUMBER_LENGTH_MAX = 16; // カード番号桁数（最大値）
const CREDIT_CARD_SECURITY_CODE_LENGTH_MIN = 3; // セキュリティコード桁数（最小値）
const CREDIT_CARD_SECURITY_CODE_LENGTH_MAX = 4; // セキュリティコード桁数（最大値）

const isRequired = function(value) {
  return _.trim(value) != '';
};
const isRuby = function(value) {
  return _.trim(value).match(/^[ァ-ヶー]*$/);
};
const isMailAddress = function(value) {
  return _.trim(value).match(/.+@.+\..+/);
};
const includeCapitalLetter = function(value) {
  return _.trim(value).match(/[A-Z]/);
};
const isDate = function(y, m, d) {
  let dt = new Date(y, m - 1, d);
  return dt.getFullYear() == y && dt.getMonth() == m - 1 && dt.getDate() == d;
};
const isNumeric = function(value) {
  return _.trim(value).match(/^\d+$/);
};
const isAlphaNumeric = function(value) {
  return _.trim(value).match(/^[A-Za-z0-9]+$/);
};
// 反角カナを含む全角文字
const is2BiteChar = function(value) {
  return _.trim(value).match(/[^\x01-\x7E]/);
};
const isZenkaku = function(value) {
  return _.trim(value).match(/^[^ -~｡-ﾟ\n\r]+$/);
};
const isZenkakuWithNl = function(value) {
  return _.trim(value).match(/^[^ -~｡-ﾟ]+$/);
};
const isHankaku = function(value) {
  return _.trim(value).match(/^[ -~｡-ﾟ]+$/);
};
const isHankakuWithNl = function(value) {
  return _.trim(value).match(/^[ -~｡-ﾟ\n\r]+$/);
};
// 半角英字と数字が1文字以上含まれる8文字以上、
const isPasswordFormat = function(value) {
  // 半角英数字各1文字以上
  // 半角英数字と記号のみ !"#$%&'()=~|-^\@[;:],./`{+*}>?_
  const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!"#\$%&'\(\)\=\~\|\{\}\[\]\;:,\.\/`<>\*\+\?_\\\-\^\@]{8,32}$/;
  return _.trim(value).match(pattern);
};
const isTel = function(tel1, tel2, tel3) {
  const FREE_DIALS = ['0120', '0800', '0570', '0990'];
  // tel1がフリーダイヤル系で、tel2+tel3の桁数が6桁未満の場合
  if (FREE_DIALS.includes(tel1) && `${tel2}${tel3}`.match(/^.{0,5}$/)) {
    return false;
  }

  // tel1が「03」で、tel2の最初の値が「3」「4」「5」「6」以外の場合
  if (tel1 == '043' && tel2.match(/^[^3456]/)) {
    return false;
  }

  // tel1が「06」の場合、tel2が4桁でない場合
  if (tel1 === '06' && tel2.length !== 4) {
    return false;
  }

  const tel = `${tel1}-${tel2}-${tel3}`;
  const TEL_PATTERNS = [
    /^(0\d{1,4}-\d{1,4}-\d{4})$/, // 0n-nnn-nnnn
    /^(0\d{2}-\d{4}-\d{4})$/, // 0n-nnnn-nnnn
    /^(0\d{3}-\d{2}-\d{4})$/, // 0nn-nnn-nnnn
    /^(0\d{4}-\d{1}-\d{4})$/, // 0nnn-nn-nnnn
    /^(0\d{4}-\d{2}-\d{3})$/, // 0nnnn-n-nnnn
    /^(0\d{2}-\d{2}-\d{4})$/, // 0nn-nn-nnnn
    /^(0\d{3}-\d{1}-\d{4})$/, // 0nnn-n-nnnn
    /^(0\d{3}-\d{4}-\d{4})$/, // 0nnn-nnnn-nnnn
    /^(0\d{2}-\d{3}-\d{4})$/, // 0nn-nnnn-nnnn
  ];
  // パターン以外の場合
  if (!TEL_PATTERNS.some(pattern => tel.match(pattern))) {
    return false;
  }
  // 9~11文字
  if (!tel.match(/^.{11,13}$/)) {
    return false;
  }

  return true;
};
const isTel1 = function(value) {
  if (!isNumeric(value)) {
    return false;
  }
  const INCORRECTS = ['010', '020', '030', '040', '0990', '0472', '0434'];
  if (INCORRECTS.includes(value)) {
    return false;
  }
  return true;
};
const isTel2 = function(value) {
  if (!isNumeric(value)) {
    return false;
  }
  // 最初の値が「0」の場合
  if (value.match(/^0/)) {
    return false;
  }
  return true;
};
const isTel3 = function(value) {
  if (!isNumeric(value)) {
    return false;
  }
  return true;
};
const questionTextarea = function(messages, row?: QuestionSchema, value = '') {
  if (!row) return;

  if (row.required && !isRequired(value)) {
    addMessage(messages, row.key_name, '入力してください。');
  } else if (row.halfAlphaOnlyFlag && !isAlphaNumeric(value)) {
    addMessage(messages, row.key_name, '半角英数字で入力してください。');
  } else if (value && row.fullCharOnlyFlag && !isZenkakuWithNl(value)) {
    addMessage(messages, row.key_name, '全角で入力してください。');
  } else if (value && row.halfCharOnlyFlag && !isHankakuWithNl(value)) {
    addMessage(messages, row.key_name, '半角で入力してください。');
  } else if (
    row.minLength != undefined &&
    row.maxLength != undefined &&
    value &&
    (value.length > row.maxLength || value.length < row.minLength)
  ) {
    let message = `${row.minLength}文字以上、${row.maxLength}文字以内で入力してください。`;
    if (row.minLength == row.maxLength) {
      message = `${row.minLength}文字で入力してください。`;
    }
    addMessage(messages, row.key_name, message);
  } else if (row.maxLength != undefined && value && value.length > row.maxLength) {
    addMessage(messages, row.key_name, `${row.maxLength}文字以内で入力してください。`);
  } else if (row.minLength != undefined && value && value.length < row.minLength) {
    addMessage(messages, row.key_name, `${row.minLength}文字以上で入力してください。`);
  }
};
const questionString = function(messages, row?: QuestionSchema | undefined, value = '') {
  if (!row) return;

  if (row.required && !isRequired(value)) {
    addMessage(messages, row.key_name, '入力してください。');
  } else if (row.halfAlphaOnlyFlag && !isAlphaNumeric(value)) {
    addMessage(messages, row.key_name, '半角英数字で入力してください。');
  } else if (value && row.fullCharOnlyFlag && !isZenkaku(value)) {
    addMessage(messages, row.key_name, '全角で入力してください。');
  } else if (value && row.halfCharOnlyFlag && !isHankaku(value)) {
    addMessage(messages, row.key_name, '半角で入力してください。');
  } else if (
    row.minLength != undefined &&
    row.maxLength != undefined &&
    value &&
    (value.length > row.maxLength || value.length < row.minLength)
  ) {
    let message = `${row.minLength}文字以上、${row.maxLength}文字以内で入力してください。`;
    if (row.minLength == row.maxLength) {
      message = `${row.minLength}文字で入力してください。`;
    }
    addMessage(messages, row.key_name, message);
  } else if (row.maxLength != undefined && value && value.length > row.maxLength) {
    addMessage(messages, row.key_name, `${row.maxLength}文字以内で入力してください。`);
  } else if (row.minLength != undefined && value && value.length < row.minLength) {
    addMessage(messages, row.key_name, `${row.minLength}文字以上で入力してください。`);
  }
};
const questionInteger = function(messages, row?: QuestionSchema, value = '') {
  if (!row) return;

  if (row.required && !isRequired(value)) {
    addMessage(messages, row.key_name, '入力してください。');
  } else if (value && row.fullCharOnlyFlag && !/^[０-９]+$/.test(value)) {
    addMessage(messages, row.key_name, '全角数字で入力してください。');
  } else if (value && !row.fullCharOnlyFlag && !/^[0-9]+$/.test(value)) {
    addMessage(messages, row.key_name, '半角数字で入力してください。');
  } else if (
    row.minLength != undefined &&
    row.maxLength != undefined &&
    value &&
    (value.length > row.maxLength || value.length < row.minLength)
  ) {
    let message = `${row.minLength}文字以上、${row.maxLength}文字以内で入力してください。`;
    if (row.minLength == row.maxLength) {
      message = `${row.minLength}文字で入力してください。`;
    }
    addMessage(messages, row.key_name, message);
  } else if (row.maxLength != undefined && value && value.length > row.maxLength) {
    addMessage(messages, row.key_name, `${row.maxLength}文字以内で入力してください。`);
  } else if (row.minLength != undefined && value && value.length < row.minLength) {
    addMessage(messages, row.key_name, `${row.minLength}文字以上で入力してください。`);
  }
};
const questionSelectBox = function(messages, row = {}, value = '') {
  // @ts-ignore TS2339
  if (row.required && (value == null || value == '')) {
    // @ts-ignore TS2339
    addMessage(messages, row.key_name, '選択してください。');
  }
};
const questionCheckBox = function(messages, row = {}, value = '') {
  // @ts-ignore TS2339
  if (row.required && _.isEmpty(value)) {
    // @ts-ignore TS2339
    addMessage(messages, row.key_name, '選択してください。');
  }
};
const questionHidden = function(messages, row?: QuestionSchema, value = '') {
  if (!row) return;

  if (row.required && !isRequired(value)) {
    addMessage(messages, row.key_name, '入力してください。');
  } else if (row.halfAlphaOnlyFlag && !isAlphaNumeric(value)) {
    addMessage(messages, row.key_name, '半角英数字で入力してください。');
  } else if (value && row.fullCharOnlyFlag && !isZenkaku(value)) {
    addMessage(messages, row.key_name, '全角で入力してください。');
  } else if (value && row.halfCharOnlyFlag && !isHankaku(value)) {
    addMessage(messages, row.key_name, '半角で入力してください。');
  } else if (
    row.minLength != undefined &&
    row.maxLength != undefined &&
    value &&
    (value.length > row.maxLength || value.length < row.minLength)
  ) {
    let message = `${row.minLength}文字以上、${row.maxLength}文字以内で入力してください。`;
    if (row.minLength == row.maxLength) {
      message = `${row.minLength}文字で入力してください。`;
    }
    addMessage(messages, row.key_name, message);
  } else if (row.maxLength != undefined && value && value.length > row.maxLength) {
    addMessage(messages, row.key_name, `${row.maxLength}文字以内で入力してください。`);
  } else if (row.minLength != undefined && value && value.length < row.minLength) {
    addMessage(messages, row.key_name, `${row.minLength}文字以上で入力してください。`);
  }
};
const addMessage = function(messages, key, value) {
  messages[key] = _.concat(_.defaultTo(messages[key], []), value);
};

export default {
  firstName: function(messages, value, message = '名が入力されていません。') {
    if (!isRequired(value)) {
      addMessage(messages, 'first_name', message);
    }
  },
  lastName: function(messages, value, message = '姓が入力されていません。') {
    if (!isRequired(value)) {
      addMessage(messages, 'last_name', message);
    }
  },
  firstNameRuby: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'first_name_ruby', '名（カナ）が入力されていません。');
    }
    if (!isRuby(value)) {
      addMessage(messages, 'first_name_ruby', '名（カナ）はカタカナで入力して下さい。');
    }
  },
  lastNameRuby: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'last_name_ruby', '姓（カナ）が入力されていません。');
    }
    if (!isRuby(value)) {
      addMessage(messages, 'last_name_ruby', '姓（カナ）はカタカナで入力して下さい。');
    }
  },
  nameRuby: function(messages, ...values) {
    const MAX_LENGTH = 39;
    let len = 0;
    values.forEach(val => {
      if (val) len += val.length;
    });
    if (len > MAX_LENGTH) {
      addMessage(messages, 'name_ruby', '姓名（カナ）は39文字以下で入力してください。');
    }
  },
  displayName: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'display_name', 'お名前が入力されていません。');
    }
  },
  mailAddress: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'mail_address', 'メールアドレスを入力してください。');
    } else if (value.length > 255) {
      addMessage(messages, 'mail_address', 'メールアドレスの文字数が多すぎます。');
    } else if (!isMailAddress(value)) {
      addMessage(messages, 'mail_address', 'メールアドレスを正しく入力してください。');
    } else if (includeCapitalLetter(value)) {
      addMessage(messages, 'mail_address', 'メールアドレスは全て小文字で入力してください。');
    }
  },
  mailAddressConfirmation: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'mail_address_confirmation', 'メールアドレス(確認用)を入力してください。');
    } else if (!isMailAddress(value)) {
      addMessage(messages, 'mail_address_confirmation', 'メールアドレス(確認用)を正しく入力してください。');
    }
  },
  mailAddressEqual: function(messages, value1, value2) {
    if (value1 != value2) {
      addMessage(messages, 'mail_address', 'メールアドレスとメールアドレス(確認用)が異なっています。');
      addMessage(messages, 'mail_address_confirmation', 'メールアドレスとメールアドレス(確認用)が異なっています。');
    }
  },
  mailAddressPinCode: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'pin_code', '認証コードが入力されていません。');
    } else if (value.toString().length != 4) {
      addMessage(messages, 'pin_code', '認証コードが4文字で入力されていません');
    }
  },
  password: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'password', 'パスワードを入力してください。');
    } else if (value.length < 8) {
      addMessage(
        messages,
        'password',
        'パスワードは、半角の英字､数字を1文字以上組み合わせた8文字以上で入力してください。',
      );
    } else if (!isPasswordFormat(value)) {
      addMessage(
        messages,
        'password',
        'パスワードは、半角の英字､数字を1文字以上組み合わせた8文字以上で入力してください。',
      );
    }
  },
  passwordConfirmation: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'password_confirmation', 'パスワード(確認)が入力されていません。');
      // } else if (value.length < 8) {
      //   addMessage(
      //     messages,
      //     'password_confirmation',
      //     'パスワード(確認)は、半角の英字､数字を1文字以上組み合わせた8文字以上で入力してください。',
      //   );
      // } else if (!isPasswordFormat(value)) {
      //   addMessage(
      //     messages,
      //     'password_confirmation',
      //     'パスワード(確認)は、半角の英字､数字を1文字以上組み合わせた8文字以上で入力してください。',
      //   );
    }
  },
  passwordEqual: function(messages, value1, value2) {
    if (value1 != value2) {
      addMessage(messages, 'password', 'パスワードとパスワード（確認用）が異なっています');
      addMessage(messages, 'password_confirmation', 'パスワードとパスワード（確認用）が異なっています');
    }
  },
  passwordMailAdrress: function(messages, password, mailAddress) {
    if (password && mailAddress && !!~password.indexOf(mailAddress)) {
      addMessage(messages, 'password', 'パスワードは、WEBアカウントが含まれていない8文字以上で入力してください。');
    }
  },
  currentPassword: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'current_password', '現在のパスワードが入力されていません。');
    }
  },
  telephone: function(messages, tel1, tel2, tel3) {
    if (!isRequired(tel1)) {
      addMessage(messages, 'telephone', '電話番号を入力してください。');
    } else if (!isRequired(tel2)) {
      addMessage(messages, 'telephone', '電話番号を入力してください。');
    } else if (!isRequired(tel3)) {
      addMessage(messages, 'telephone', '電話番号を入力してください。');
    } else if (!isTel1(tel1)) {
      addMessage(messages, 'telephone', '電話番号を正しく入力してください。');
    } else if (!isTel2(tel2)) {
      addMessage(messages, 'telephone', '電話番号を正しく入力してください。');
    } else if (!isTel3(tel3)) {
      addMessage(messages, 'telephone', '電話番号を正しく入力してください。');
    } else if (!isTel(tel1, tel2, tel3)) {
      addMessage(messages, 'telephone', '電話番号を正しく入力してください。');
    }
  },
  sex: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'sex', '性別を指定してください。');
    } else if (!_.includes(['man', 'woman', 'unknown'], value)) {
      addMessage(messages, 'sex', '性別が入力されていません。');
    }
  },
  agreeFlag: function(messages, value) {
    if (!value) {
      addMessage(messages, 'agreed_contract_version', '規約への同意が必要となります。');
    }
  },
  agreeCreditFlag: function(messages, value) {
    if (!value) {
      addMessage(messages, 'agreed_credit', '規約への同意が必要となります。');
    }
  },
  notesAgreementFlag: function(messages, value) {
    if (!value) {
      addMessage(messages, 'notes_agreement_flag', '上記内容への同意が必要となります。');
    }
  },
  birthday: function(messages, y, m, d) {
    if (!y) {
      addMessage(messages, 'birthday', '生年月日を選択してください。');
    } else if (!m) {
      addMessage(messages, 'birthday', '生年月日を選択してください。');
    } else if (!d) {
      addMessage(messages, 'birthday', '生年月日を選択してください。');
    } else {
      this.birthdayDate(messages, y, m, d);
    }
  },
  birthdayDate: function(messages, y, m, d) {
    if (!isDate(y, m, d)) {
      addMessage(messages, 'birthday', '生年月日を正しく選択してください。');
    }
  },
  defaultAvatarUrl: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'default_avatar_url', 'アイコンが入力されていません。');
    }
  },
  passcode: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'passcode', '暗証番号が入力されていません');
    } else if (!isNumeric(value)) {
      addMessage(messages, 'passcode', '暗証番号が数字で入力されていません');
    } else if (value.toString().length != 4) {
      addMessage(messages, 'passcode', '暗証番号が4文字で入力されていません');
    }
  },
  pinCode: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'pinCode', 'コードが入力されていません');
    } else if (value.toString().length != 5) {
      addMessage(messages, 'pinCode', 'コードが5文字で入力されていません');
    }
  },
  cardNumber: function(messages, value) {
    if (!isRequired(value)) {
      addMessage(messages, 'card_number', 'クレジットカードの下4桁が入力されていません');
    } else if (!isNumeric(value)) {
      addMessage(messages, 'card_number', '数字で入力されていません');
    } else if (value.toString().length != 4) {
      addMessage(messages, 'card_number', '4桁で入力されていません');
    }
  },
  /**
   * クレジットカード番号（全桁）のバリデーションを行います。
   * @param messages {object} メッセージ情報
   * @param value {string} 入力値
   * @param isUnset {boolean} true メッセージをリセットする, false メッセージをリセットしない
   */
  creaditCardFullNumber: (messages, value, isUnset = true) => {
    const KEY_NAME = 'cardNumber'; // キー名
    // メッセージが設定されている場合は削除する
    if (isUnset && _.has(messages, KEY_NAME)) {
      _.unset(messages, KEY_NAME);
    }
    // バリデーションを実行する
    if (!isRequired(value)) {
      // 未入力
      addMessage(messages, KEY_NAME, 'カード番号が入力されていません。');
    } else if (!isNumeric(value)) {
      // 数値で入力されていない
      addMessage(messages, KEY_NAME, '数字で入力されていません。');
    } else if (
      value.toString().length < CREDIT_CARD_NUMBER_LENGTH_MIN ||
      value.toString().length > CREDIT_CARD_NUMBER_LENGTH_MAX
    ) {
      // 入力桁数が不正（最小値より小さい、最大値より大きい）
      addMessage(
        messages,
        KEY_NAME,
        `${CREDIT_CARD_NUMBER_LENGTH_MIN}〜${CREDIT_CARD_NUMBER_LENGTH_MAX}桁で入力されていません。`,
      );
    }
  },
  /**
   * クレジットカード有効期限のバリデーションを行います。
   * @param messages {object} メッセージ情報
   * @param value {string} 入力値
   * @param isUnset {boolean} true メッセージをリセットする, false メッセージをリセットしない
   */
  creditCardLimit: (messages, value, isUnset = true) => {
    const DATE_FORMAT = 'YYYYMM'; // 日付フォーマット
    const KEY_NAME = 'cardLimit'; // キー名
    // メッセージが設定されている場合は削除する
    if (isUnset && _.has(messages, KEY_NAME)) {
      _.unset(messages, KEY_NAME);
    }
    // バリデーションを実行する
    // @ts-ignore TS2345
    if (!datetime(value, DATE_FORMAT, true).isValid()) {
      // 入力値が不正
      addMessage(messages, KEY_NAME, '有効期限の値が不正です。');
    }
  },
  /**
   * セキュリティーコードのバリデーションを行います。
   * @param messages {object} メッセージ情報
   * @param value {string} 入力値
   * @param isUnset {boolean} true メッセージをリセットする, false メッセージをリセットしない
   */
  creditCardSecurityCode: (messages, value, isUnset = true) => {
    const KEY_NAME = 'securityCode'; // キー名
    // メッセージが設定されている場合は削除する
    if (isUnset && _.has(messages, KEY_NAME)) {
      _.unset(messages, KEY_NAME);
    }
    if (!isRequired(value)) {
      // 未入力
      addMessage(messages, KEY_NAME, 'セキュリティコードが入力されていません。');
    } else if (!isNumeric(value)) {
      // 数値で入力されていない
      addMessage(messages, KEY_NAME, '数字で入力されていません。');
    } else if (
      value.toString().length < CREDIT_CARD_SECURITY_CODE_LENGTH_MIN ||
      value.toString().length > CREDIT_CARD_SECURITY_CODE_LENGTH_MAX
    ) {
      // 入力桁数が不正（最小値より小さい、最大値より大きい）
      addMessage(
        messages,
        KEY_NAME,
        `${CREDIT_CARD_SECURITY_CODE_LENGTH_MIN}〜${CREDIT_CARD_SECURITY_CODE_LENGTH_MAX}桁で入力されていません。`,
      );
    }
  },
  membershipAgreement(messages, value) {
    if (!value) {
      addMessage(messages, 'membershipAgreement', '「WOWOW WEB会員規約」に同意してください。');
    }
  },
  privacyPolicyAgreement(messages, value) {
    if (!value) {
      addMessage(messages, 'privacyPolicyAgreement', '「個人情報規約」に同意してください。');
    }
  },
  question(messages, row?: QuestionSchema, value?: any) {
    const questionType = row?.questionType;
    if (questionType === QUESTION_TYPE.HIDDEN) {
      return questionHidden(messages, row, value);
    } else if (questionType === QUESTION_TYPE.CHECK_BOX) {
      return questionCheckBox(messages, row, value);
    } else if (questionType === QUESTION_TYPE.SELECT_BOX) {
      return questionSelectBox(messages, row, value);
    } else if (questionType === QUESTION_TYPE.TEXT) {
      return questionTextarea(messages, row, value);
    } else if (questionType === QUESTION_TYPE.INTEGER) {
      return questionInteger(messages, row, value);
    } else if (questionType === QUESTION_TYPE.STRING) {
      return questionString(messages, row, value);
    }
  },

  isRequired: isRequired,
  isRuby: isRuby,
  isMailAddress: isMailAddress,
  isDate: isDate,
  isNumeric: isNumeric,
  isAlphaNumeric: isAlphaNumeric,
  is2BiteChar: is2BiteChar,
  isPasswordFormat: isPasswordFormat,
  questionCheckBox: questionCheckBox,
  questionSelectBox: questionSelectBox,
  questionTextarea: questionTextarea,
  questionString: questionString,
  questionInteger: questionInteger,

  addMessage: addMessage,
};
