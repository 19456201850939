import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import url from 'url';
import classnames from 'classnames';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HtmlContext from '../../../common/context/HtmlContext';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import panelHOC from '../../../common/hocs/panelHOC';
import BackgroundImage from '../../../common/components/BackgroundImage';
// import notificationDataStore from '../../../../utils/notificationDataStore';

import { CUSTOM_EVENTS } from '../../../../common/GtmApp';
import JsonLd from '../../../common/components/JsonLd';

class InfoList extends React.Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    return [
      rootPath.concat([{ from: 0, to: 49 }, ['id', 'title', 'thumbnailUrl', 'postAt']]),
      rootPath.concat(['additional']),
    ];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['link_sets', props.id];
  };

  static arrangeData = function(data) {
    const additional = data.additional;
    data = _.clone(
      _.values(
        _.omitBy(data, (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id') || key === 'additional';
        }),
      ),
    );
    _.forEach(data, (link, i) => {
      Object.assign(link, additional[i]);
    });
    return data;
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = props.model;
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const infoItems = this.model.getSync(rootPath);
    if (infoItems) {
      // @ts-ignore TS2339
      this.infoItems = this.constructor.arrangeData(infoItems);
    }
    this.state = {};
    this.handleClickItem = this.handleClickItem.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    this.fetchData(this.props);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // @ts-ignore TS2339
    if (this.props.id != nextProps.id) {
      this.fetchData(nextProps);
    }
    return true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        const infoItems = _.get(res.json, rootPath);
        if (infoItems) {
          // @ts-ignore TS2339
          this.infoItems = this.constructor.arrangeData(infoItems);
        }
        const newState = {
          dispose: null,
          fetchDataError: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          dispose: null,
          fetchDataError: e,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  handleClickItem(e) {
    let id = e;

    if (id) {
      // this.updateNotificationDataStore();
      // @ts-ignore TS2554
      let to = routes.infoDetail.makePath({ id: id });
      // @ts-expect-error TS2339
      this.context.history.push(to);
    }
    this.sendToGtm();
  }

  handleLinkClick() {
    // this.updateNotificationDataStore();
    this.sendToGtm();
  }

  // updateNotificationDataStore() {
  //   // 最新の日時、ない場合は現在日時を抽出
  //   const newDate = _.max(_.map(this.infoItems, link => {
  //     return link.validityStartAtTime;
  //   })) || new Date().getTime();
  //   if (this.isLatestInfoUnread(newDate)) {
  //     const profileData = _.get(this.context.getModelData('memberContext'), 'profileData');
  //     const profileId = _.get(profileData, 'active.id');
  //     notificationDataStore.set(newDate, profileId);
  //   }
  // }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnCustomClick(CUSTOM_EVENTS.NOTIFICATION_DETAIL_CLICK);
  }

  // isLatestInfoUnread(date) {
  //   // マウント前はfalse
  //   if (!this._isMounted) return false;
  //   const profileData = _.get(this.context.getModelData('memberContext'), 'profileData');
  //   const profileId = _.get(profileData, 'active.id');
  //   return notificationDataStore.isNew(date, profileId);
  // }

  render() {
    // @ts-ignore TS2339
    if (this.infoItems == undefined) {
      return null;
    }

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    // @ts-ignore TS2339
    if (this.infoItems && this.infoItems.length <= 0) {
      return (
        <div className="infoList-noResult" key={'noResult'}>
          <div className="no_img" />
          <div className="gallery-message">お知らせはありません。</div>
        </div>
      );
    }

    if (browserInfo.isIOS || browserInfo.isAndroid) {
      return (
        <div className="infoList-box">
          {/*
           // @ts-ignore TS2339 */}
          {_.map(this.infoItems, item => {
            return (
              // @ts-expect-error TS2322
              <MainViewLink
                // @ts-ignore TS2322
                to={routes.infoDetail}
                params={{ id: item.id }}
                className="infoList-box__item clearfix mb"
                key={`info-item-${item.id}`}
                onClick={this.handleLinkClick}
              >
                <div className="infoList-box__item-thumb">
                  {item.thumbnailUrl ? (
                    // @ts-ignore TS2322
                    <BackgroundImage className="artwork" url={item.thumbnailUrl} />
                  ) : (
                    // @ts-ignore TS2322
                    <BackgroundImage className="artwork" url={'/images/no_img.jpg'} />
                  )}
                </div>
                <div className="infoList-box__item-title">{item.title}</div>
                <div className="infoList-box__item-date">{item.postAt}</div>
              </MainViewLink>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="infoList-box">
          {/*
           // @ts-ignore TS2339 */}
          {_.map(this.infoItems, item => {
            return (
              <div className="infoList-box__item clearfix" key={`info-item-${item.id}`}>
                <div className="infoList-box__item-thumb" onClick={() => this.handleClickItem(item.id)}>
                  {item.thumbnailUrl ? (
                    // @ts-ignore TS2322
                    <BackgroundImage className="artwork" url={item.thumbnailUrl} />
                  ) : (
                    // @ts-ignore TS2322
                    <BackgroundImage className="artwork" url={'/images/no_img.jpg'} />
                  )}
                </div>
                {/*
                 // @ts-expect-error TS2322 */}
                <MainViewLink
                  // @ts-ignore TS2322
                  to={routes.infoDetail}
                  params={{ id: item.id }}
                  className="infoList-box__item-title"
                  onClick={this.handleLinkClick}
                >
                  {item.title}
                </MainViewLink>
                <div className="infoList-box__item-date">{item.postAt}</div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

class Info extends React.Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    return [rootPath.concat([{ from: 0, to: 49 }, ['id', 'title', 'url']])];
  };

  static getRootPath = function(models, options, props = {}) {
    const key = _.get(models, 'config.data.link_set_keys.info.index', {});
    return ['link_sets', key];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.sendToGtm = this.sendToGtm.bind(this);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const infoIndex = this.model.getSync(rootPath);
    if (infoIndex) {
      // @ts-ignore TS2339
      this.infoIndex = _.values(
        _.omitBy(infoIndex, (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
        }),
      );
    }
    this.state = {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.sendToGtm();

    // @ts-ignore TS2339
    let current = _.first(this.infoIndex);
    // @ts-expect-error TS2339
    let selected = _.get(this.context.routeHandler, 'hash');
    if (selected) {
      // @ts-ignore TS2339
      current = _.find(this.infoIndex, tab => {
        return _.endsWith(tab.url, selected);
      });
    }
    this.setState({ current });
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pageView('お知らせ');
  }

  render() {
    // @ts-ignore TS2339
    const key = this.state.current ? _.get(url.parse(this.state.current.url), 'hash').slice(1) : '';
    // @ts-expect-error TS2339
    const host = this.context.getModelData('hosts', 'host');
    // @ts-ignore TS2554
    const _url = routes.info.makePath();
    const jsonLdProps = {
      breadcrumbList: {
        itemListElement: [{ name: 'お知らせ', item: host + _url }],
      },
    };
    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ keywords }) => (
            <HelmetProvider>
              <Helmet title={'お知らせ'} />
            </HelmetProvider>
          )}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        <div className="infoList-wrapper">
          <h2>お知らせ</h2>
          <div className="gallery-tab-wrapper">
            <ul className="gallery-tab-navi">
              {/*
               // @ts-ignore TS2339 */}
              {_.map(this.infoIndex, tab => {
                return (
                  <li
                    key={`info-tab-${tab.id}`}
                    // @ts-ignore TS2339
                    className={classnames({ active: this.state.current && tab.id === this.state.current.id })}
                    // @ts-ignore TS2339
                    onClick={this.handleClick}
                  >
                    {/*
                     // @ts-ignore TS2322 */}
                    <MainViewLink href={tab.url} className="active">
                      {tab.title}
                    </MainViewLink>
                  </li>
                );
              })}
            </ul>
          </div>
          {/*
           // @ts-ignore TS2322 */}
          {key && <InfoList id={`info_${key}`} model={this.model} />}
        </div>
      </React.Fragment>
    );
  }
}
export default panelHOC(Info);
