import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

type P = {
  handleClickCreditAgreeFlag?: () => void;
  [key: string]: any;
};

// CheckboxAreaをシンプルにしたComponent
export default class CheckboxDiv extends Component<P> {
  checkboxRef: any;

  static get propTypes() {
    return {
      defaultChecked: PropTypes.bool,
      id: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      required: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      defaultChecked: false,
      id: null,
      name: null,
      value: '1',
      disabled: false,
      required: false,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.checkboxRef = React.createRef();
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(e) {
    // aタグを反応させる為target.hrefがあればスルーする
    if (e.target.href) {
      return;
    }

    e.preventDefault();

    // @ts-ignore TS2339
    if (this.props.disabled || this.props.readOnly) {
      return;
    }

    // @ts-ignore TS2339
    if (this.checkboxRef.current) {
      // @ts-ignore TS2339
      this.checkboxRef.current.checked = !this.checkboxRef.current.checked;
    }

    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
    }

    if (this.props.handleClickCreditAgreeFlag) {
      // カード規約のチェックボックスが変更されたことを親コンポーネントに通知
      this.props.handleClickCreditAgreeFlag();
    }
  }

  getChecked() {
    // @ts-ignore TS2339
    return _.get(this.checkboxRef, 'current.checked');
  }

  setChecked(flag) {
    // @ts-ignore TS2339
    if (this.checkboxRef.current) {
      // @ts-ignore TS2339
      this.checkboxRef.current.checked = flag;
    }
  }

  render() {
    let classNames = 'checkbox-cont';
    // @ts-ignore TS2339
    if (this.props.disabled) {
      classNames = classNames + ' disabled';
    }
    return (
      <div className={classNames} onClick={this._handleClick}>
        <input
          // @ts-ignore TS2339
          ref={this.checkboxRef}
          // @ts-ignore TS2339
          id={this.props.id}
          // @ts-ignore TS2339
          name={this.props.name}
          // @ts-ignore TS2339
          readOnly={this.props.readOnly}
          type="checkbox"
          // @ts-ignore TS2339
          defaultChecked={this.props.defaultChecked}
          // @ts-ignore TS2339
          value={this.props.value}
          // @ts-ignore TS2339
          required={this.props.required}
          className="check-box-label"
        />
        <label className="check-box-label">{this.props.children}</label>
      </div>
    );
  }
}
