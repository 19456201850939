import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import shallowEqualFuzzy from 'shallow-equal-fuzzy';

import BackgroundImage from '../../../common/components/BackgroundImage';

import getColumnsInRowInPlayer from '../../../common/utils/getColumnsInRowInPlayer';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import Row from '../browse/Row';

class MultiView extends React.Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.id) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    return [path.concat([['id', 'name']])];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['meta', props.id, 'medias'];
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      columnsInRow: PropTypes.number,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.contentRef = React.createRef();
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    // @ts-ignore TS2339
    this.medias = props.model.getSync(rootPath);
    let index = 0;
    // @ts-ignore TS2339
    if (this.medias) {
      // @ts-ignore TS2339
      index = !!props.mediaId ? _.findIndex(this.medias, media => media.mediaId == props.mediaId) : 0;
      if (index === -1) index = 0;
    }

    this.state = {
      focused: false,
      index,
      dispose: null,
      generation: props.model.getVersion(rootPath),
      fetchDataError: null,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
      this.checkMediaQuery();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { focused: nextProps.focused };
  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log(!shallowEqualFuzzy(this.props, nextProps), !shallowEqualFuzzy(this.state, nextState));
    if (!shallowEqualFuzzy(this.props, nextProps) || !shallowEqualFuzzy(this.state, nextState)) {
      return true;
    }
    return false;
  }

  componentDidUpdate(beforeProps, beforeState) {
    // @ts-ignore TS2339
    if (this.props.commentActive != beforeProps.commentActive) {
      this.checkMediaQuery();
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
  }

  checkMediaQuery() {
    // @ts-ignore TS2339
    this.setState({ columnsInRow: getColumnsInRowInPlayer(this.props.commentActive) });
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, null, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.medias = _.get(res.json, rootPath, []) || [];
        // @ts-ignore TS2339
        let index = !!props.mediaId ? _.findIndex(this.medias, media => media.mediaId == props.mediaId) : 0;
        if (index === -1) index = 0;
        const newState = {
          index,
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState);
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }

  render() {
    // @ts-ignore TS2339
    if (!this.medias || this.medias.length < 2) return null;

    // @ts-ignore TS2339
    let multiCards = this.medias.map(media => {
      return (
        <MultiCard
          key={`media-card-${media.mediaId}`}
          media={media}
          // @ts-ignore TS2339
          mediaId={this.props.mediaId}
          // @ts-ignore TS2339
          onClick={this.props.onClick}
        />
      );
    });

    // @ts-ignore TS2339
    let initialSlide = Math.floor(this.state.index / this.state.columnsInRow) * this.state.columnsInRow;
    // @ts-ignore TS2339
    const rightNum = initialSlide + this.state.columnsInRow - multiCards.length;
    if (rightNum > 0 && initialSlide - rightNum >= 0) {
      initialSlide = initialSlide - rightNum;
    }

    return (
      // @ts-ignore TS2339
      <div ref={this.contentRef} className="player-multi-view" style={{ zIndex: 10 }}>
        <Row
          {...this.props}
          // @ts-ignore TS2322
          columnsInRow={this.state.columnsInRow}
          // @ts-ignore TS2339
          totalItems={this.medias.length}
          initialSlide={initialSlide}
          enablePeek={true}
          enablePaginationIndicator={false}
          enablePushOut={false}
          enableLooping={false}
          isOnPlayer={true}
        >
          {multiCards}
        </Row>
      </div>
    );
  }
}

const MultiCard = (props, context) => {
  return (
    <div
      data-media-id={props.media.mediaId}
      key={`media-${props.media.mediaId}`}
      className={classnames('multiCard', { current: props.mediaId == props.media.mediaId })}
      onClick={e => props.onClick(props.media.mediaId)}
    >
      <div className="artwork-wrapper">
        {/*
         // @ts-ignore TS2322 */}
        <BackgroundImage className="artwork" url={props.media.thumbnailUrl} />
      </div>
      <span className="name">
        {props.mediaId == props.media.mediaId ? <i className="fa-camera"></i> : null}
        {props.media.name}
      </span>
    </div>
  );
};

export default withLayoutContext(MultiView);
