import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import routes from '../../../common/routes';
import classnames from 'classnames';
import url from 'url';

import { withLayoutContext } from '../../../common/context/LayoutContext';
import { NotfoundError } from '../../../../constants/error';
import { NotFoundError as NFErr } from '../../../common/components/ErrorBoundary';
import HtmlContext from '../../../common/context/HtmlContext';
// import RealtimeList from './RealtimeList';
import EpgEvent from './Epg/EpgEvent';
// import RealtimeInfo from './Epg/RealtimeInfo';
import BackgroundImage from '../../../common/components/BackgroundImage';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import Tags from './Epg/Tags';
import Meta from './Meta';
import SimulcastEventInfo from './Epg/SimulcastEventInfo';
import MyListButton from './MyListButton';
import ShareButton from './ShareButton';
import EventRow from './Epg/EventRow';
import MainViewLink from '../../../common/components/MainViewLink';
import activeProfile, { showNotTrial, canPlayBroadcast } from '../../../../utils/activeProfile';
import ScheduleButton from './ScheduleButton';
import JsonLd from '../../../common/components/JsonLd';
import SetDate from './SetDate';
import { LINKS } from 'src/constants/links';

class SimulcastDetail extends React.Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      history: PropTypes.object,
    };
  }

  static getPaths = function(models, options, props = {}) {
    let paths = [['linearChannel', { from: 0, to: 5 }, ['id', 'name', 'refId']]];
    // @ts-ignore TS2339
    return paths.concat(EpgEvent.getPaths(models, options, _.assign({}, props, { id: props.uniqueId })));
  };

  static getRootPath = function(models, options, props = {}) {
    return ['linearChannel'];
  };

  static getEventRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return EpgEvent.getRootPath(models, options, _.assign({}, props, { id: props.uniqueId }));
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function(models, options, props) {
    return data => {
      const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
      if (_.isEmpty(item)) {
        // @ts-ignore TS2554
        return { error: new NotfoundError() };
      }
      const event = _.omit(_.get(data.json, this.getEventRootPath(models, options, props)), ['$__path']);
      if (_.isEmpty(event)) {
        // @ts-ignore TS2554
        return { error: new NotfoundError() };
      }
      // @ts-ignore TS2554
      const eventData = EpgEvent.additionalData(event);
      const simulcast = _.find(models.simulcast.data, item => item.name == props.channelName);
      if (_.isEmpty(simulcast) || simulcast.refId !== eventData.linearChannelMeta.refId) {
        // @ts-ignore TS2554
        return { error: new NotfoundError() };
      }
      // 放送期間中
      if (eventData.isOnAir) {
        // オンデマンドで配信対象の場合
        if (!eventData.isMask) {
          // Watchにリダイレクトする
          // @ts-ignore TS2554
          return { redirect: routes.simulcast.makePath({ channelName: simulcast.name }) };

          // オンデマンドで配信対象外で、かつキャッチアップがある場合
        } else if (eventData.catchupMeta) {
          // 対象のキャッチアップメタにリダイレクトする
          let to;
          if (eventData.catchupMeta.type === 'media') {
            // @ts-ignore TS2554
            to = routes.watchNow.makePath({ id: eventData.catchupMeta.metaId });
            if (eventData.catchupMeta.refId) {
              // @ts-ignore TS2554
              to = routes.content.makePath({ id: eventData.catchupMeta.refId });
            }
          } else {
            // @ts-ignore TS2554
            to = routes.title.makePath({ id: eventData.catchupMeta.metaId });
            if (eventData.catchupMeta.refId) {
              // @ts-ignore TS2554
              to = routes.program.makePath({ id: eventData.catchupMeta.refId });
            }
          }
          return { redirect: to };
        }
      }
    };
  };

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const items = this.model.getSync(rootPath);
    const simulcast = _.find(context.getModelData('simulcast'), item => item.name == props.channelName);
    // @ts-ignore TS2339
    const eventRootPath = this.constructor.getEventRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.event = this.model.getSync(eventRootPath);
    // @ts-ignore TS2339
    this.event = EpgEvent.additionalData(this.event);

    // @ts-ignore TS2339
    if (_.isEmpty(simulcast) || simulcast.refId !== _.get(this.event, 'linearChannelMeta.refId')) {
      // @ts-ignore TS2554
      throw new NFErr();
    }
    // @ts-ignore TS2339
    this.linearChannel = _.find(items, item => item.refId == simulcast.refId);
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });

    this.state = {
      // @ts-ignore TS2339
      generation: this.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this.state.watchSpMode = false;

    // 放送期間中
    // @ts-ignore TS2339
    if (context.history && this.event.isOnAir) {
      // オンデマンドで配信対象の場合
      // @ts-ignore TS2339
      if (!this.event.isMask) {
        // Watchにリダイレクトする
        // @ts-ignore TS2554
        context.history.replace(routes.simulcast.makePath({ channelName: simulcast.name }));

        // オンデマンドで配信対象外で、かつキャッチアップがある場合
        // @ts-ignore TS2339
      } else if (this.event.catchupMeta) {
        // 対象のキャッチアップメタにリダイレクトする
        let to;
        // @ts-ignore TS2339
        if (this.event.catchupMeta.type === 'media_meta') {
          // @ts-ignore TS2554
          to = routes.watchNow.makePath({ id: this.event.catchupMeta.metaId });
          // @ts-ignore TS2339
          if (this.event.catchupMeta.refId) {
            // @ts-ignore TS2554
            to = routes.content.makePath({ id: this.event.catchupMeta.refId });
          }
        } else {
          // @ts-ignore TS2554
          to = routes.title.makePath({ id: this.event.catchupMeta.metaId });
          // @ts-ignore TS2339
          if (this.event.catchupMeta.refId) {
            // @ts-ignore TS2554
            to = routes.program.makePath({ id: this.event.catchupMeta.refId });
          }
        }
        context.history.replace(to);
      }
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // if (!this.linearChannel || !this.event) {
    //   this.fetchData(this.props, this.context);
    // } else {
    //   this.overLookingFetch(this.props, this.context);
    // }
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
    this.checkMediaQuery();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // if (this.state.dispose) {
    //   this.state.dispose();
    // }
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
  }

  checkMediaQuery() {
    let mediaQueryCheck = 1;
    let watchSpMode = false;
    if (window.matchMedia('screen and (min-width: 1024px)').matches) {
      mediaQueryCheck = 1;
    } else if (window.matchMedia('screen and (min-width: 768px) and (max-width: 1023px)').matches) {
      mediaQueryCheck = 2;
    } else if (window.matchMedia('screen and (max-width: 767px)').matches) {
      mediaQueryCheck = 2;
    }
    // @ts-ignore TS2339
    if (mediaQueryCheck !== this.state.mediaQueryCheck) {
      this.setState({ mediaQueryCheck });
    }

    if (window.matchMedia('screen and (max-width: 1023px)').matches) {
      watchSpMode = true;
    } else {
      watchSpMode = false;
    }
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ watchSpMode });
    // @ts-ignore TS2339
    else this.state.watchSpMode = watchSpMode;
  }

  fetchData(props, context) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const eventRootPath = this.constructor.getEventRootPath(context.models, {}, props);
    const evaluator = props.pathEvaluator.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        const canvas = _.values(_.omit(_.get(res.json, rootPath), '$__path', 'length'));
        // @ts-ignore TS2339
        this.linearChannel = this.constructor.findLinearChannel(canvas, props.id);

        // チャンネルの見逃し
        // this.overLookingFetch(props, context);

        // @ts-ignore TS2339
        this.event = _.get(res.json, eventRootPath);
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.pathEvaluator.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
          fetching: false,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  // overLookingFetch(props, context) {
  //   if (!this.linearChannel.channelMeta) return;

  //   const paths = [["overlooking", this.linearChannel.channelMeta.metaId, "length"]];
  //   const evaluator = props.pathEvaluator.fetch(paths);
  //   evaluator.then((res) => {
  //     if (_.get(res.json, paths[0], 0) > 0) {
  //       this.setState({enableOverLooking: true});
  //     } else {
  //       this.setState({enableOverLooking: false});
  //     }
  //   }).catch((e) => {
  //   });
  // }

  render() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models) || {};
    let channelName;
    const simulcast = _.find(
      // @ts-expect-error TS2339
      this.context.getModelData('simulcast'),
      // @ts-ignore TS2339
      item => item.refId == this.event.linearChannelMeta.refId,
    );
    if (simulcast) channelName = _.lowerCase(simulcast.name);

    let myListButton, scheduleButton;
    // @ts-ignore TS2339
    if (this.event) {
      const myListProps = {
        // @ts-ignore TS2339
        model: this.model,
        btnType: 'short',
        btnStyle: false,
      };
      // if (!this.meta.leadSeasonId) {
      // @ts-ignore TS2339
      myListProps.item = this.event;
      // } else {
      //   myListProps.id = this.meta.leadSeasonId;
      // }
      myListButton = <MyListButton {...myListProps} />;
    }

    scheduleButton = (
      <a href="/schedule" target="_blank" className="action-btn schedule-btn">
        <i className="fa fa-schedule"></i>
        <span>番組表</span>
      </a>
    );

    const inquiryTo = LINKS.WIP.FORGOT_EMAIL;

    let catchupTo = {};
    // @ts-ignore TS2339
    if (this.event.catchupMeta) {
      // @ts-ignore TS2339
      if (this.event.catchupMeta.type === 'media_meta') {
        catchupTo = {
          to: routes.watchNow,
          // @ts-ignore TS2339
          params: { id: this.event.catchupMeta.metaId },
        };
        // @ts-ignore TS2339
        if (this.event.catchupMeta.refId) {
          catchupTo = {
            to: routes.content,
            // @ts-ignore TS2339
            params: { id: this.event.catchupMeta.refId },
          };
        }
      } else {
        catchupTo = {
          to: routes.title,
          // @ts-ignore TS2339
          params: { id: this.event.catchupMeta.metaId },
        };
        // @ts-ignore TS2339
        if (this.event.catchupMeta.refId) {
          catchupTo = {
            to: routes.program,
            // @ts-ignore TS2339
            params: { id: this.event.catchupMeta.refId },
          };
        }
      }
    }

    // 構造化タグ
    const jsonLdProps = {};
    // @ts-expect-error TS2339
    const host = this.context.getModelData('hosts', 'host');
    const itemListElement = [];

    // ジャンル
    // @ts-ignore TS2339
    const genre = _.first(this.event.genres);
    // @ts-ignore TS2339
    if (genre && genre.id && genre.name) {
      // @ts-ignore TS2554
      let url = routes.genre.makePath({ id: genre.id }, { type: 'ev' });
      // @ts-ignore TS2339
      if (genre.refId) url = routes.genreRef.makePath({ id: genre.refId }, { type: 'ev' });
      // @ts-ignore TS2339
      itemListElement.push({ name: genre.name, item: host + url });
    }

    // チャンネル
    // @ts-ignore TS2339
    if (simulcast && this.event.linearChannelMeta.name) {
      // @ts-ignore TS2554
      const url = routes.simulcast.makePath({ channelName: simulcast.name });
      // @ts-ignore TS2339
      const name = this.event.linearChannelMeta.name;
      itemListElement.push({ name: name, item: host + url });
    }

    // 番組
    // @ts-ignore TS2339
    if (simulcast && this.event.uniqueId && this.event.name) {
      // @ts-ignore TS2554
      const url = routes.simulcastDetail.makePath({ channelName: simulcast.name, uniqueId: this.event.uniqueId });
      // @ts-ignore TS2339
      const name = this.event.name;
      itemListElement.push({ name: name, item: host + url });
    }

    if (!_.isEmpty(itemListElement)) {
      // @ts-ignore TS2339
      jsonLdProps.breadcrumbList = { itemListElement };
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ title }) => {
            const metas = [];
            // @ts-ignore TS2339
            if (this.event.name) {
              // @ts-ignore TS2339
              const description = this.event.description || '';
              // @ts-ignore TS2339
              metas.push({ property: 'og:title', content: title(this.event.name) });
              metas.push({ name: 'description', content: description });
              metas.push({ property: 'og:description', content: description });
            }
            // @ts-ignore TS2339
            if (this.event.ogImage) {
              // @ts-ignore TS2339
              metas.push({ name: 'thumbnail', content: this.event.ogImage });
              // @ts-ignore TS2339
              metas.push({ property: 'og:image', content: this.event.ogImage });
            }
            return (
              <HelmetProvider>
                {/* @ts-ignore TS2339 */}
                <Helmet title={this.event.name} meta={metas} />
              </HelmetProvider>
            );
          }}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        <div className="watch realtime-detail">
          <div className="watch-content">
            <div className="watch-main-visual">
              {/*
               // @ts-ignore TS2339 */}
              {this.state.mediaQueryCheck == 1 ||
              // @ts-ignore TS2339
              (this.event.isMask && !this.event.isSuspend) ||
              // @ts-ignore TS2339
              (!this.event.isOnAir && !!this.event.isCatchup) ? (
                <div className="watch-main-visual__metadata">
                  <div className={classnames('logo', channelName)}></div>
                  <div className="playable-title__date">
                    {/*
                     // @ts-ignore TS2339 */}
                    {this.event && this.event.startAt && this.event.endAt ? (
                      <React.Fragment>
                        {/*
                         // @ts-ignore TS2322 */}
                        <SetDate date={this.event.startAt} format={'short'} /> -{' '}
                        {/*
                         // @ts-ignore TS2322 */}
                        <SetDate date={this.event.endAt} format={'time'} />
                      </React.Fragment>
                    ) : // @ts-ignore TS2339
                    this.event && this.event.startAt && !this.event.endAt ? (
                      // @ts-ignore TS2322
                      <SetDate date={this.event.endAt} format={'short'} />
                    ) : null}
                    {/*
                     // @ts-ignore TS2339 */}
                    {this.event && this.event.isMask && (
                      <div className="tag-list">
                        <span key="isMask" className="tag grey-tag">
                          同時配信 対象外
                        </span>
                      </div>
                    )}
                  </div>
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.event.name ? <h1 className="playable-title">{this.event.name}</h1> : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  <Meta metadata={this.event} />
                  {/*
                   // @ts-ignore TS2339 */}
                  <Tags {...this.event} />
                  {/* @ts-ignore */}
                  {!this.state.watchSpMode ? (
                    <div className="action-box">
                      <ul className="action-box__inner">
                        <li key={`scheduleButton`}>{scheduleButton}</li>
                        <li key={`shareButton`}>
                          {/* @ts-ignore */}
                          <ShareButton spMode={this.props.spMode} metadata={this.event} position="left" />
                        </li>
                      </ul>
                    </div>
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.event.isMask && !this.event.isSuspend ? (
                    <div className="watch-main-visual__metadata__message">
                      <div className="watch-main-visual__metadata__message-title">
                        この番組は放送のみでお楽しみいただけます。
                      </div>
                      <div className="watch-main-visual__metadata__message-text">
                        詳しくはお客さまサポート・よくあるご質問をご確認ください。
                      </div>
                      {browserInfo.isAndroid || browserInfo.isIOS ? (
                        <a
                          href="https://support.wowow.co.jp/s/category?prmcat=category3"
                          target="_blank"
                          className="watch-main-visual__metadata__message-notice btn"
                        >
                          詳しくはこちら
                        </a>
                      ) : (
                        <div className="watch-main-visual__metadata__message-notice">
                          詳しくは
                          <a
                            href="https://support.wowow.co.jp/s/category?prmcat=category3"
                            target="_blank"
                            className="accent-color"
                          >
                            こちら
                          </a>
                        </div>
                      )}
                      {/*
                       // @ts-ignore TS2339 */}
                      {!!this.event.isCatchup && !_.isEmpty(catchupTo) ? (
                        // @ts-ignore TS2322
                        <MainViewLink {...catchupTo} className={'btn btn-fill btn-large'}>
                          オンデマンドで再生する
                        </MainViewLink>
                      ) : null}
                    </div>
                  ) : // @ts-ignore TS2339
                  !this.event.isOnAir && !!this.event.isCatchup && userInfo.status !== 'NON_REGISTERED_MEMBER' ? (
                    //ログイン済みのキャッチアップ
                    <React.Fragment>
                      {!_.isEmpty(catchupTo) ? (
                        <div className="watch-main-visual__metadata__message">
                          {/*
                           // @ts-ignore TS2322 */}
                          <MainViewLink {...catchupTo} className={'btn btn-fill btn-large'}>
                            オンデマンドで再生する
                          </MainViewLink>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : // @ts-ignore TS2339
                  !this.event.isOnAir && !!this.event.isCatchup ? (
                    //未ログインのキャッチアップ
                    <div className="watch-main-visual__metadata__message">
                      {/*
                       // @ts-ignore TS2339 */}
                      {this.event.permitUserStatuses.length == 1 &&
                      // @ts-ignore TS2339
                      this.event.permitUserStatuses[0] == 'broadcast' &&
                      !canPlayBroadcast(profile) ? (
                        //放送視聴登録者 限定
                        <div className="watch-main-visual__metadata__message-title">
                          WOWOWオンデマンドなら、BCAS登録済のWEBアカウントでログインすることで時間を選ばずいつでもお楽しみいただけます。
                        </div>
                      ) : // @ts-ignore TS2339
                      this.event.permitUserStatuses.length == 2 &&
                        // @ts-ignore TS2339
                        _.includes(this.event.permitUserStatuses, 'stream') &&
                        // @ts-ignore TS2339
                        _.includes(this.event.permitUserStatuses, 'broadcast') &&
                        // @ts-expect-error TS2339
                        showNotTrial(profile, this.context.models) ? (
                        //無料トライアル 対象外
                        <div className="watch-main-visual__metadata__message-title">
                          WOWOWオンデマンドなら、WOWOWに有料契約済みのWEBアカウントでログインすることで時間を選ばずいつでもお楽しみいただけます。
                        </div>
                      ) : // @ts-ignore TS2339
                      _.includes(this.event.permitUserStatuses, 'free') ||
                        // @ts-ignore TS2339
                        _.includes(this.event.permitUserStatuses, 'trial_used') ? (
                        //無料
                        <div className="watch-main-visual__metadata__message-title">
                          WOWOWオンデマンドなら、WOWOW
                          WEBアカウントでログインすることで時間を選ばずいつでもお楽しみいただけます。
                        </div>
                      ) : (
                        <div className="watch-main-visual__metadata__message-title">
                          WOWOWオンデマンドなら、WOWOWにご加入済みのWEBアカウントでログインすることで時間を選ばずいつでもお楽しみいただけます。
                        </div>
                      )}
                      {/*
                       // @ts-ignore TS2322 */}
                      <MainViewLink href={'/login'} className={'btn btn-fill btn-large sign-up'}>
                        ログインして視聴する
                      </MainViewLink>
                      {browserInfo.isAndroid || browserInfo.isIOS ? (
                        <a href={inquiryTo} target="_blank" className="watch-main-visual__metadata__message-notice btn">
                          WEBアカウントが不明な方はこちら
                        </a>
                      ) : (
                        <div className="watch-main-visual__metadata__message-notice">
                          WEBアカウントが不明な方は
                          <a href={inquiryTo} target="_blank" className="accent-color">
                            こちら
                          </a>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="main-visual">
                <div className="video-preload-title">
                  {/*
                   // @ts-ignore TS2339 */}
                  <div className="video-preload-title-label">{this.event.name}</div>
                </div>
                <BackgroundImage
                  // @ts-ignore TS2322
                  className={classnames('artwork', {
                    // @ts-ignore TS2339
                    noImage: !this.event.thumbnailUrl && !this.event.linearChannelMeta.thumbnailUrl,
                  })}
                  url={
                    // @ts-ignore TS2339
                    this.event.thumbnailUrl
                      ? // @ts-ignore TS2339
                        this.event.thumbnailUrl
                      : // @ts-ignore TS2339
                      this.event.linearChannelMeta.thumbnailUrl
                      ? // @ts-ignore TS2339
                        this.event.linearChannelMeta.thumbnailUrl
                      : null
                  }
                />
              </div>
            </div>
            <div className="below-player">
              <SimulcastEventInfo
                // @ts-ignore TS2339
                metadata={this.event}
                {...this.props}
                // @ts-ignore TS2339
                model={this.model}
                // @ts-ignore TS2322
                mediaQueryCheck={this.state.mediaQueryCheck}
                titleHidden={
                  // @ts-ignore TS2339
                  (this.event.isMask && !this.event.isSuspend) || (!this.event.isOnAir && !!this.event.isCatchup)
                    ? true
                    : false
                }
              />
              {/* @ts-ignore */}
              {this.state.watchSpMode ? (
                <div className="action-box">
                  <ul className="action-box__inner">
                    <li key={`scheduleButton`}>{scheduleButton}</li>
                    <li key={`shareButton`}>
                      {/* @ts-ignore */}
                      <ShareButton spMode={this.props.spMode} metadata={this.event} position="left" />
                    </li>
                  </ul>
                </div>
              ) : null}
              <div className={'series-area event-timeline'}>
                {/*
                // @ts-ignore TS2322 */}
                <EventRow listContext={'fromnow'} model={this.model} id={this.linearChannel.id} tagHidden={true} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withLayoutContext(SimulcastDetail);
