import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import PresTrackedElement from '../../../common/components/PresTrackedElement';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import Meta from './Meta';
import BackgroundImage from '../../../common/components/BackgroundImage';
import CardContext, { withCardContext } from '../../../common/context/CardContext';
import CardBadge from './CardBadge';
import { getIsDelivery } from '../../../common/utils/metaUtil';
import SetDate from './SetDate';

class EpisodeCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.episodeId) {
      // @ts-ignore TS2339
      path = path.concat(['meta', props.episodeId]);
    }
    let pathProps = [
      'id',
      'refId',
      'name',
      'schemaId',
      'attributes',
      'genres',
      'middleGenres',
      'shortName',
      'thumbnailUrl',
      'resumePoint',
      'cardInfo',
      'seasonMeta',
      'seriesMeta',
      'publishStartAt',
      'publishEndAt',
      'type',
      'leadSeasonId',
      'titleMetaId',
      'tvodBadge',
      'rental',
      'subscription',
    ];

    // @ts-ignore TS2339
    if (props.rental) {
      pathProps = pathProps.concat(['ppvLimit', 'isExercised']);
    }
    return [path.concat([pathProps])];
  };

  static get propTypes() {
    return {
      sliderItemId: PropTypes.string,
      showEpisodeDuration: PropTypes.bool,
      showEpisodeNumber: PropTypes.bool,
      showEpisodeSummary: PropTypes.bool,
      rankNum: PropTypes.number,
      itemTabbable: PropTypes.bool,
      current: PropTypes.bool,
      enableCardClick: PropTypes.bool,
      model: PropTypes.object,
      episodeId: PropTypes.number.isRequired,
      beginning: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      showEpisodeDuration: true,
      showEpisodeNumber: true,
      showEpisodeSummary: true,
      beginning: false,
    };
  }

  static contextType = CardContext;

  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.handleClickPlayButton = this.handleClickPlayButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClickPlayButton(e);
    }
  }

  handleClickPlayButton(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-expect-error TS2339
    const { routeHandler, history } = this.context;
    const query = {};
    // 最初から
    // @ts-ignore TS2339
    if (this.props.beginning) {
      // @ts-ignore TS2339
      query.t = 0;
    }
    // @ts-ignore TS2339
    if (this.props.onChangeEpisode) {
      // @ts-ignore TS2339
      this.props.onChangeEpisode(this.item);
    } else {
      if (routeHandler.path.indexOf('/content') === 0) {
        // @ts-ignore TS2554
        const to = routes.content.makePath({ id: this.item.refId }, query);
        history.replace(to);
      } else if (routeHandler.path.indexOf('/watch') === 0) {
        // @ts-ignore TS2554
        const to = routes.watchNow.makePath({ id: this.props.episodeId }, query);
        history.replace(to);
        // @ts-ignore TS2339
      } else if (this.item.refId) {
        // @ts-ignore TS2554
        const to = routes.content.makePath({ id: this.item.refId }, query);
        history.push(to);
      } else {
        // @ts-ignore TS2554
        const to = routes.watchNow.makePath({ id: this.props.episodeId }, query);
        history.push(to);
      }
    }
    // @ts-ignore TS2339
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) return null;

    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');

    let summary;
    // @ts-ignore TS2339
    if (this.props.showEpisodeSummary === true && this.item.cardInfo.description) {
      // @ts-ignore TS2339
      summary = <div className="synopsis">{this.item.cardInfo.description}</div>;
    }
    let duration;
    // @ts-ignore TS2339
    if (this.props.showEpisodeDuration === true) {
      // @ts-ignore TS2339
      if (this.item.cardInfo.episodeRuntime) {
        // @ts-ignore TS2339
        let runtime = parseInt(this.item.cardInfo.episodeRuntime, 10);
        let seconds = runtime % 60;
        let minutes = ((runtime - seconds) / 60) % 60;
        // @ts-ignore TS2345
        let hour = parseInt(runtime / 3600, 10);
        let durationText;
        if (hour > 0) {
          durationText = `${hour}時間${minutes}分`;
        } else if (minutes > 0) {
          durationText = `${minutes}分`;
        } else {
          durationText = `${seconds}秒`;
        }
        // @ts-ignore TS2339
        if (this.item.cardInfo.episodeRuntime < 60) {
          duration = <span className="duration">{durationText}</span>;
        } else {
          duration = <span className="duration">{durationText}</span>;
        }
      }
    }

    const resumeKey = 'resumePoint';
    let showProgress =
      // @ts-ignore TS2339
      _.has(this.item, resumeKey) && userInfo.status !== 'NON_REGISTERED_MEMBER' && _.get(this.item, 'schemaId') != 4;
    let progress = 0;
    // @ts-ignore TS2339
    if (!this.props.beginning && this.item.cardInfo.episodeRuntime && this.item[resumeKey]) {
      // @ts-ignore TS2339
      if (!!this.item.cardInfo.episodeRuntime && !!this.item[resumeKey]) {
        // @ts-ignore TS2345
        progress = Math.min(parseInt((this.item[resumeKey] / this.item.cardInfo.episodeRuntime) * 100), 100);
      }
    }

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    let cardBadge = (
      <CardBadge
        // @ts-ignore TS2339
        item={this.item}
        // @ts-ignore TS2339
        mbListLayout={this.props.mbListLayout}
        // @ts-ignore TS2339
        keyPrefix={this.props.keyPrefix}
        // @ts-ignore TS2339
        listContext={this.props.listContext}
        // @ts-ignore TS2339
        listType={this.props.listType}
        // @ts-ignore TS2339
        isOnArt={!this.props.listType && !(browserInfo.isIOS || browserInfo.isAndroid)}
      />
    );

    const Title = props => {
      // @ts-expect-error TS2339
      if (this.context.pageSchemaId === 1) {
        return <h3 className={props.classname}>{props.children}</h3>;
      // @ts-expect-error TS2339
      } else if (_.includes([2, 3], this.context.pageSchemaId)) {
        return <h2 className={props.classname}>{props.children}</h2>;
      } else {
        return <div className={props.classname}>{props.children}</div>;
      }
    };

    let cardContentsTitles = (
      <React.Fragment>
        {/*
         // @ts-ignore TS2339 */}
        {this.props.listType || browserInfo.isIOS || browserInfo.isAndroid ? cardBadge : null}
        <Title {...this.props} classname="title">
          {/*
           // @ts-ignore TS2339 */}
          {this.props.episodeId ? (
            <p
              className="link"
              onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClickPlayButton : null}
            >
              {/*
               // @ts-expect-error TS2339 */}
              {this.context.showSeasonTitle &&
              // @ts-expect-error TS2339
              this.context.allSeasonCount >= 1 &&
              // @ts-ignore TS2339
              this.item.seasonMeta &&
              // @ts-ignore TS2339
              this.item.seasonMeta.name ? (
                // @ts-ignore TS2339
                <span className="season-title ellipsized">{this.item.seasonMeta.name}</span>
              ) : null}
              {/*
               // @ts-ignore TS2339 */}
              {this.item.shortName}
            </p>
          ) : (
            <p>
              {/*
               // @ts-expect-error TS2339 */}
              {this.context.showSeasonTitle &&
              // @ts-expect-error TS2339
              this.context.allSeasonCount >= 1 &&
              // @ts-ignore TS2339
              this.item.seasonMeta &&
              // @ts-ignore TS2339
              this.item.seasonMeta.name ? (
                // @ts-ignore TS2339
                <span className="season-title ellipsized">{this.item.seasonMeta.name}</span>
              ) : null}
              {/*
               // @ts-ignore TS2339 */}
              {this.item.shortName}
            </p>
          )}
        </Title>
        {/*
         // @ts-ignore TS2339 */}
        <Meta metadata={this.item} hiddenYear={true} hiddenGenre={true} />
      </React.Fragment>
    );
    // @ts-ignore TS2339
    if (this.props.listType || browserInfo.isIOS || browserInfo.isAndroid) {
      cardContentsTitles = <div className="list-type-titles-box">{cardContentsTitles}</div>;
      cardContentsTitles = cardContentsTitles;
    }
    let ppvLimitEl = null;
    // @ts-ignore TS2339
    if (this.item.ppvLimit) {
      ppvLimitEl = (
        // @ts-ignore TS2339
        <div className={classnames('viewLimit', { viewableExercisedAt: !this.item.isExercised })}>
          {/*// @ts-ignore TS2339 */}
          <SetDate format="short" date={this.item.ppvLimit} prefix="PPV期限 " txt="まで" />
        </div>
      );
    }

    let cardContent = (
      <React.Fragment>
        <div className="sliderRefocus lockup">
          {/*
           // @ts-ignore TS2322 */}
          <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
            {/*
             // @ts-expect-error TS2322 */}
            <BackgroundImage
              // @ts-ignore TS2322
              onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClickPlayButton : null}
              className={'artwork'}
              // @ts-ignore TS2339
              url={this.item.thumbnailUrl}
            >
              {/*
               // @ts-ignore TS2339 */}
              {!this.props.listType && !(browserInfo.isIOS || browserInfo.isAndroid) ? cardBadge : null}
              {// 配信期間中のもののみ再生アイコンを表示する
              // @ts-ignore TS2339
              !getIsDelivery(this.item.cardInfo.deliveryStartAt, this.item.cardInfo.deliveryEndAt) ? null : (
                <div className="play-link"></div>
              )}
            </BackgroundImage>
            {showProgress && (
              <div className="progress">
                <span className="progress-bar">
                  <span role="presentation" className="progress-completed" style={{ width: `${progress}%` }}></span>
                </span>
              </div>
            )}
          </PresTrackedElement>
        </div>
        {cardContentsTitles}
        {ppvLimitEl}

        {summary}
      </React.Fragment>
    );

    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // @ts-ignore TS2339
      let linkProps = { to: routes.watchNow, params: { id: this.props.episodeId } };
      // @ts-ignore TS2339
      if (_.get(this.item, 'refId')) {
        // @ts-ignore TS2339
        linkProps = { to: routes.content, params: { id: this.item.refId } };
      }
      return (
        // @ts-expect-error TS2322
        <MainViewLink
          {...linkProps}
          // @ts-ignore TS2322
          onClick={this.props.enableCardClick ? this.handleClickPlayButton : undefined}
          role="link"
          aria-label="playbutton"
          className={classnames('card', 'episode-card', {
            // @ts-ignore TS2339
            clickable: this.props.enableCardClick,
            nohover: browserInfo.isIOS || browserInfo.isAndroid,
            // @ts-ignore TS2339
            selected: this.props.selected,
          })}
        >
          {cardContent}
        </MainViewLink>
      );
    } else {
      return (
        <div
          className={classnames('card', 'episode-card', {
            // @ts-ignore TS2339
            current: this.props.episodeId === this.props.currentEpisodeId,
            // @ts-ignore TS2339
            clickable: this.props.enableCardClick,
            nohover: browserInfo.isIOS || browserInfo.isAndroid,
            // @ts-ignore TS2339
            selected: this.props.selected,
          })}
          // @ts-ignore TS2339
          id={this.props.episodeId}
          // @ts-ignore TS2322
          tabIndex={this.props.itemTabbable === true ? '0' : '-1'}
          // @ts-ignore TS2339
          onClick={this.props.enableCardClick ? this.handleClickPlayButton : undefined}
          onKeyDown={this.handleKeyDown.bind(this)}
        >
          {cardContent}
        </div>
      );
    }
  }

  async fetchData(props) {
    const pathProps = { ...props };

    const _fetch = async () => {
      // @ts-ignore TS2339
      if (!_.isEmpty(this.item)) {
        // @ts-ignore TS2339
        pathProps.rental = this.item.rental;
      }
      // @ts-ignore TS2339
      const paths = this.constructor.getPaths(this.context.models, null, pathProps);
      let newState;
      try {
        const evaluator = props.model.fetch(paths);
        // @ts-ignore TS2339
        this.state.dispose = evaluator.dispose;
        const res = await evaluator;
        // @ts-ignore TS2339
        this.item = _.get(res.json, ['meta', props.episodeId], {});
        newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
      } catch (e) {
        console.error(e.stack);
        newState = {
          fetchDataError: e,
          dispose: null,
        };
      }
      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState(newState);
      } else {
        Object.assign(this.state, newState);
      }
    };
    await _fetch();
    // tvodかつ、tvodかどうかが事前に取得できていない場合はもう一度fetchする
    // @ts-ignore TS2339
    if (this.item.rental && this.item.rental !== pathProps.rental) {
      await _fetch();
    }
  }
}

const root = withCardContext(EpisodeCard);
// @ts-ignore TS2339
root.getPaths = EpisodeCard.getPaths;
export default root;
