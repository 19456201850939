import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import assign from 'object-assign';
import _ from 'src/domain/libs/util';

import MainViewLink from '../../../../common/components/MainViewLink';
import RowHeader from '../RowHeader';
import Row from '../Row';
import EventCard from './EventCard';
import LoadingRow from '../../loader/LoadingRow';
import LoadingTitle from '../../loader/LoadingTitle';
import LoadingListTitle from '../../loader/LoadingListTitle';
import GalleryContent from '../GalleryContent';
import { CardContextProvider } from '../../../../common/context/CardContext';
import { CONTENT_EVENTS, CLICK_AREA } from '../../../../../common/GtmApp';

import * as browserEvents from '../../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../../sketch-platform/utils/DOMUtils';

class EventRow extends React.Component {
  static getPaths = function(models, options, props) {
    let paths = [];
    if (props.id) {
      // @ts-ignore TS2339
      paths = EventCard.getPaths().map(function(path) {
        return [
          props.listContext,
          props.id,
          {
            from: (props && props.fromNum) || 0,
            to: props && props.toNum ? props.toNum : (props && props.fromNum ? props.fromNum : 0) + props.perPage - 1,
          },
        ].concat(path);
      });
    }
    const rootPath = this.getRootPath(models, options, props);
    paths = paths.concat([[props.listContext, props.id, 'length']]);
    return paths.concat([rootPath.concat([['id', 'name', 'refId']])]);
  };

  static getRootPath = function(models, options, props) {
    if (props.id) {
      return ['meta', props.id];
    }
    return [];
  };

  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      perPage: PropTypes.number,
    };
  }

  static get defaultProps() {
    return {
      perPage: 60,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      popType: PropTypes.string,
      columnsInRow: PropTypes.number,
      gtmApp: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      listContext: PropTypes.string,
      columnsInRow: PropTypes.number,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.sendToGtm = this.sendToGtm.bind(this);
    // @ts-ignore TS2339
    this.eventRowRef = React.createRef();
    // @ts-ignore TS2339
    this.numTitlesToFetch = props.perPage;
    // @ts-ignore TS2339
    this.isFirstSetOfResults = true;
    const browserInfo = context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      this.onScroll = _.throttle(this.onScroll.bind(this), 100);
    }
    this.state = {
      dispose: null,
      fetchDataError: null,
    };

    // @ts-ignore TS2339
    this._isMounted = false;
  }

  getChildContext() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-expect-error TS2339
    const columnsInRow = browserInfo.isIOS || browserInfo.isAndroid ? 1 : this.context.columnsInRow;
    return {
      // @ts-ignore TS2339
      listContext: this.props.listContext,
      columnsInRow,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('scroll', this.onScroll);
    }
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      // @ts-ignore TS2339
      this.items = [];
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('scroll', this.onScroll);
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }
  handleItemClick(item) {
    this.sendToGtm(item);
  }

  sendToGtm(item) {
    if (!_.get(this.context, 'gtmApp')) return;
    const channel = _.get(item, 'linearChannelMeta');
    const content = {
      refId: _.get(item, 'uniqueId'),
      name: _.get(item, 'name'),
    };
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { content, channel, attributes, genres, middleGenres, schemaId },
      // @ts-expect-error TS2339
      { clickArea: this.context.listType ? CLICK_AREA.LIST : CLICK_AREA.SHELF },
    );
  }

  render() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    let episodes = null;
    // @ts-ignore TS2339
    if (!this.items || this.items.length === 0) {
      // @ts-ignore TS2339
      if (this.props.isGrid) return null;
      // @ts-ignore TS2339
      if (!this.state.dispose) {
        return null;
      }
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        let loadingListTitle = [];
        for (let i = 0; i < 4; i++) {
          loadingListTitle.push(
            <LoadingListTitle
              pulsate={true}
              displayWhenNotPulsing={false}
              lockupClassName={`card episode-card`}
              key={`loading-row-${i}`}
            />,
          );
        }
        episodes = loadingListTitle;
        return (
          <div className="series-area">
            <div className="episodes-container">
              <div className="episodes-wrapper">
                <div className="gallery">
                  <div className="gallery-content">{episodes}</div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <LoadingRow />;
      }
    }

    const titles = _.compact(
      // @ts-ignore TS2339
      this.items.map((item, index) => {
        if (!item.uniqueId) return;
        return (
          <EventCard
            // @ts-ignore TS2322
            popType={this.context.popType}
            // @ts-ignore TS2339
            model={this.props.model}
            // @ts-ignore TS2339
            key={`event_${item.uniqueId}_${this.props.rowNum}_${index}`}
            rankNum={index}
            // @ts-ignore TS2339
            ellipsized={this.props.listContext !== 'nowonair'}
            // @ts-ignore TS2339
            rowNum={this.props.rowNum}
            eventId={item.uniqueId}
            itemData={item}
            channelNameHidden={true}
            // @ts-ignore TS2339
            tagHidden={this.props.tagHidden}
            noisOnAirMode={true}
            listType={browserInfo.isIOS || browserInfo.isAndroid ? true : false}
          />
        );
      }),
    );

    const headerProps = {};
    // @ts-ignore TS2339
    if (this.meta.id) {
      // @ts-ignore TS2339
      headerProps.id = this.meta.id;
      // @ts-ignore TS2339
      headerProps.linearChannelMeta = this.meta;
      // @ts-ignore TS2339
      if (this.props.title) {
        // @ts-ignore TS2339
        headerProps.title = this.props.title;
      } else {
        // @ts-ignore TS2339
        headerProps.title = '配信予定';
      }
    }
    let row = null;

    if (browserInfo.isIOS || browserInfo.isAndroid) {
      row = (
        // @ts-expect-error TS2322
        <GalleryContent
          header={
            // @ts-ignore TS2339
            <div className={classnames('gallery-header', { small: this.props.titleSmallSize })}>
              <div className="title">
                {/*
                 // @ts-ignore TS2339 */}
                <span className="gallery-title">{headerProps.title}</span>
              </div>
            </div>
          }
          // @ts-ignore TS2322
          listType={true}
        >
          {titles}
        </GalleryContent>
      );
      return (
        // @ts-ignore TS2339
        <div ref={this.eventRowRef}>
          <CardContextProvider value={this.getChildContext()}>
            {/*
             // @ts-expect-error TS2322 */}
            <GalleryContent
              header={
                // @ts-ignore TS2339
                <div className={classnames('gallery-header', { small: this.props.titleSmallSize })}>
                  <div className="title">
                    <span className="gallery-title">
                      {/*
                       // @ts-ignore TS2339 */}
                      <MainViewLink {...headerProps}>{headerProps.title}</MainViewLink>
                    </span>
                  </div>
                </div>
              }
              // @ts-ignore TS2322
              listType={true}
              itemsInRow={1}
            >
              {titles}
            </GalleryContent>
            {/*
             // @ts-ignore TS2339 */}
            {this.state.fetchingMoreRows === true && (
              <div className="gallery-spin-loader">
                <div className="loader-cont"></div>
              </div>
            )}
          </CardContextProvider>
        </div>
      );
    } else {
      return (
        // @ts-ignore TS2339
        <div className={'canvas-row'} data-list-context={this.props.listContext}>
          <CardContextProvider value={this.getChildContext()}>
            <RowHeader {...headerProps} />
            <Row
              // @ts-ignore TS2339
              totalItems={this.items.length}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              handleSliderMove={this.handleSliderMove.bind(this)}
              // @ts-ignore TS2322
              handleItemClick={this.handleItemClick.bind(this)}
              // @ts-ignore TS2339
              listContext={this.props.listContext}
              loadingComponent={<LoadingTitle />}
              enableLooping={false}
              enablePaginationIndicator={false}
              enablePeek={true}
              enablePushOut={false}
            >
              {titles}
            </Row>
          </CardContextProvider>
        </div>
      );
    }
  }

  handleSliderMove(nextState, _) {
    if (
      // @ts-ignore TS2339
      this.numTitlesToFetch > this.items.length &&
      // @ts-ignore TS2339
      nextState.lowestVisibleItemIndex >= this.items.length - 10 &&
      // @ts-ignore TS2339
      !this.state.fetchingMoreRows
    ) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
    // @ts-ignore TS2339
    if (this.props.onSliderMove) {
      // @ts-ignore TS2339
      this.props.onSliderMove(nextState);
    }
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(
      // @ts-expect-error TS2339
      this.context.models,
      {},
      // @ts-ignore TS2339
      Object.assign({}, props, { toNum: this.numTitlesToFetch - 1 }),
    );

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const beforeItemLength = this.items ? this.items.length : 0;
    evaluator
      .then(res => {
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
          fetchingMoreRows: undefined,
        };
        if (res) {
          // @ts-ignore TS2339
          this.meta = _.get(res.json, rootPath);
          const dataPath = `json.${props.listContext}.${props.id}`;
          const data = _.get(res, dataPath);
          // @ts-ignore TS2339
          this.items = _.clone(_.values(_.omitBy(data, (val, key) => key.indexOf('$') === 0 || key === 'length')));
          if (data.hasOwnProperty('length')) {
            // @ts-ignore TS2339
            this.totalCount = data.length;
          }
        } else {
          // @ts-ignore TS2339
          this.totalCount = this.items.length;
        }
        // @ts-ignore TS2339
        this.numTitlesToFetch += props.perPage;
        // @ts-ignore TS2339
        if (this.numTitlesToFetch > this.totalCount) this.numTitlesToFetch = this.totalCount;
        // @ts-ignore TS2339
        this.isFirstSetOfResults = false;
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState, () => {
            // @ts-ignore TS2339
            if (beforeItemLength >= this.items.length) this.numTitlesToFetch = this.items.length;
          });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }

  onScroll(e) {
    if (this.isWithinDistanceBuffer(300)) {
      this.fetchMoreRows();
    }
  }

  isWithinDistanceBuffer(distanceBuffer) {
    // @ts-ignore TS2339
    if (!this.eventRowRef) return;
    // @ts-ignore TS2339
    const el = this.eventRowRef.current;
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }

  fetchMoreRows() {
    // @ts-ignore TS2339
    if (this.items && this.numTitlesToFetch > this.items.length && !this.state.fetchingMoreRows) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
  }
}

export default EventRow;
