import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import PresTrackedElement from '../../../common/components/PresTrackedElement';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
import BackgroundImage from '../../../common/components/BackgroundImage';

export default class StudioCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let rootPath = [];
    // @ts-ignore TS2339
    if (props.studioId) {
      // @ts-ignore TS2339
      rootPath = rootPath.concat(['studio', props.studioId]);
    }
    const paths = [rootPath.concat([['id', 'name', 'logoUrl']])];
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.studioId) return;
    // @ts-ignore TS2339
    return ['studio', props.studioId];
  };

  static get propTypes() {
    return {
      sliderItemId: PropTypes.string,
      itemTabbable: PropTypes.bool,
      model: PropTypes.object.isRequired,
      rowModel: PropTypes.object,
      rankNum: PropTypes.number,
      rowNum: PropTypes.number,
      studioId: PropTypes.number.isRequired,
      popType: PropTypes.string,
      popClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    };
  }

  static get defaultProps() {
    return {
      rankNum: 0,
      rowNum: 0,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      history: PropTypes.object,
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      listContext: PropTypes.string,
      listType: PropTypes.string,
      routeHandler: PropTypes.object,
      rowNum: PropTypes.number,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (props.itemData) {
      // @ts-ignore TS2339
      this.item = props.itemData;
    } else if (rootPath) {
      // @ts-ignore TS2339
      this.item = props.model.getSync(rootPath);
    }
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (!this.props.itemData) this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.mouseOutTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOutTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
    }
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) {
      return null;
    }

    const childFactory = child => {
      return React.cloneElement(child, {});
    };

    // @ts-ignore TS2339
    const key = `title-card-${this.props.rowNum}-${this.props.rankNum}`;
    // @ts-ignore TS2339
    const tabIndex = this.props.itemTabbable ? 0 : -1;

    // @ts-ignore TS2322
    const titleArtwork = <BackgroundImage className={classnames('artwork', {})} url={this.item.logoUrl} />;

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    return (
      <div className="card title-card">
        <div
          className={classnames({
            lockup: true,
            sliderRefocus: !(browserInfo.isIOS || browserInfo.isAndroid),
          })}
          // @ts-ignore TS2339
          aria-label={this.item.name}
          tabIndex={tabIndex}
          role={'link'}
          key={key}
          id={key}
          onClick={this.handleClick.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        >
          <PresTrackedElement>{titleArtwork}</PresTrackedElement>
        </div>
      </div>
    );
  }

  handleClick(e) {
    e.preventDefault();
    // @ts-expect-error TS2339
    const { history } = this.context;
    // @ts-ignore TS2339
    history.push(routes.studio.makePath({ id: this.props.studioId }));
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, rootPath, {});
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }
}
