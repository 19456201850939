import React, { Component, createRef, StrictMode } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import PageTransitionProgressBar from '../../common/components/PageTransitionProgressBar';
import GlobalMenu from '../../common/components/GlobalMenu';
import Footer from '../../common/components/Footer';
import Pagetop from '../../common/components/Pagetop';
import SpNavFooter from '../../common/components/SpNavFooter';
import ProfilesGate from '../../common/components/ProfilesGate';
import ErrorBoundary from '../../common/components/ErrorBoundary';

import FreezeWrapper from './FreezeWrapper';

import LayoutContext from '../../common/context/LayoutContext';
import ModalContext from '../../common/context/ModalContext';
import { CardContextProvider } from '../../common/context/CardContext';
import ModalContent from '../../common/components/ModalContent';

import htmlContext from '../../common/hocs/htmlContext';

import * as browserEvents from '../../../sketch-platform/utils/browserEvents';
import * as COOKIE from '../../../constants/cookie';
import vuidDataStore from '../../../utils/vuidDataStore';
import DrawerContent from '../../common/components/DrawerContent';
import DrawerContext from '../../common/context/DrawerContext';
import getColumnsInRow from '../../common/utils/getColumnsInRow';
import getOrientation from '../../common/utils/getOrientation';
import DeepLinkLauncher from '../components/deepLink/DeepLinkLauncher';
import routes from 'src/apps/common/routes';

class FreezeOnChangePageKey extends React.PureComponent {
  static get contextTypes() {
    return {
      pageKey: PropTypes.string.isRequired,
    };
  }

  render() {
    // @ts-ignore TS2339
    return this.props.children;
  }
}

type BlueroseLayoutState = {
  columnsInRow?: number;
  isRental: boolean;
  isHideSpNavFooter: boolean;
  spMode: boolean;
  isSidemenuOpened?: boolean;
  onetimeTokenData: string | null;
  orientation?: string;
  locationKey?: string;
  modalComponents?: JSX.Element[];
  drawerComponent?: JSX.Element | null;
};
class BlueroseLayout extends Component<any, BlueroseLayoutState> {

  static get childContextTypes() {
    return {
      pageKey: PropTypes.string,
      columnsInRow: PropTypes.number,
      routeHandlers: PropTypes.array,
      orientation: PropTypes.string,
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      cookies: PropTypes.object,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
      authApp: PropTypes.object,
      isIframe: PropTypes.bool,
      appsFlyerApp: PropTypes.object,
    };
  }

  static getPrefetchPaths = function(models, options, props) {
    return GlobalMenu.getPaths(models, options, props);
  };

  private _locationKey: string;
  private _isMounted: boolean;
  private layoutRef: React.RefObject<HTMLDivElement>;
  mainViewRef: React.RefObject<HTMLDivElement>;
  headerRef: React.RefObject<HTMLElement>;
  footerRef: React.RefObject<HTMLElement>;
  pagetopRef: React.RefObject<HTMLDivElement>;

  constructor(props, context) {
    super(props, context);
    this.state = {
      columnsInRow: 4,
      isRental: false,
      isHideSpNavFooter: false,
      spMode: false,
      onetimeTokenData: context.getModelData('onetimeTokenData'),
      // default openなので
      isSidemenuOpened: GlobalMenu.getMenuType(context.models, {}, props) === 'sidemenu',
    };
    // @ts-ignore TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isAndroid || browserInfo.isIOS) {
      // スマホの初期値は2で決め打つ
      if (browserInfo.isSmartPhone) {
        // @ts-ignore TS2339
        this.state.columnsInRow = 2;
        // @ts-ignore TS2339
        this.state.spMode = true;
        // タブレットの初期値は3で決め打つ
      } else {
        // @ts-ignore TS2339
        this.state.columnsInRow = 3;
      }
    }

    this._locationKey = _.get(this.context, 'history.location.key');

    this.layoutRef = React.createRef();
    // @ts-ignore TS2339
    this.spFooterRef = React.createRef();
    this.footerRef = React.createRef();
    this.pagetopRef = React.createRef();
    this.headerRef = React.createRef();

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollStart = this.handleScrollStart.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);
    this.renderLoginErrorModal = this.renderLoginErrorModal.bind(this);
    this.afterDeepLinkLaunch = this.afterDeepLinkLaunch.bind(this);
    this.hideSignupFooter = this.hideSignupFooter.bind(this);
    this.onChangeSideMenuOpened = this.onChangeSideMenuOpened.bind(this);
  }

  getChildContext() {
    // @ts-ignore TS2339
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    return {
      pageKey: routeHandler ? routeHandler.path : '',
      columnsInRow: this.state.columnsInRow,
      routeHandlers: this.props.routeHandlers,
      orientation: this.state.orientation,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.checkMediaQuery();

    // @ts-expect-error TS2339
    if (this.context.appsFlyerApp && !this.context.appsFlyerApp.initialized) {
      // @ts-expect-error TS2339
      this.context.appsFlyerApp.init();
    }
    // ログイン後のセットアップ
    // @ts-expect-error TS2339
    if (this.context.cookies.get(COOKIE.LOGINED_KEY)) {
      // @ts-expect-error TS2339
      this.context.appsFlyerApp.sendEventOnLogin();
      // @ts-expect-error TS2339
      this.context.cookies.remove(COOKIE.LOGINED_KEY, { path: '/' });
    }

    // wtokenが付与されている場合、画面表示した時に消す
    // @ts-expect-error TS2339
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    if (routeHandler.query.wtoken) {
      const query = _.omit(routeHandler.query, 'wtoken');
      // @ts-expect-error TS2339
      this.context.history.replace(routeHandler.route.makePath(routeHandler.params, query), { norender: true });
    }

    // @ts-expect-error TS2339
    const loginErrorModalData = this.context.getModelData('loginErrorModalData');
    if (loginErrorModalData) {
      this.renderLoginErrorModal(loginErrorModalData);
      // あまり良くないので消し方なので考える
      // @ts-expect-error TS2339
      delete this.context.models.loginErrorModalData;
    }

    // serverで生成したvuidを保存
    vuidDataStore.init();

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
      browserEvents.addEventListener('scroll', this.handleScroll);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
      browserEvents.addEventListener('scrollStart', this.handleScrollStart);
      browserEvents.addEventListener('scrollEnd', this.handleScrollEnd);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // 連続再生し、前のエピソードに戻り、連続再生すると遷移できない
    // 前のエピソードを再生させる時にrenderせずにurlだけ変えているので
    // 連続再生しようとした時に同じパス扱いになってしまう為
    if (nextContext.history.location.key !== this.state.locationKey) {
      this._locationKey = nextContext.history.location.key;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
      browserEvents.removeEventListener('scroll', this.handleScroll);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
      browserEvents.removeEventListener('scrollStart', this.handleScrollStart);
      browserEvents.removeEventListener('scrollEnd', this.handleScrollEnd);
    }
  }

  checkMediaQuery() {
    if (typeof window === 'undefined') return;

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    let orientation;
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      orientation = getOrientation();
    }
    let columnsInRow = getColumnsInRow();
    if ((browserInfo.isIOS || browserInfo.isAndroid) && orientation === 'horizontal') {
      columnsInRow = 3;
    }
    const spMode = columnsInRow === 2;

    if (spMode !== this.state.spMode) {
      this.setState({ spMode });
    }
    if (columnsInRow !== this.state.columnsInRow) {
      this.setState({ columnsInRow, orientation });
    }
  }

  handleScroll(e) {
    if (document && this.layoutRef.current) {
      let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
      if (scrollTop > 41) {
        this.layoutRef.current.classList.add('fixedSearch');
      } else {
        this.layoutRef.current.classList.remove('fixedSearch');
      }
    }
  }

  handleScrollStart() {
    if (document) {
      document.body.style.pointerEvents = 'none';
    }
  }

  handleScrollEnd() {
    if (document) {
      document.body.style.pointerEvents = '';
    }
  }

  renderLoginErrorModal(loginErrorModalData) {
    const onClickReagree = () => {
      const href = loginErrorModalData.href;
      if (href) {
        window.location.href = href;
      }
      this.closeModal();
    };
    // @ts-ignore TS2554
    this.showModal(
      <div>
        <div className="modal-text">
          <p className="text text-center">{loginErrorModalData.message}</p>
        </div>
        <div className="btn-block">
          <button className="btn btn-gray" onClick={this.closeModal}>
            閉じる
          </button>
          <button className="btn btn-fill" onClick={onClickReagree}>
            再同意する
          </button>
        </div>
      </div>,
    );
  }

  onChangeSideMenuOpened(opened: boolean) {
    this.setState({ isSidemenuOpened: opened });
  }

  renderGlobalMenu() {
    // @ts-expect-error TS2339
    if (this.context.isIframe) return null;
    // @ts-expect-error TS2339
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    const term = routeHandler.query.q || decodeURIComponent(routeHandler.params.term || '');
    const props = {
      fixed: true,
      enableIncrementalSearch: this.props.isIncrementalSearchPage,
      handleClickCallback: cb => {
        // @ts-ignore TS2339
        this._handleClickCallback = cb;
      },
      // @ts-ignore TS2339
      rootRef: this.headerRef,
    };
    if (term) {
      // @ts-ignore TS2339
      props.term = term;
    }
    // @ts-ignore TS2322
    return <GlobalMenu {...props} model={this.props.model} onChangeSideMenuOpened={this.onChangeSideMenuOpened} />;
  }

  showModal(component, props) {
    const modalComponents = this.state.modalComponents || [];
    modalComponents.push(
      <CardContextProvider value={Object.assign(this.context, this.getChildContext())}>
        <ModalContent key={`modal-content-${modalComponents.length}`} {...props} onClose={e => this.closeModal()}>
          {component}
        </ModalContent>
      </CardContextProvider>,
    );
    this.setState({
      modalComponents: modalComponents,
    });
    if (document) {
      document.body.style.overflowY = 'hidden';
    }
  }

  closeModal() {
    const modalComponents = this.state.modalComponents || [];
    if (modalComponents.length > 1) {
      this.setState({
        modalComponents: modalComponents.slice(0, modalComponents.length - 1),
      });
    } else {
      this.setState({
        modalComponents: [],
      });
    }
    if (document) {
      document.body.style.overflowY = '';
    }
  }

  openDrawer(component, props) {
    // @ts-ignore TS2339
    const behindElementRefs = [this.footerRef, ...props.behindElementRefs];
    this.setState({
      drawerComponent: (
        <DrawerContent
          {...props}
          onClose={e => this.closeDrawer()}
          behindElementRefs={behindElementRefs}
          // @ts-ignore TS2339
          spFooterRef={this.spFooterRef}
          // @ts-ignore TS2339
          pagetopRef={this.pagetopRef}
          // @ts-ignore TS2339
          headerRef={this.headerRef}
        >
          {component}
        </DrawerContent>
      ),
    });
  }

  closeDrawer() {
    this.setState({
      drawerComponent: null,
    });
    if (typeof document !== 'undefined') {
      if (document.body.classList.contains('is-drawer-open')) {
        document.body.classList.remove('is-drawer-open');
      }
    }
  }

  afterDeepLinkLaunch() {
    // @ts-expect-error TS2339
    this.context.models.onetimeTokenData = null;
    this.setState({ onetimeTokenData: null });
  }

  // 視聴ページ判定
  hideSignupFooter() {
    return (
      routes.watchNow.match(this.props.routeHandler.path) ||
      routes.content.match(this.props.routeHandler.path) ||
      routes.pv.match(this.props.routeHandler.path) ||
      routes.product.match(this.props.routeHandler.path)
    );
  }

  // 未加入ユーザー判定
  isNonRegisteredMember(userInfo) {
    return userInfo.status == 'NON_REGISTERED_MEMBER' || userInfo.userStatus == 0;
  }

  render() {
    // @ts-expect-error TS2339
    const inapp = this.context.getModelData('inapp', 'inapp');
    // @ts-expect-error TS2339
    const routeHandler = this.props.routeHandler || this.context.routeHandler;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');

    const layoutContextValue = {
      spMode: this.state.spMode,
    };

    const modalContextValue = {
      showModal: this.showModal,
      closeModal: this.closeModal,
    };

    const drawerContextValue = {
      openDrawer: this.openDrawer,
      closeDrawer: this.closeDrawer,
    };

    // @ts-expect-error TS2339
    const globalMenuType = GlobalMenu.getMenuType(this.context.models, {}, this.props);
    return (
      <div
        ref={this.layoutRef}
        className={classnames('bd', 'lang_ja', {
          inapp: inapp,
        })}
        lang="ja-JP"
        onClick={e => {
          // @ts-ignore TS2339
          if (this._handleClickCallback) this._handleClickCallback(e);
        }}
      >
        <LayoutContext.Provider value={layoutContextValue}>
          <ModalContext.Provider value={modalContextValue}>
            <DrawerContext.Provider value={drawerContextValue}>
              <FreezeWrapper>
                {/*
                 // @ts-expect-error TS2769 */}
                <FreezeOnChangePageKey key={routeHandler.path + '-' + this._locationKey}>
                  {/*
                       // @ts-ignore TS2322 */}
                  <PageTransitionProgressBar history={this.context.history} routeHandler={routeHandler} />
                </FreezeOnChangePageKey>
              </FreezeWrapper>
              {this.renderGlobalMenu()}
              <CardContextProvider value={Object.assign(this.context, this.getChildContext())}>
                <FreezeWrapper>
                  {/*
                   // @ts-expect-error TS2769 */}
                  <FreezeOnChangePageKey key={routeHandler.path + '-' + this._locationKey}>
                    <TransitionGroup
                      className={classnames('mainView', {
                        // @ts-expect-error TS2339
                        iframe: this.context.isIframe,
                        'with-sidemenu': globalMenuType === 'sidemenu',
                        'sidemenu-is-closed': !this.state.isSidemenuOpened,
                      })}
                    >
                      <div className="attention-area"></div>
                      {React.Children.map(this.props.children, child => {
                        if (routeHandler) {
                          return (
                            <CSSTransition classNames="pageTransition" timeout={{ exit: 200, enter: 500 }}>
                              <ErrorBoundary>
                                {React.cloneElement(
                                  // @ts-ignore TS2769
                                  child,
                                  Object.assign({ key: routeHandler.path }, routeHandler.params, {
                                    // @ts-ignore TS2769
                                    headerRef: this.headerRef,
                                  }),
                                )}
                              </ErrorBoundary>
                            </CSSTransition>
                          );
                        }
                        return child;
                      })}
                    </TransitionGroup>
                  </FreezeOnChangePageKey>
                </FreezeWrapper>
              </CardContextProvider>
              {/*
               // @ts-expect-error TS2339 */}
              {!inapp && !this.context.isIframe && !(this.hideSignupFooter() && this.isNonRegisteredMember(userInfo)) && (
                <SpNavFooter
                  // @ts-ignore TS2322
                  model={this.props.model}
                  currentPath={_.get(this.props, 'routeHandler.path')}
                  currentRouteHandler={_.get(this.props, 'routeHandler')}
                  isRental={this.state.isRental}
                  isHide={this.state.isHideSpNavFooter}
                  // @ts-ignore TS2339
                  rootRef={this.spFooterRef}
                />
              )}
              {/*
                   // @ts-ignore TS2339 */}
              {!inapp && !this.context.isIframe && this.state.onetimeTokenData ? (
                <DeepLinkLauncher token={this.state.onetimeTokenData} afterDeepLinkLaunch={this.afterDeepLinkLaunch} />
              ) : null}
              {/*
                   // @ts-ignore TS2339 */}
              {!inapp && !this.context.isIframe ? (
                <Footer
                  className={classnames({
                    'with-sidemenu': globalMenuType === 'sidemenu',
                    'sidemenu-is-closed': !this.state.isSidemenuOpened,
                  })}
                  rootRef={this.footerRef}
                />
              ) : null}
              {browserInfo.isIOS || browserInfo.isAndroid ? (
                // @ts-ignore TS2322
                <Pagetop rootRef={this.pagetopRef} spFooterRef={this.spFooterRef} />
              ) : null}
              {this.state.modalComponents}
              {this.state.drawerComponent}
            </DrawerContext.Provider>
          </ModalContext.Provider>
        </LayoutContext.Provider>
        <ProfilesGate />
      </div>
    );
  }
}

export default htmlContext(BlueroseLayout);
