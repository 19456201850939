import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import GalleryContent from './GalleryContent';
import MyListGalleryHeader from './MyListGallaryHeader';
import SortGallery from './SortGallery';

class OnRental extends React.Component {
  static getPrefetchPaths = function(models, options, props) {
    return GalleryContent.getPaths(
      models,
      options,
      Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'onrental' }),
    );
  };
  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }
  static childContextTypes = {
    popType: PropTypes.string,
  };
  constructor(props, context) {
    super(props, context);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.handleFetchedCount = this.handleFetchedCount.bind(this);
    // @ts-ignore TS2339
    this.model = props.pathEvaluator || props.model.pathEvaluator;
    this.state = {
      showSortMenu: false,
    };
  }
  getChildContext() {
    return {
      popType: 'none',
    };
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.sendToGtm();
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }
  handleFetchedCount(count) {
    this.setState({ showSortMenu: count > 0 });
  }
  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pageView('マイリスト');
  }
  render() {
    // @ts-expect-error TS2339
    const sortOrder = this.context.routeHandler.query['so'] || 'sd';
    // @ts-ignore TS2339
    const sortComponent = this.state.showSortMenu ? (
      <SortGallery
        // @ts-ignore TS2322
        sortOrder={sortOrder}
        sortOrders={[
          { so: 'sd', name: '購入順' },
          { so: 'ea', name: '視聴期限順' },
        ]}
        positionRight={true}
      />
    ) : null;
    return (
      <GalleryContent
        // @ts-ignore TS2322
        header={<MyListGalleryHeader sortComponent={sortComponent} />}
        // @ts-ignore TS2339
        model={this.model}
        keyPrefix={'onrental'}
        // @ts-ignore TS2339
        onFetchedCount={this.handleFetchedCount}
        sortOrder={sortOrder}
        // @ts-ignore TS2322
        listContext={'mylist'}
      />
    );
  }
}

export default OnRental;
