import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import * as COURSE from 'src/constants/course';
import { LINKS } from 'src/constants/links';
import MainViewLink from 'src/apps/common/components/MainViewLink';
import usePlaybackRestrictionType from 'src/apps/common/hooks/usePlaybackRestrictionType';
import activeProfile, { isFree } from 'src/utils/activeProfile';
import routes from 'src/apps/common/routes';
import GtmApp, { CLICK_AREA } from 'src/common/GtmApp';
import { Models } from 'src/types/app/models';
import AppContext from 'src/types/context/App';

type SubscriptionPlanContext = {
  gtmApp: GtmApp;
  models: Models;
  getModelData: AppContext.GetModelData;
};

const SubscriptionPlan = (props, context: SubscriptionPlanContext) => {
  const course =
    props.courses.find(course => course.id === COURSE.DEFAULT) ||
    props.courses.find(course => course.id === COURSE.STREAMING_DIRECT_WITH_CAS_PAY_AT_SUBSCRIBE);
  const playbackRestrictionType = usePlaybackRestrictionType({ meta: props.meta, event: props.event });

  const titleText = useMemo(() => {
    let _titleText: string | undefined;
    if (playbackRestrictionType == 100) {
      //BCAS未登録NG
      _titleText = '※この作品をお楽しみいただくには、WOWOWにB-CAS登録済のWEBアカウントが必要です。';
    } else if (playbackRestrictionType == 200) {
      //トライアルNG
      //無料トライアル2023年11月17日（金）に終了のため、文言表示させないようにしています
      // _titleText = '※この作品をお楽しみいただくには、WOWOWに有料契約済みのWEBアカウントが必要です。';
    }
    return _titleText;
  }, [playbackRestrictionType]);

  const onClickJoin = useCallback(() => {
    context.gtmApp.pushDataLayerOnSubscriptionClick(CLICK_AREA.SIGNUP.EPISODE);
  }, [context.gtmApp]);

  const browserInfo = context.getModelData('browserInfo');

  const profile = activeProfile(context.models);
  const isFreeProfile = profile && isFree(profile);
  let link = null;
  if (!profile || isFreeProfile) {
    let href = routes.login.makePath();
    let text = 'アカウントをお持ちの方は';
    let target;
    if (isFreeProfile) {
      href = LINKS.SUPPORT.REGISTER_SELF;
      text = 'ご契約済みの方は';
      target = '_blank';
    }
    if (browserInfo.isAndroid || browserInfo.isIOS) {
      link = (
        <div className="plan-info__item__li__sub">
          {/*
           // @ts-expect-error TS2322 */}
          <MainViewLink href={href} className={'plan-info__notice btn'} target={target}>
            {`${text}こちら`}
          </MainViewLink>
        </div>
      );
    } else {
      link = (
        <div className="plan-info__item__li__sub">
          <div className="plan-info__notice">
            ※{text}
            {/*
             // @ts-expect-error TS2322 */}
            <MainViewLink href={href} className={'accent-color'} target={target}>
              こちら
            </MainViewLink>
          </div>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <div className="plan-info__title">定額配信中の番組が見放題</div>
      <div className="plan-info__item svod">
        <div className="plan-info__item__li">
          <div className="plan-info__item__li__name">月額視聴料</div>
          <div className="plan-info__item__li__price">
            <div className="price">
              <span>{course.activePricing.displayPaymentAmountWithoutUnit}</span>円（税込み）
            </div>
          </div>
        </div>
        <div className="plan-info__item__li btn-box">
          {/*
           // @ts-expect-error TS2322 */}
          <MainViewLink href="/static/join" className="btn sign-up btn-wide btn-svod" onClick={onClickJoin}>
            ご加入はこちら
          </MainViewLink>
          {titleText && <div className="plan-info__item__li__sub">{titleText}</div>}
          {link}
        </div>
      </div>
    </React.Fragment>
  );
};

SubscriptionPlan.contextTypes = {
  getModelData: PropTypes.func,
  models: PropTypes.object,
  gtmApp: PropTypes.object,
};
export default React.memo(SubscriptionPlan);
