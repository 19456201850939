import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from './Button';

class CommentButton extends React.PureComponent {
  static get propTypes() {
    return {
      player: PropTypes.object.isRequired,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  onClick(e) {
    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
    }
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('comment');
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover('comment');
    }
  }

  handleKeyDown(e) {
    // @ts-ignore TS2339
    if (this.props.isHidden) {
      return;
    }

    // Space or Enter
    if (e.which === 32 || e.which === 13) {
      e.preventDefault();
      this.onClick(e);
    }
  }

  render() {
    // @ts-ignore TS2339
    const props = { ref: this.props.forwardedRef };
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      // @ts-ignore TS2339
      props.onMouseEnter = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onFocus = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onBlur = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onKeyDown = this.handleKeyDown;
    }
    return (
      <button
        {...props}
        type="button"
        className={classnames('touchable player-control-element player-button-control', {
          // @ts-ignore TS2339
          'fa-chat-off': !this.props.commentActive,
          // @ts-ignore TS2339
          'fa-chat': this.props.commentActive,
        })}
        // @ts-ignore TS2322
        tabIndex="0"
        role="button"
        aria-label="チャット"
        name="commentBtn"
        onClick={this.onClick}
      ></button>
    );
  }
}

// export default CommentButton;
export default React.forwardRef(function commentButton(props, ref) {
  // @ts-ignore TS2322
  return <CommentButton {...props} forwardedRef={ref} />;
});
