import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import { NotFoundError } from '../../../common/components/ErrorBoundary';

import Canvas from './Canvas';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HomeContent from './HomeContent';
import Curation from './Curation';
import JsonLd from '../../../common/components/JsonLd';
import routes from '../../../common/routes';

export default class CanvasDisplay extends Component {
  static getPaths = function(models, options, props) {
    const canvasId = this.getCanvasId(models, options, props);
    return Canvas.getPaths(models, options, {
      canvasId: canvasId,
    }).concat([['canvas', canvasId, ['idKey', 'canvasId']]]);
  };

  static getCanvasId = function(models, options = {}, props = {}) {
    // @ts-ignore TS2339
    return props.id;
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getRootPath = function(models, options, props) {
    return Canvas.getRootPath(models, options, {
      canvasId: this.getCanvasId(models, options, props),
    });
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.sendToGtm = this.sendToGtm.bind(this);

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    // @ts-ignore TS2339
    this.canvas = this.model.getSync(rootPath) || {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    if (!this.canvas || Object.keys(this.canvas).length === 0) {
      // componentWillMountでスローしたいが、SSRのタイミングでも呼ばれるので不可
      // https://reactjs.org/docs/error-boundaries.html
      // @ts-ignore TS2554
      throw new NotFoundError();
    }
    this.sendToGtm();
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-ignore TS2339
    const titleType = _.get(this.canvas, 'titleType');
    // @ts-ignore TS2339
    const canvasName = titleType == 'name' ? _.get(this.canvas, 'name') : _.get(this.canvas, 'shortName');
    // @ts-expect-error TS2339
    this.context.gtmApp.pageView(canvasName);
  }

  render() {
    // @ts-ignore TS2339
    const canvasId = this.constructor.getCanvasId(this.context.models, {}, this.props);
    if (!canvasId) return null;
    // @ts-ignore TS2339
    if (!this.canvas || Object.keys(this.canvas).length === 0) return null;
    const links = [];
    // ホーム・おすすめ
    // @ts-ignore TS2339
    const homeIdKey = HomeContent.getCanvasId(this.context.models, {}, this.props);
    // @ts-ignore TS2339
    const curationIdKey = Curation.getCanvasId(this.context.models, {}, this.props);

    let key = 'display';
    const params = {};
    // @ts-ignore TS2339
    switch (this.canvas.idKey) {
      case homeIdKey:
        key = 'home';
        break;
      case curationIdKey:
        key = 'curation';
        break;
      default: {
        const isUuid = function(key) {
          return key && new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/).test(key);
        };
        // @ts-ignore TS2339
        if (this.canvas.idKey && !isUuid(this.canvas.idKey)) {
          // @ts-ignore TS2339
          params.id = this.canvas.idKey;
          // @ts-ignore TS2339
        } else if (this.canvas.canvasId) {
          // @ts-ignore TS2339
          params.id = this.canvas.canvasId;
        } else {
          key = null;
        }
      }
    }
    if (key) {
      // @ts-expect-error TS2339
      const host = this.context.getModelData('hosts', 'host');
      links.push({ rel: 'canonical', href: host + routes[key].makePath(params) });
    }

    const jsonLdProps = {};
    // @ts-ignore TS2339
    if (this.canvas.name) {
      // @ts-expect-error TS2339
      const host = this.context.getModelData('hosts', 'host');
      // @ts-ignore TS2554
      const url = routes.display.makePath({ id: canvasId });
      // @ts-ignore TS2339
      jsonLdProps.breadcrumbList = {
        // @ts-ignore TS2339
        itemListElement: [{ name: this.canvas.name, item: host + url }],
      };
    }
    return (
      <React.Fragment>
        <HelmetProvider>
          <Helmet link={links} />
        </HelmetProvider>
        <JsonLd {...jsonLdProps} />
        {/*
         // @ts-ignore TS2339 */}
        <Canvas {...this.props} model={this.model} canvasId={canvasId} />
      </React.Fragment>
    );
  }
}
