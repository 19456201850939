import React, { useMemo, useCallback, useState, useEffect } from 'react';
import _ from 'src/domain/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ProductCard from './ProductCard';
import { Product } from 'src/types/context/Product';
import { Meta } from 'src/types/context/Meta';
import MainViewLink from '../../../MainViewLink';
import activeProfile, { isFree } from 'src/utils/activeProfile';
import routes from 'src/apps/common/routes';
import { CLICK_AREA, CUSTOM_EVENTS } from 'src/common/GtmApp';
import { LINKS } from 'src/constants/links';
import queryString from 'query-string';

type PPVPlanProps = {
  checkModal: () => void;
  meta?: Meta;
  event?: Event;
  products?: Product[];
  isProductPage: boolean;
  isDisabled?: boolean;
  spMode: boolean;
  containsSubOnlyTvod: boolean;
  doAction: (option: { selected: { type: string; id: number | string }; returnTo: string }) => void;
};

const PPVPlan = (props: PPVPlanProps, context) => {
  const authContext = context.getModelData('authContext');
  const browserInfo = context.getModelData('browserInfo');

  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  const [selectedSubOnlyTvodProduct, setSelectedSubOnlyTvodProduct] = useState<Product | null>(null);

  const SIGNUP_PATH = '/static/join/';
  const query = {};
  const pathname = _.get(window, 'location.pathname', '');
  query['return_to'] = pathname;
  const queryStr = _.isEmpty(pathname) ? '' : `?${queryString.stringify(query)}`;

  const sendToGtm = useCallback(
    (eventName: string, clickArea: string) => {
      if (_.get(context, 'gtmApp')) {
        context.gtmApp.pushDataLayerOnCustomClick(eventName, { click_area: clickArea });
      }
    },
    [context],
  );

  const signupLink = useMemo(() => {
    // ログインしていれば不要
    if (authContext) {
      return null;
    }
    const returnTo = encodeURIComponent(context.routeHandler.url);
    const signUpHref = `/signup_wol?return_to=${returnTo}&from=tvod`;
    const signInHref = routes.login.makePath();
    if (props.containsSubOnlyTvod && props.isProductPage) {
      return (
        <div className="plan-info__notice">
          ※アカウントをお持ちの方は
          {/*
           // @ts-expect-error TS2322 */}
          <MainViewLink
            href={signInHref}
            className={'accent-color'}
            onClick={() => {
              sendToGtm(CUSTOM_EVENTS.TVOD_LOGIN_CLICK, '商品ページ_未ログイン_S限定TVOD');
            }}
          >
            こちら
          </MainViewLink>
        </div>
      );
    } else {
      if (browserInfo.isAndroid || browserInfo.isIOS) {
        return (
          // @ts-expect-error TS2322
          <MainViewLink href={signUpHref} className={'plan-info__notice btn'}>
            WEBアカウント登録（無料）はこちら
          </MainViewLink>
        );
      } else {
        return (
          <div className="plan-info__notice">
            ※WEBアカウント登録（無料）は
            {/*
             // @ts-expect-error TS2322 */}
            <MainViewLink href={signUpHref} className={'accent-color'}>
              こちら
            </MainViewLink>
          </div>
        );
      }
    }
  }, [
    authContext,
    browserInfo.isAndroid,
    browserInfo.isIOS,
    context.routeHandler.url,
    props.containsSubOnlyTvod,
    props.isProductPage,
    sendToGtm,
  ]);

  const onClickJoin = useCallback(() => {
    if (context.gtmApp) {
      if (props.containsSubOnlyTvod) {
        sendToGtm(CUSTOM_EVENTS.SUBSCRIPTION_CLICK, 'エピソード画面S導線');
      } else {
        context.gtmApp.pushDataLayerOnSubscriptionClick(CLICK_AREA.SIGNUP.EPISODE);
      }
    }
  }, [context.gtmApp, props.containsSubOnlyTvod, sendToGtm]);

  const profile = activeProfile(context.models);
  const isFreeProfile = profile && isFree(profile);
  let link = null;

  if (!profile || (isFreeProfile && !props.containsSubOnlyTvod)) {
    let href = routes.login.makePath();
    let text = 'アカウントをお持ちの方は';
    let target;
    if (isFreeProfile) {
      href = LINKS.SUPPORT.REGISTER_SELF;
      text = 'ご契約済みの方は';
      target = '_blank';
    }
    if (browserInfo.isAndroid || browserInfo.isIOS) {
      link = (
        <div className="plan-info__item__li__sub">
          {/*
           // @ts-expect-error TS2322 */}
          <MainViewLink
            href={`${href + queryStr}`}
            className={'plan-info__notice btn'}
            target={target}
            onClick={() => {
              sendToGtm(CUSTOM_EVENTS.TVOD_LOGIN_CLICK, 'エピソード_未ログイン_S限定TVOD');
            }}
          >
            {`${text}こちら`}
          </MainViewLink>
        </div>
      );
    } else {
      link = (
        <div className="plan-info__item__li__sub">
          <div className="plan-info__notice">
            ※{text}
            {/*
             // @ts-expect-error TS2322 */}
            <MainViewLink
              href={`${href + queryStr}`}
              className={'accent-color'}
              target={target}
              onClick={() => {
                sendToGtm(CUSTOM_EVENTS.TVOD_LOGIN_CLICK, 'エピソード_未ログイン_S限定TVOD');
              }}
            >
              こちら
            </MainViewLink>
          </div>
        </div>
      );
    }
  }

  const monthlyPlanPrice = context.getModelData('monthlyPlanPrice');
  const subOnlyProducts = props.products.filter(p => p.saleCourseRule && p.saleCourseRule.length > 0) || [];
  const normalProducts = props.products.filter(p => !p.saleCourseRule) || [];

  useEffect(() => {
    // 複数商品と紐付ける場合の初期値設定
    if (!selectedSubOnlyTvodProduct && subOnlyProducts.length > 0) {
      setSelectedSubOnlyTvodProduct(
        [...subOnlyProducts].sort((a, b) => {
          //@ts-ignore TS2339
          const priceA = a.activePricing?.paymentAmount ?? 0;
          //@ts-ignore TS2339
          const priceB = b.activePricing?.paymentAmount ?? 0;
          return priceB - priceA;
        })[0],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedProductId = (productId: string) => {
    const product = props.products.find(p => p.id == productId);
    setSelectedSubOnlyTvodProduct(product);
  };

  const onSubOnlyTvodPurchase = useCallback(() => {
    if (context.gtmApp) {
      context.gtmApp.pushDataLayerOnCustomClick(CUSTOM_EVENTS.TVOD_BUY_CLICK, {
        click_area: 'エピソード_ログイン＆加入済み_S限定TVOD',
      });
    }

    if (selectedSubOnlyTvodProduct && selectedSubOnlyTvodProduct.onSale) {
      const returnTo = encodeURIComponent(context.routeHandler.url);
      props.doAction({ selected: { type: 'product', id: selectedSubOnlyTvodProduct.id }, returnTo });
    }
  }, [context.gtmApp, context.routeHandler.url, props, selectedSubOnlyTvodProduct]);

  return (
    <>
      {!props.isProductPage && props.containsSubOnlyTvod && subOnlyProducts.length > 0 && (
        <>
          <div className="plan-info__title">
            月額契約中の方のみご購入いただけます<div className="card-badge">月額</div>+
            <div className="card-badge tvod ppv">PPV</div>
          </div>

          <div className="sub-only-tvod-plan">
            <div className="sub-only-tvod-plan__item">
              <div
                className={classnames('sub-only-tvod-plan__step step1', {
                  alreadySubscribed: profile && !isFreeProfile,
                })}
              >
                <span>STEP</span>1
              </div>
              <div className="sub-only-tvod-plan__item__inner svod">
                <div className="plan-info__item svod">
                  <div className={classnames('plan-info__item__li', { alreadySubscribed: profile && !isFreeProfile })}>
                    <div className="plan-info__item__li__name">月額視聴料</div>
                    <div className="plan-info__item__li__price">
                      <div className="price">
                        <span>{monthlyPlanPrice}</span>円（税込み）
                      </div>
                    </div>
                  </div>
                  <div className="plan-info__item__li btn-box">
                    {!profile || isFreeProfile ? (
                      <>
                        {/*
                         // @ts-expect-error TS2322 */}
                        <MainViewLink
                          href={`${SIGNUP_PATH + queryStr}`}
                          className="btn sign-up btn-wide btn-svod"
                          onClick={onClickJoin}
                        >
                          ご加入はこちら
                        </MainViewLink>
                        {link}
                      </>
                    ) : (
                      <div className="btn btn-wide btn-subscribed">
                        <i className="fa fa-check-circle"></i>
                        ご加入済み
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-only-tvod-plan__item">
              <div
                className={classnames('sub-only-tvod-plan__step step2', {
                  alreadySubscribed: profile && !isFreeProfile,
                })}
              >
                <span>STEP</span>2
              </div>
              <div className="sub-only-tvod-plan__item__inner ppv">
                {subOnlyProducts
                  .slice()
                  .sort((a, b) => {
                    //@ts-ignore TS2339
                    const priceA = a.activePricing?.paymentAmount ?? 0;
                    //@ts-ignore TS2339
                    const priceB = b.activePricing?.paymentAmount ?? 0;
                    return priceB - priceA;
                  })
                  .map((product, index) => (
                    <ProductCard
                      key={index}
                      // @ts-ignore TS2339
                      product={product}
                      doAction={props.doAction}
                      meta={props.meta}
                      isDisabled={props.isDisabled}
                      isProductPage={props.isProductPage}
                      spMode={props.spMode}
                      showSubOnlyTvodUI={true}
                      multi={subOnlyProducts.length > 1}
                      getSelectedProductId={getSelectedProductId}
                      selectedProduct={selectedSubOnlyTvodProduct}
                      index={index}
                    />
                  ))}
                {subOnlyProducts.length > 1 && (
                  <div className="sub-only-tvod-plan__item__inner__multiBtn">
                    <p>商品を選択して購入してください</p>
                    <div className="sub-only-tvod-plan__item__inner__multiBtn__box">
                      <button
                        type="button"
                        className={classnames('btn btn-fill purchase-btn', {
                          disabled: !authContext || isFreeProfile,
                        })}
                        onClick={onSubOnlyTvodPurchase}
                      >
                        購入する
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {props.isProductPage ||
      (!props.isProductPage && !props.containsSubOnlyTvod) ||
      (!props.isProductPage && props.containsSubOnlyTvod && isAccordionOpen) ? (
        <>
          {!props.isProductPage ? (
            <div className="plan-info__title">
              対象番組のみ視聴<div className="card-badge tvod ppv">PPV</div>
            </div>
          ) : null}

          {props.isProductPage
            ? props.products.map((product, index) => (
                <ProductCard
                  key={`product-${index}`}
                  //@ts-ignore TS2339
                  product={product}
                  doAction={props.doAction}
                  meta={props.meta}
                  isDisabled={props.isDisabled}
                  isProductPage={props.isProductPage}
                  spMode={props.spMode}
                  showSubOnlyTvodUI={props.isProductPage && props.containsSubOnlyTvod}
                />
              ))
            : normalProducts
                .slice()
                .sort((a, b) => {
                  //@ts-ignore TS2339
                  const priceA = a.activePricing?.paymentAmount ?? '0';
                  //@ts-ignore TS2339
                  const priceB = b.activePricing?.paymentAmount ?? '0';
                  return priceB - priceA;
                })
                .map((product, index) => (
                  <ProductCard
                    key={`product-${index}`}
                    //@ts-ignore TS2339
                    product={product}
                    doAction={props.doAction}
                    meta={props.meta}
                    isDisabled={props.isDisabled}
                    isProductPage={props.isProductPage}
                    spMode={props.spMode}
                    showSubOnlyTvodUI={false}
                  />
                ))}
          {signupLink}
        </>
      ) : null}
      {!props.isProductPage && props.containsSubOnlyTvod && normalProducts.length > 0 && (
        <div
          className="normal-tvod-accordion"
          onClick={() => {
            if (!isAccordionOpen) sendToGtm(CUSTOM_EVENTS.ACCORDION_CLICK, 'TVOD視聴プラン一覧');
            setIsAccordionOpen(!isAccordionOpen);
          }}
        >
          {isAccordionOpen ? '閉じる' : '月額契約をせずに対象番組をご視聴いただくにはこちら'}
          {isAccordionOpen ? <i className="fa fa-angle_up"></i> : <i className="fa fa-angle_down"></i>}
        </div>
      )}
    </>
  );
};

PPVPlan.contextTypes = {
  getModelData: PropTypes.func,
  routeHandler: PropTypes.object,
  models: PropTypes.object,
  gtmApp: PropTypes.object,
};
export default React.memo<PPVPlanProps>(PPVPlan);
