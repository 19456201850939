import React from 'react';
import PropTypes from 'prop-types';

class FreezeWrapper extends React.Component {

  static get propTypes() {
    return {
      shouldFreeze: PropTypes.bool
    };
  }

  static get defaultProps() {
    return {
      shouldFreeze: false
    };
  }

  render() {
    // @ts-expect-error TS2339
    return this.props.children;
  }
}

export default FreezeWrapper;
