import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import routes from '../../../common/routes';
import Canvas from './Canvas';
import { withModalContext } from '../../../common/context/ModalContext';
import activeProfile, { isFree } from '../../../../utils/activeProfile';
import Interest from './Interest';
import HtmlContext from '../../../common/context/HtmlContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InterestApp from '../../../../common/InterestApp';
import GlobalMenu from 'src/apps/common/components/GlobalMenu';
import MainViewLink from '../../../common/components/MainViewLink';
import GenreTab from 'src/apps/common/components/GlobalMenu/Header/GenreTab';
import { CLICK_AREA } from '../../../../common/GtmApp';

class HomeContent extends Component {
  static getPaths = function(models, options, props) {
    return Canvas.getPaths(models, options, {
      canvasId: this.getCanvasId(models, options, props),
      isHome: true,
    })
      .concat(this.getInfoPaths(models, options, props))
      .concat(this.getInterestPalettePath(models, options, props))
      .concat([
        GlobalMenu.getGenreRootPath(models, options, props).concat([
          { from: 0, to: 49 },
          ['id', 'refId', 'name', 'canvas'],
        ]),
      ]);
  };

  static getCanvasId = function(models, options = {}, props = {}) {
    let canvasId;
    // @ts-ignore TS2554
    const profile = activeProfile(models);
    // @ts-ignore TS2339
    if (props.canvasId) {
      // @ts-ignore TS2339
      canvasId = props.canvasId;
      // @ts-ignore TS2339
    } else if (props.routeHandler.route.match(routes.title)) {
      canvasId = _.get(models, 'config.data.canvas.title');
      // 無料以外
    } else if (!isFree(profile)) {
      const isEven = profile.refId % 2 === 0;
      if (isEven && _.get(models, 'config.data.canvas.top.browse_even_member_id')) {
        canvasId = models.config.data.canvas.top.browse_even_member_id;
      } else if (!isEven && _.get(models, 'config.data.canvas.top.browse_odd_member_id')) {
        canvasId = models.config.data.canvas.top.browse_odd_member_id;
      }
      if (!canvasId) canvasId = _.get(models, 'config.data.canvas.top.browse');
    } else {
      // 無料アカウント
      if (profile && profile.refId) {
        const isEven = profile.refId % 2 === 0;
        if (isEven && _.get(models, 'config.data.canvas.top.marketing_even_member_id')) {
          canvasId = models.config.data.canvas.top.marketing_even_member_id;
        } else if (!isEven && _.get(models, 'config.data.canvas.top.marketing_odd_member_id')) {
          canvasId = models.config.data.canvas.top.marketing_odd_member_id;
        }
      }
      if (!canvasId) canvasId = _.get(models, 'config.data.canvas.top.marketing');
    }
    return canvasId;
  };

  static getInterestPalettePath = function(models = {}, options = {}, props = {}) {
    let path = [];
    // @ts-ignore TS2554
    const profile = activeProfile(models);
    // 無料以外
    if (!isFree(profile) && !profile.isInterestRegistered) {
      const idKey = _.get(models, 'config.data.palette.interests');
      if (idKey) path.push(['palette', idKey, 'objects', 'length']);
    }
    return path;
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getInfoPaths = function(models, options, props) {
    const rootPath = this.getInfoRootPath(models, options, props);
    return [
      rootPath.concat([{ from: 0, to: 49 }, ['id', 'title', 'url', 'thumbnailUrl', 'postAt']]),
      rootPath.concat(['additional']),
    ];
  };
  static getInfoRootPath = function(models, options, props = {}) {
    const key = _.get(models, 'config.data.link_set_keys.info.top', {});
    return ['link_sets', key];
  };

  static arrangeData = function(data) {
    const additional = data.additional;
    data = _.clone(
      _.values(
        _.omitBy(data, (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id') || key === 'additional';
        }),
      ),
    );
    if (!_.isEmpty(additional)) {
      _.forEach(data, (link, i) => {
        if (additional[i]) Object.assign(link, additional[i]);
      });
    }
    return data;
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.handleTabGenreClick = this.handleTabGenreClick.bind(this);

    // @ts-ignore TS2339
    const rootPath = this.constructor.getInfoRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const infoIndex = this.model.getSync(rootPath);
    if (infoIndex) {
      // @ts-ignore TS2339
      this.infoData = this.constructor.arrangeData(infoIndex);
    }
    // @ts-ignore TS2339
    const genreData = this.model.getSync(GlobalMenu.getGenreRootPath(context.models, {}, props));
    if (genreData) {
      // @ts-ignore TS2339
      this.genres = this.constructor.arrangeData(genreData);
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    // 興味あるリスト
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    // 無料以外
    if (!isFree(profile) && !profile.isInterestRegistered) {
      // TODO
      profile.isInterestRegistered = true;
      // @ts-expect-error TS2339
      const idKey = _.get(this.context.models, 'config.data.palette.interests');
      // @ts-ignore TS2339
      const length = this.model.getSync(['palette', idKey, 'objects', 'length']) || 0;
      if (length > 0) {
        // @ts-ignore TS2339
        this.props.showModal(<Interest {...this.props} />, { classes: 'interest' });
      } else {
        // @ts-expect-error TS2339
        const authContext = this.context.getModelData('authContext');
        // @ts-expect-error TS2339
        const interestApp = new InterestApp(this.context.getModelData('services', 'cms'));
        interestApp.registered(authContext, profile.id);
      }
    }
    this.sendToGtm();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-expect-error TS2339
    this.context.gtmApp.pageView('ホーム');
  }

  handleTabGenreClick(genreName) {
    return () => {
      // @ts-expect-error TS2339
      if (this.context.gtmApp) {
        // @ts-expect-error TS2339
        this.context.gtmApp.pushDataLayerOnMenuGenreClick(CLICK_AREA.MENU_GENRE.OVER_MUST_HEAD, genreName);
      }
    };
  }

  render() {
    // @ts-ignore TS2339
    const canvasId = this.constructor.getCanvasId(this.context.models, {}, this.props);
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!canvasId) return null;
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    const isFreeProfile = !profile || isFree(profile);
    let genreTab;

    // @ts-ignore TS2339
    if (this.genres) {
      // @ts-ignore TS2339
      let tablinks = _.map(this.genres, genre => {
        let props = {
          params: { id: genre.id },
        };
        switch (genre.type) {
          case 'genre':
            if (genre.refId) {
              props = {
                // @ts-ignore TS2322
                to: routes.genreRef,
                params: { id: genre.refId },
              };
            } else {
              // @ts-ignore TS2339
              props.to = routes.genre;
            }
            break;
          case 'person':
            // @ts-ignore TS2339
            props.to = routes.person;
            break;
          case 'studio':
            // @ts-ignore TS2339
            props.to = routes.studio;
            break;
          case 'league':
            // @ts-ignore TS2339
            props.to = routes.league;
            break;
          case 'team':
            // @ts-ignore TS2339
            props.to = routes.team;
            break;
          default:
            // @ts-ignore TS2339
            props.to = routes.attr;
        }
        return (
          // @ts-ignore TS2322
          <MainViewLink {...props} onClick={this.handleTabGenreClick(genre.name)}>
            {genre.name}
          </MainViewLink>
        );
      });
      // @ts-ignore TS2339
      genreTab = <GenreTab genreLinks={tablinks} ref={this.genreListRef} showGenres={true} />;
    }

    // PCで未契約の場合はヘッダー下に出す
    if (!browserInfo.isIOS && !browserInfo.isAndroid && !isFreeProfile) {
      genreTab = null;
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {() => {
            const metas = [];
            const links = [];
            // @ts-expect-error TS2339
            const ogpInfo = this.context.getModelData('config', 'ogpInfo');
            metas.push({ property: 'og:type', content: _.get(ogpInfo.type, 'website') });
            // @ts-expect-error TS2339
            const host = this.context.getModelData('hosts', 'host');
            // @ts-ignore TS2554
            const url = routes.home.makePath();
            links.push({ rel: 'canonical', href: host + url });
            return (
              <HelmetProvider>
                <Helmet meta={metas} link={links} />
              </HelmetProvider>
            );
          }}
        </HtmlContext.Consumer>
        <div className="home-container">
          <Canvas
            {...this.props}
            // @ts-ignore TS2339
            model={this.model}
            // @ts-ignore TS2339
            infoData={this.infoData}
            genreTab={genreTab}
            infoShowLength={3}
            canvasId={canvasId}
            isHome={true}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withModalContext(HomeContent);
