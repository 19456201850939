import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class AudioTextSettingsButton extends React.PureComponent {
  static get propTypes() {
    return {
      player: PropTypes.object.isRequired,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onClick(e) {
    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
    }
    // @ts-ignore TS2339
    if (this.props.releaseFocus) {
      // @ts-ignore TS2339
      this.props.releaseFocus(e);
    }
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('audioTextSettings');
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (!this.props.showSettings && this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover('audioTextSettings');
    }
  }

  render() {
    const props = {};
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid && !browserInfo.isRequestDesktopWebsite) {
      // @ts-ignore TS2339
      props.onMouseEnter = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onFocus = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onBlur = this.onMouseLeave;
    }
    return (
      <button
        {...props}
        // @ts-ignore TS2339
        ref={this.props.forwardedRef}
        type="button"
        className={classnames('touchable player-control-element player-button-control fa fa-sub-dub')}
        // @ts-ignore TS2322
        tabIndex="0"
        role="button"
        aria-label="音声字幕設定"
        onClick={this.onClick}
      ></button>
    );
  }
}

// export default AudioTextSettingsButton;
export default React.forwardRef(function audioTextSettingsButton(props, ref) {
  // @ts-ignore TS2322
  return <AudioTextSettingsButton {...props} forwardedRef={ref} />;
});
