import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import InterestApp from '../../../../common/InterestApp';
import PaletteContent from './PaletteContent';
import activeProfile from '../../../../utils/activeProfile';
import { withLayoutContext } from 'src/apps/common/context/LayoutContext';
import classnames from 'classnames';

interface InterestState {
  itemCount: number;
}

class Interest extends React.Component<any, InterestState> {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // DeleteAppを流用する
    // @ts-ignore TS2339
    this._deleteApp = new InterestApp(context.getModelData('services', 'cms'), this.context.gtmApp, props.model);
    // @ts-ignore TS2339
    this._deleteApp.toggleMode();
    this.handleSend = this.handleSend.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      itemCount: 0,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    // 一旦表示したタイミング
    // @ts-expect-error TS2339
    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    // @ts-ignore TS2339
    this._deleteApp.registered(authContext, profile.id);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleSend() {
    // @ts-expect-error TS2339
    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    // @ts-ignore TS2339
    this._deleteApp
      .send(authContext, profile.id)
      .then(response => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        // @ts-ignore TS2339
        this.props.closeModal();
      })
      .catch(e => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        // @ts-ignore TS2339
        this.props.closeModal();
      });
  }
  handleClose() {
    if (_.get(this.context, 'gtmApp')) {
      // GTMにスキップイベントを送信
      // @ts-expect-error TS2339
      this.context.gtmApp.pushDataLayerOnInitialFavoriteSkipClick();
    }
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  /**
   * 気になる番組に追加・削除した際のカウント
   */
  countFavoriteItems = (isAdding: boolean) => {
    if (isAdding) {
      this.setState({ itemCount: this.state.itemCount - 1 });
    } else {
      this.setState({ itemCount: this.state.itemCount + 1 });
    }
  };

  render() {
    // @ts-expect-error TS2339
    const idKey = _.get(this.context.models, 'config.data.palette.interests', 'interests');
    return (
      <div className="interest-wrapper">
        <p>
          まずは気になる番組を
          {this.props.spMode && <br />}
          お気に入り登録してみましょう
        </p>
        <PaletteContent
          {...this.props}
          id={idKey}
          showSpaOnly={true}
          shouldSkipGtm={true}
          headerVisuable={false}
          useHelmet={false}
          // @ts-ignore TS2322
          deleteApp={this._deleteApp}
          countFavoriteItems={this.countFavoriteItems}
        />
        <div className="btn-wrapper">
          <a className={classnames('btn btn-fill', { disabled: this.state.itemCount == 0 })} onClick={this.handleSend}>
            完了
          </a>
          <a href="javascript:void(0);" className="skip-btn" onClick={this.handleClose}>
            スキップ
          </a>
        </div>
      </div>
    );
  }
}
export default withLayoutContext(Interest);
