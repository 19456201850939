import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import SelectPeriodRow from './SelectPeriodRow';
import SortRow from './SortRow';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import BackgroundImage from '../../../common/components/BackgroundImage';

export default class RowHeader extends Component {
  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      selectPeriod: PropTypes.string,
      onSelectPeriodChange: PropTypes.func,
      sortOrder: PropTypes.string,
      sortOrders: PropTypes.arrayOf(
        PropTypes.shape({
          so: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
      onSortOrderChange: PropTypes.func,
    };
  }

  static get defaultProps() {
    return {
      id: null,
      title: null,
      hideAll: false,
    };
  }

  static get contextTypes() {
    return {
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      isTallRow: PropTypes.bool,
      listContext: PropTypes.string,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    // @ts-ignore TS2339
    if (!this.props.title && !this.props.description) {
      this.renderEmpty();
    }

    let headerClassName, pulldownRow;
    // @ts-ignore TS2339
    if (this.props.selectPeriod && this.props.onSelectPeriodChange) {
      headerClassName = classnames('row-header', 'with-select-period-row', {
        // @ts-ignore TS2339
        'without-description': !this.props.description,
      });
      pulldownRow = (
        <SelectPeriodRow
          // @ts-ignore TS2339
          selectPeriod={this.props.selectPeriod}
          // @ts-ignore TS2339
          onSelectPeriodChange={this.props.onSelectPeriodChange}
        />
      );
      // @ts-ignore TS2339
    } else if (this.props.sortOrders && this.props.sortOrder && this.props.onSortOrderChange) {
      headerClassName = classnames('row-header', 'with-sort-row', {
        // @ts-ignore TS2339
        'without-description': !this.props.description,
      });
      pulldownRow = (
        <SortRow
          // @ts-ignore TS2339
          sortOrders={this.props.sortOrders}
          // @ts-ignore TS2322
          sortOrder={this.props.sortOrder}
          // @ts-ignore TS2339
          onSortOrderChange={this.props.onSortOrderChange}
        />
      );
    }

    let rowTitle, rowDescription, rowTab;
    // @ts-ignore TS2339
    if (this.props.title) {
      // @ts-ignore TS2339
      const listContext = this.props.listContext || this.context.listContext;
      let showLink = !!routes[listContext];
      let linkLabel = 'すべて表示';
      let linkProps = {
        to: routes[listContext],
        // @ts-ignore TS2339
        params: { id: this.props.id },
        // @ts-ignore TS2339
        query: this.props.query,
      };
      if (-1 !== _.indexOf(['nowonair', 'timeline', 'fromnow', 'schedule'], listContext)) {
        showLink = true;
        linkLabel = '番組表を見る';
        const simulcast = _.find(
          // @ts-expect-error TS2339
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.props.linearChannelMeta.refId,
        );
        // @ts-ignore TS2322
        linkProps = { href: `/schedule/${_.lowerCase(simulcast.name)}` };
        // @ts-ignore TS2339
        linkProps.target = '_blank';
      }
      // リンク指定があるやつは最優先
      // @ts-ignore TS2339
      if (this.props.url) {
        // @ts-ignore TS2322
        linkProps = { href: this.props.url };
      }
      // @ts-ignore TS2339
      if (this.props.target) {
        // @ts-ignore TS2339
        linkProps.target = this.props.target;
      }
      // @ts-ignore TS2339
      if (this.props.linkLabel) {
        // @ts-ignore TS2339
        linkLabel = this.props.linkLabel;
      }
      const title = (
        <div className="row-header-title">
          <div className="row-header-title-inner">
            {/*
             // @ts-ignore TS2339 */}
            {this.props.thumbnailUrl ? <BackgroundImage className="artwork" url={this.props.thumbnailUrl} /> : null}
            {/*
             // @ts-ignore TS2339 */}
            {this.props.title}
          </div>
        </div>
      );
      // @ts-ignore TS2339
      showLink = showLink && !this.props.hideAll;
      rowTitle = (
        <div
          className={classnames('row-title', {
            showLink: showLink,
            // @ts-ignore TS2339
            rightShowLink: this.props.rightShowLink,
            pulldownRow: !!pulldownRow,
          })}
        >
          {/*
           // @ts-ignore TS2339 */}
          {this.props.hideAll ? (
            title
          ) : (
            <React.Fragment>
              <MainViewLink {...linkProps}>
                {title}
                {/*
                 // @ts-ignore TS2339 */}
                {showLink && !this.props.rightShowLink ? (
                  // @ts-ignore TS2339
                  <div className="row-arrow" aria-label={`${this.props.title}をすべて表示する`}>
                    <div className="see-more-link">{linkLabel}</div>
                    <div className="row-chevron icon-caret-right fa fa-angle_right"></div>
                  </div>
                ) : null}
              </MainViewLink>
              {pulldownRow}
              {/*
               // @ts-ignore TS2339 */}
              {showLink && this.props.rightShowLink ? (
                // @ts-expect-error TS2322
                <MainViewLink
                  {...linkProps}
                  // @ts-ignore TS2322
                  className={classnames('row-arrow', { hilight: this.props.showAllHilight })}
                  // @ts-ignore TS2339
                  aria-label={`${this.props.title}をすべて表示する`}
                >
                  <div className="see-more-link">{linkLabel}</div>
                  <div className="row-chevron icon-caret-right fa fa-angle_right"></div>
                </MainViewLink>
              ) : null}
            </React.Fragment>
          )}
        </div>
      );
    }
    // @ts-ignore TS2339
    if (this.props.description) {
      // @ts-ignore TS2339
      rowDescription = <span className="row-description">{this.props.description}</span>;
    }

    if (headerClassName && pulldownRow) {
      return (
        <h2 className={headerClassName}>
          <div className="row-header-title-wrapper">{rowTitle}</div>
          {rowDescription}
        </h2>
      );
    }

    // @ts-expect-error TS2339
    const routeHandler = this.context.routeHandler;
    const Title = props => {
      if (routeHandler.route._regex === routes.title._regex) {
        return <div className={props.classname}>{props.children}</div>;
      } else {
        return <h2 className={props.classname}>{props.children}</h2>;
      }
    };

    return (
      <Title {...this.props} classname="row-header">
        {rowTitle}
        {rowDescription}
      </Title>
    );
  }

  renderEmpty() {
    return (
      <div className="row-header">
        <span className="row-title">&nbsp;</span>
      </div>
    );
  }
}
