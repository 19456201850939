import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import presentationHOC from '../../../common/hocs/presentationHOC';
import HtmlSnippet from '../../../common/components/HtmlSnippet';

import TitlePalette from './TitlePalette';
import GalleryContent from './GalleryContent';
import TitleCard from './TitleCard';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';

const PERPAGE = 10;

// 未使用
class SeriesCanvas extends Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    const indexes = {
      from: (props && props.fromNum) || 0,
      to:
        props && props.toNum
          ? props.toNum - (props.fromNum || 0)
          : options && options.numItems
          ? options.numItems
          : PERPAGE - 1,
    };

    const rowPaths = TitlePalette.getPaths(models, options, {});
    const canvasRowPaths = rowPaths.map(path => {
      return rootPath.concat(['children', 'df', indexes]).concat(path);
    });

    const paths = canvasRowPaths.concat([rootPath.concat(['children', 'df', 'length'])]);
    return paths;
  };

  static getRootPath = function(models, options, props) {
    if (props.id) {
      return ['meta', props.id];
    }
    return [];
  };

  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      numRows: PropTypes.number,
      enablePeek: PropTypes.bool,
      model: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
      columnsInRow: PropTypes.number,
    };
  }

  static get childContextTypes() {
    return {
      columnsInRow: PropTypes.number,
    };
  }

  getChildContext() {
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-expect-error TS2339
    const columnsInRow = browserInfo.isIOS || browserInfo.isAndroid ? 1 : this.context.columnsInRow;
    return { columnsInRow };
  }

  constructor(props, context) {
    super(props, context);

    this.onScroll = this.onScroll.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
    // @ts-ignore TS2339
    this.numRowsToFetch = PERPAGE;
    // @ts-ignore TS2339
    this.canvasRef = React.createRef();

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.meta = props.model.getSync(rootPath);
    // @ts-ignore TS2339
    if (this.meta && _.get(this.meta, 'children.df')) {
      // @ts-ignore TS2339
      this.items = _.compact(_.values(_.omit(this.meta.children.df, ['$__path'])));
    }
    this.state = {
      bouncing: false,
      dispose: null,
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
      maxRowToLoadImages: 6,
      maxRowToRender: 3,
      scrollHandlersDisabled: true,
    };
  }

  getSnippetId() {
    // 未ログインの時のみスニペットを表示する。
    // @ts-expect-error TS2339
    if (_.get(this.context.getModelData('userInfo'), 'status') != 'NON_REGISTERED_MEMBER') return;

    let snippetId = _.get(this.context, 'models.config.data.html_snippet_keys.series_title');
    // @ts-ignore TS2339
    if (this.props.rental && snippetId) {
      // @ts-expect-error TS2322
      snippetId = 'rental_' + snippetId;
    }
    return snippetId;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);

    browserEvents.addEventListener('scroll', this.onScroll);
    browserEvents.addEventListener('scrollEnd', this.onScrollEnd);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();

    browserEvents.removeEventListener('scroll', this.onScroll);
    browserEvents.removeEventListener('scrollEnd', this.onScrollEnd);
  }

  fetchMoreRows() {
    // @ts-ignore TS2339
    if (!this.meta || !this.items) return;
    // @ts-ignore TS2339
    if (this.numRowsToFetch > this.items.length && !this.state.fetchingMoreRows) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const pathProps = Object.assign({}, props, { toNum: this.numRowsToFetch - 1 });
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.meta = _.get(res, ['json'].concat(rootPath));
        const totalRowCount = props.model.getSync(`meta.${props.id}.children.df.length`, 0);
        // @ts-ignore TS2339
        if (this.meta && _.get(this.meta, 'children.df')) {
          // @ts-ignore TS2339
          this.items = _.compact(
            // @ts-ignore TS2339
            _.dropRightWhile(_.values(_.omit(this.meta.children.df, ['$__path'])), function(v) {
              return _.isNumber(v);
            }),
          );
        }

        // @ts-ignore TS2339
        this.numRowsToFetch += PERPAGE;
        // @ts-ignore TS2339
        if (this.numRowsToFetch > totalRowCount) this.numRowsToFetch = totalRowCount;
        delete this.state[JSON.stringify(paths)];
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };

        // @ts-ignore TS2339
        delete this.state.fetchingMoreRows;
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    let spinLoader;
    // @ts-ignore TS2339
    if (this.state.fetchingMoreRows === true) {
      spinLoader = (
        <div className="gallery-spin-loader">
          <div className="loader-cont"></div>
        </div>
      );
    }

    // @ts-ignore TS2339
    if (!this.meta && !spinLoader) {
      return null;
    }

    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');

    let rows = null;
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // let header = (
      //   <React.Fragment>
      //     <div className="gallery-header">
      //       <div className="title">
      //         <span className="gallery-title">作品</span>
      //       </div>
      //     </div>
      //   </React.Fragment>
      // )
      // @ts-ignore TS2339
      const cards = _.map(this.items, (child, i) => {
        return (
          <TitleCard
            // @ts-ignore TS2322
            titleId={child.id}
            // @ts-ignore TS2339
            model={this.props.model}
            listCard={true}
            key={`title-card-${i}`}
            enableCardClick={true}
          />
        );
      });
      // @ts-expect-error TS2559
      rows = <GalleryContent>{cards}</GalleryContent>;
    } else {
      // @ts-ignore TS2339
      rows = _.map(this.items, (child, i) => {
        if (!child) return null;
        const props = {};
        if (child.type === 'media' || child.type === 'linear_channel') {
          // @ts-ignore TS2339
          props.children = [child];
        }
        // @ts-ignore TS2322
        return <TitlePalette {...props} id={child.id} model={this.props.model} key={`season_palette_${i}`} />;
      });
    }

    const snippetId = this.getSnippetId();

    return (
      // @ts-ignore TS2339
      <div ref={this.canvasRef} className="series-canvas">
        {/*
         // @ts-ignore TS2322 */}
        {snippetId ? <HtmlSnippet {...this.props} snippetId={snippetId} /> : null}
        {rows}
        {spinLoader}
      </div>
    );
  }

  onScroll(e) {
    if (this.isWithinDistanceBuffer(300)) {
      // @ts-ignore TS2554
      this.fetchMoreRows(true);
    }
  }

  onScrollEnd(e) {}

  isWithinDistanceBuffer(distanceBuffer) {
    // @ts-ignore TS2339
    if (!this.canvasRef) return;
    // @ts-ignore TS2339
    const el = this.canvasRef.current;
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }
}

export default presentationHOC(SeriesCanvas);
