import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';
import { CONTENT_EVENTS } from '../../../../common/GtmApp';
import { withModalContext } from '../../../common/context/ModalContext';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';

import ShareContents from './ShareContents';

class ShareButton extends Component {
  static get propTypes() {
    return {
      metadata: PropTypes.object,
      position: PropTypes.string,
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handleShareButtonClick = this.handleShareButtonClick.bind(this);
    this.shareContentOpenModal = this.shareContentOpenModal.bind(this);
    this.shareContentCloseModal = this.shareContentCloseModal.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);

    this.state = {
      open: false,
    };
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.shareContentCloseModal);
    } else {
      browserEvents.addEventListener('resize', this.shareContentCloseModal);
    }
    this.shareContentCloseModal();
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.shareContentCloseModal);
    } else {
      browserEvents.removeEventListener('resize', this.shareContentCloseModal);
    }
  }

  handleShareButtonClick() {
    // スマホサイズだったらモーダルを表示
    // @ts-ignore TS2339
    if (this.props.spMode) {
      // @ts-ignore TS2339
      if (this.state.open) this.setState({ open: false });
      this.shareContentOpenModal();
    } else {
      // @ts-ignore TS2339
      if (this.state.open) {
        this.setState({ open: false });
      } else {
        this.setState({ open: true });
        this.sendToGtm();
      }
    }
  }

  shareContentOpenModal() {
    let mobileType = false;
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (this.props.spMode || browserInfo.isIOS || browserInfo.isAndroid) {
      mobileType = true;
    }
    // @ts-ignore TS2339
    this.props.showModal(
      <ShareContents
        // @ts-ignore TS2322
        mobileType={mobileType}
        // @ts-ignore TS2339
        metadata={this.props.metadata}
        // @ts-ignore TS2339
        currentTime={this.props.currentTime}
        onclick={this.handleShareButtonClick}
        // @ts-ignore TS2339
        position={this.props.position}
      />,
    );
    this.sendToGtm();
  }
  shareContentCloseModal() {
    // @ts-ignore TS2339
    if (this.state.open) this.setState({ open: false });
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    const item = _.get(this.props, 'metadata');
    const channel = _.get(item, 'linearChannelMeta');
    let hasRelationProgram = false;
    if (_.get(item, 'seriesMeta') && _.get(item, 'seasonMeta')) {
      // @ts-ignore TS18048
      hasRelationProgram = item.seriesMeta.id != item.seasonMeta.id;
    }
    const isMediaMeta = _.get(item, 'type') === 'media';
    const relationProgram = hasRelationProgram
      ? {
          refId: _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seriesMeta.name'),
        }
      : null;
    const program = !_.isEmpty(channel)
      ? null
      : isMediaMeta
      ? {
          refId: _.get(item, 'seasonMeta.refId') || _.get(item, 'seriesMeta.refId'),
          name: _.get(item, 'seasonMeta.name') || _.get(item, 'seriesMeta.name'),
        }
      : {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name'),
          rental: _.get(item, 'rental'),
          subscription: _.get(item, 'subscription'),
        };
    const content = !_.isEmpty(channel)
      ? {
          refId: _.get(item, 'uniqueId'),
          name: _.get(item, 'name'),
        }
      : isMediaMeta
      ? {
          refId: _.get(item, 'refId'),
          name: _.get(item, 'name') || _.get(item, 'playableTitle'),
          rental: _.get(item, 'rental'),
          subscription: _.get(item, 'subscription'),
        }
      : null;
    const attributes = _.get(item, 'attributes');
    const genres = _.get(item, 'genres');
    const middleGenres = _.get(item, 'middleGenres');
    const schemaId = _.get(item, 'schemaId');
    // @ts-expect-error TS2339
    this.context.gtmApp.pushDataLayerOnContentPageClick(CONTENT_EVENTS.SHARE, {
      content,
      channel,
      relationProgram,
      program,
      attributes,
      genres,
      middleGenres,
      schemaId,
    });
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleShareButtonClick();
    }
  }

  render() {
    return (
      <div
        // @ts-ignore TS2339
        className={classnames('action-btn share-btn', { open: this.state.open })}
        onClick={this.handleShareButtonClick}
        tabIndex={0}
        onKeyDown={this.handleKeyDown.bind(this)}
      >
        <i className="fa fa-share"></i>
        <span>シェア</span>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.open ? (
          <ShareContents
            // @ts-ignore TS2322
            mobileType={this.props.spMode}
            // @ts-ignore TS2339
            metadata={this.props.metadata}
            // @ts-ignore TS2339
            currentTime={this.props.currentTime}
            onclick={this.handleShareButtonClick}
            // @ts-ignore TS2339
            position={this.props.position}
          />
        ) : null}
      </div>
    );
  }
}

export default withModalContext(ShareButton);
