import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import TargetTitle from './TargetTitle';
import RoomShare from './RoomShare';
import Input from './Input';

class Step1 extends Component {
  static get propTypes() {
    return {
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
      resetRoomName: PropTypes.func,
      resetNickname: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this._handleWatchPartyCancel = this._handleWatchPartyCancel.bind(this);

    // @ts-ignore TS2339
    this.roomRef = React.createRef();
    // @ts-ignore TS2339
    this.nameRef = React.createRef();

    this._handleRoomChange = this._handleRoomChange.bind(this);
    this._handleNameChange = this._handleNameChange.bind(this);
    this._handleEnter = this._handleEnter.bind(this);
    this._handleClick = this._handleClick.bind(this);

    const profole = context.watchPartyApp.getProfile();
    this.state = {
      room: '',
      name: profole.name,
      isSubmittable: true,
      showDescription: false,
    };
  }

  componentDidMount() {
    this.setState({ showDescription: true });
  }

  _handleWatchPartyCancel() {
    // @ts-expect-error TS2339
    if (this.context.watchPartyApp) {
      // @ts-expect-error TS2339
      this.context.watchPartyApp.dispose();
    }
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  _handleRoomChange(e) {
    this.setState({ room: e.target.value });
    // @ts-ignore TS2339
    this.props.resetRoomName();
  }

  _handleNameChange(e) {
    this.setState({ name: e.target.value });
    // @ts-ignore TS2339
    this.props.resetNickname();
  }

  _handleEnter(e) {
    if (e.keyCode == 13) {
      this._handleClick();
    }
  }

  _handleClick() {
    // @ts-ignore TS2339
    this.props.onClick(this.state.room, this.state.name);
  }

  render() {
    // @ts-expect-error TS2339
    let userAttendLimit = this.context.watchPartyApp.getUserAttendLimit();

    return (
      <React.Fragment>
        <div className="modal-block">
          <TargetTitle
            roomInfoView={false}
            // @ts-ignore TS2339
            titleModel={this.props.titleModel}
            // @ts-ignore TS2339
            seriesModel={this.props.seriesModel}
            // @ts-ignore TS2339
            linearModel={this.props.linearModel}
            // @ts-ignore TS2322
            userState={this.props.userState}
          />
        </div>
        <div className="modal-block">
          <div className="input-block">
            <dl>
              <dt>試聴制限時間</dt>
              {/*
               // @ts-expect-error TS2339 */}
              <dd>{`${Math.floor(this.context.watchPartyApp.getTimeLimit() / (60 * 60 * 1000))}時間`}</dd>
              <dt>試聴制限人数</dt>
              <dd>
                {/*
                 // @ts-ignore TS2339 */}
                {this.props.userState === 'master'
                  ? `${userAttendLimit}人`
                  // @ts-expect-error TS2339
                  : `${_.get(this.context.watchPartyApp, 'room.customData.userCount', 0)}/${userAttendLimit}人`}
              </dd>
            </dl>
          </div>
        </div>
        <div className="modal-block">
          <Input
            // @ts-ignore TS2339
            ref={this.roomRef}
            label={'ルーム名'}
            type="text"
            placeholder={'ルーム名を入力してください'}
            maxLength={16}
            required={true}
            // @ts-ignore TS2322
            onChange={this._handleRoomChange}
            onKeyDown={this._handleEnter}
            // @ts-ignore TS2339
            value={this.state.room}
            // @ts-ignore TS2339
            errorMessage={this.props.errorMessageRoomName}
          />
          <Input
            // @ts-ignore TS2339
            ref={this.nameRef}
            label={'ニックネーム'}
            type="text"
            placeholder={'ニックネームを入力してください'}
            maxLength={8}
            required={false}
            // @ts-ignore TS2322
            onChange={this._handleNameChange}
            onKeyDown={this._handleEnter}
            // @ts-ignore TS2339
            value={this.state.name}
            // @ts-ignore TS2339
            errorMessage={this.props.errorMessageNickname}
          />
        </div>
        <div className="modal-block">
          <div className="input-block">
            {/*
             // @ts-ignore TS2339 */}
            {this.state.showDescription && (
              <p className="small-text">
                再生/一時停止などは、ルーム主催者のみが操作でき、ルーム参加者は操作できません。
                {/* <br />ウォッチパーティを開始するには、<a href='https://www.hjholdings.jp/chat_guideline' target='_blank' class='text-accent'>チャットガイドライン</a> への同意が必要です。 */}
              </p>
            )}
          </div>
        </div>
        <div className="form-btn-block text-center">
          <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleWatchPartyCancel}>
            キャンセル
          </a>
          <a
            className={classnames('btn btn-fill btn-small', {
              // @ts-ignore TS2339
              disabled: _.isEmpty(this.state.room) || _.isEmpty(this.state.name),
            })}
            href="javascript:void(0);"
            onClick={this._handleClick}
          >
            {/* 同意して */}ルームを作成する
          </a>
        </div>
      </React.Fragment>
    );
  }
}
class Step2 extends Component {
  static get propTypes() {
    return {
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
      handleWatchPartyModeChange: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this._handleWatchPartyStart = this._handleWatchPartyStart.bind(this);
    this._handleWatchPartyCancel = this._handleWatchPartyCancel.bind(this);

    this.state = {
      isSubmittable: true,
    };
  }

  _handleWatchPartyStart() {
    // 開始日時を記録
    // @ts-expect-error TS2339
    this.context.watchPartyApp
      .startRoom()
      .then(() => {
        // @ts-ignore TS2339
        this.props.closeModal();
        // @ts-ignore TS2339
        this.props.handleWatchPartyModeChange();
      })
      .catch(e => {
        console.log(e);
      });
  }

  _handleWatchPartyCancel() {
    // @ts-expect-error TS2339
    this.context.watchPartyApp.leave((error, body) => {
      // 退室してから処理する
      // @ts-ignore TS2339
      this.props.onClick();
    });
  }

  render() {
    // @ts-expect-error TS2339
    let userAttendLimit = this.context.watchPartyApp.getUserAttendLimit();

    return (
      <React.Fragment>
        <div className="modal-block">
          <TargetTitle
            roomInfoView={true}
            // @ts-ignore TS2322
            room={this.props.room}
            // @ts-ignore TS2339
            titleModel={this.props.titleModel}
            // @ts-ignore TS2339
            seriesModel={this.props.seriesModel}
            // @ts-ignore TS2339
            linearModel={this.props.linearModel}
            // @ts-ignore TS2339
            userState={this.props.userState}
          />
        </div>
        <div className="modal-block">
          <div className="input-block">
            <dl>
              <dt>試聴制限時間</dt>
              {/*
               // @ts-expect-error TS2339 */}
              <dd>{`${Math.floor(this.context.watchPartyApp.getTimeLimit() / (60 * 60 * 1000))}時間`}</dd>
              <dt>試聴制限人数</dt>
              <dd>
                {/*
                 // @ts-ignore TS2339 */}
                {this.props.userState === 'master'
                  ? `${userAttendLimit}人`
                  // @ts-expect-error TS2339
                  : `${_.get(this.context.watchPartyApp, 'room.customData.userCount', 0)}/${userAttendLimit}人`}
              </dd>
            </dl>
          </div>
        </div>
        {/*
         // @ts-ignore TS2322 */}
        <RoomShare room={this.props.room} titleModel={this.props.titleModel} />
        <div className="form-btn-block text-center">
          <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleWatchPartyCancel}>
            戻る
          </a>
          <a className="btn btn-fill btn-small" href="javascript:void(0);" onClick={this._handleWatchPartyStart}>
            開始する
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default class RoomCreate extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      watchPartyMode: PropTypes.bool,
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      stepNum: 1,
      errorMessageRoomName: null,
      errorMessageNickname: null,
    };

    this._handleStepNumber = this._handleStepNumber.bind(this);
    this._resetRoomName = this._resetRoomName.bind(this);
    this._resetNickname = this._resetNickname.bind(this);
    this.createRoom = this.createRoom.bind(this);
  }

  _handleStepNumber(roomName, name) {
    // console.log('_handleStepNumber');
    // @ts-ignore TS2339
    if (this.state.stepNum == 1) {
      // @ts-ignore TS2339
      if (this.state.loading) return;
      this.setState({ loading: true }, () => {
        this.createRoom(roomName, name);
      });
    } else {
      // roomの削除
      // @ts-expect-error TS2339
      this.context.watchPartyApp
        .doDeleteRoom()
        .then(response => {
          // console.log(response);
          this.setState({ stepNum: 1, room: null });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  _resetRoomName() {
    this.setState({ errorMessageRoomName: null });
  }

  _resetNickname() {
    this.setState({ errorMessageNickname: null });
  }

  createRoom(roomName, name) {
    // roomの作成
    // @ts-expect-error TS2339
    this.context.watchPartyApp
      // @ts-ignore TS2339
      .createRoom(roomName, this.props.titleModel.id, this.props.titleModel.refId)
      .then(room => {
        // console.log(room);
        // @ts-expect-error TS2339
        this.context.watchPartyApp
          .initClient()
          .then(() => {
            // @ts-expect-error TS2339
            this.context.watchPartyApp.joinWithName(name, { master: 1 }, (error, body) => {
              this.setState({ loading: false });
              // console.log(error, body);

              // ニックネームNGワード
              if (_.has(error, 'message') && -1 !== error.message.indexOf('includes ng word')) {
                this.setState({ errorMessageNickname: '不適切な表現が含まれています' });
                return;
              }

              if (error) {
                // TODO: error処理
                console.log(error);
                return;
              }

              this.setState({ stepNum: 2, room: room });
            });
          })
          .catch(e => {
            console.log(e);
            this.setState({ loading: false });
          });
      })
      .catch(e => {
        console.log(e);
        this.setState({ loading: false });

        // ルーム名NGワード
        if (_.has(e, 'message') && -1 !== e.message.indexOf('includes ng word')) {
          this.setState({ errorMessageRoomName: '不適切な表現が含まれています' });
          return;
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal-title">
          <i className="fa-watch-party"></i>
          WatchParty
        </div>
        <div className="step-wrapper">
          <div
            className={classnames('step-wrapper__item', {
              // @ts-ignore TS2339
              select: this.state.stepNum == 1,
              // @ts-ignore TS2339
              active: this.state.stepNum == 2,
            })}
          >
            <div className="step-wrapper__item-number">1</div>
            <span>ルーム作成</span>
          </div>
          {/*
           // @ts-ignore TS2339 */}
          <div className={classnames('step-wrapper__item', { select: this.state.stepNum == 2 })}>
            <div className="step-wrapper__item-number">2</div>
            <span>招待・開始</span>
          </div>
        </div>
        <div className="text-left">
          {/*
           // @ts-ignore TS2339 */}
          {this.state.stepNum == 1 ? (
            <Step1
              // @ts-ignore TS2339
              titleModel={this.props.titleModel}
              // @ts-ignore TS2339
              seriesModel={this.props.seriesModel}
              // @ts-ignore TS2339
              linearModel={this.props.linearModel}
              // @ts-ignore TS2339
              closeModal={this.props.closeModal}
              // @ts-ignore TS2322
              userState={this.props.userState}
              onClick={this._handleStepNumber}
              resetRoomName={this._resetRoomName}
              resetNickname={this._resetNickname}
              // @ts-ignore TS2339
              errorMessageRoomName={this.state.errorMessageRoomName}
              // @ts-ignore TS2339
              errorMessageNickname={this.state.errorMessageNickname}
            />
          ) : // @ts-ignore TS2339
          this.state.stepNum == 2 ? (
            <Step2
              // @ts-ignore TS2322
              room={this.state.room}
              // @ts-ignore TS2339
              titleModel={this.props.titleModel}
              // @ts-ignore TS2339
              seriesModel={this.props.seriesModel}
              // @ts-ignore TS2339
              linearModel={this.props.linearModel}
              // @ts-ignore TS2339
              closeModal={this.props.closeModal}
              // @ts-ignore TS2339
              userState={this.props.userState}
              onClick={this._handleStepNumber}
              // @ts-ignore TS2339
              handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
            />
          ) : null}
        </div>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.loading ? (
          <div className="comment-loading">
            <div className="loading-spinner"></div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
