import App from 'src/sketch-platform/app/App';
import routeHandlers from './routeHandlers';
import routes from '../common/routes';
import createApp from './createApp';
import _ from 'src/domain/libs/util';
import PlayerApp from '../../common/PlayerApp';
import AuthApp from '../../common/AuthApp';
import GtmApp from '../../common/GtmApp';
import AppsFlyerApp from '../../common/AppsFlyerApp';
import WatchPartyApp from '../../common/WatchPartyApp';
import FullScreenApp from 'src/common/FullScreenApp';

let copyrights = [];
const discoveryApp = createApp({}, {}, {});

export default new App(
  {
    routeHandlers,
    routes,
  },
  {
    // @ts-ignore TS7006
    handleCreateContext: function(context) {
      const fsApp = new FullScreenApp();
      const playerApp = new PlayerApp({
        config: _.get(context, 'model.models.config.data', {}),
        browserInfo: _.get(context, 'model.models.browserInfo.data', {}),
        googlecast: _.get(context, 'model.models.googlecast.data', {}),
        fsApp,
      });

      const authApp = new AuthApp(_.get(context, 'model.models', {}), {}, _.get(context, ['model', 'node', 'req']));

      const gtmApp = new GtmApp({
        models: _.get(context, 'model.models', {}),
        config: _.get(context, 'model.models.config.data', {}),
        browserInfo: _.get(context, 'model.models.browserInfo.data', {}),
      });

      const appsFlyerApp = new AppsFlyerApp({
        models: _.get(context, 'model.models', {}),
        config: _.get(context, 'model.models.config.data', {}),
        browserInfo: _.get(context, 'model.models.browserInfo.data', {}),
      });

      const watchPartyApp = new WatchPartyApp(
        authApp,
        _.get(context, 'model.models', {}),
        {},
        _.get(context, ['model', 'node', 'req']),
      );

      return Object.assign({}, context, {
        discoveryApp,
        playerApp,
        authApp,
        gtmApp,
        appsFlyerApp,
        fsApp,
        watchPartyApp,
        discoveryStore: discoveryApp.getStore(),
      });
      // @ts-ignore
    },
    handleRouteChange: function(state, routeHandler) {
      state.authApp.setRouteHandler(routeHandler);
      state.gtmApp.setRouteHandler(routeHandler);
      return state;
    },
  },
);
