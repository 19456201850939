import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import datetime from 'src/domain/libs/datetime';
import { withModalContext } from 'src/apps/common/context/ModalContext';
import MainViewLink from 'src/apps/common/components/MainViewLink';
import { getPurchaseLabel } from 'src/utils/getPurchaseLabel';
import { Models } from 'src/types/app/models';
import SetDate from 'src/apps/bluerose/components/browse/SetDate';
import routes from 'src/apps/common/routes';
import ProductDetailModal from 'src/apps/common/components/modal/ProductDetailModal';
import activeProfile, { isFree } from 'src/utils/activeProfile';
import GtmApp, { CLICK_AREA, CUSTOM_EVENTS } from 'src/common/GtmApp';
import queryString from 'query-string';

import AppContext from 'src/types/context/App';
import { RouteHandler } from 'src/types/app/Route';

type ProductCardContext = {
  gtmApp: GtmApp;
  getModelData: AppContext.GetModelData;
  routeHandler: RouteHandler;
  models: Models;
};

const ProductCard = (props, context: ProductCardContext) => {
  const authContext = context.getModelData('authContext');
  const onClickPurchase = useCallback(() => {
    if (context.gtmApp) {
      if (props.showSubOnlyTvodUI) {
        context.gtmApp.pushDataLayerOnCustomClick(CUSTOM_EVENTS.TVOD_BUY_CLICK, {
          click_area: '商品ページ_ログイン＆加入済み_S限定TVOD',
        });
      } else {
        context.gtmApp.pushDataLayerOnTvodBuyClick(props.isProductPage, props.meta);
      }
    }
    if (props.product.onSale) {
      const returnTo = encodeURIComponent(context.routeHandler.url);
      props.doAction({ selected: { type: 'product', id: props.product.id }, returnTo });
    }
  }, [context.gtmApp, context.routeHandler, props]);

  const onClickLoginPurchase = useCallback(() => {
    if (context.gtmApp) {
      context.gtmApp.pushDataLayerOnTvodLoginClick(props.isProductPage, props.meta);
    }
  }, [context.gtmApp, props.isProductPage, props.meta]);

  const onClickTargetEpisode = useCallback(() => {
    if (context.gtmApp) {
      context.gtmApp.pushDataLayerOnTvodTargetEpisodeClick(props.meta);
    }
  }, [context.gtmApp, props.meta]);

  const onClickProductDetail = useCallback(
    product => {
      return () => {
        props.showModal(<ProductDetailModal onClickClose={props.closeModal} product={product} />);
      };
    },
    [props],
  );

  const handleRadioChange = event => {
    props.getSelectedProductId(event.target.value);
  };

  const label = getPurchaseLabel(props.product, props.meta);
  // 販売開始前
  const beforeSalesStartAt = useMemo(
    () => props.product.salesStartAt && datetime() < datetime(props.product.salesStartAt),
    [props.product.salesStartAt],
  );
  // 販売終了
  const beforeSalesEndAt = useMemo(() => props.product.salesEndAt && datetime() >= datetime(props.product.salesEndAt), [
    props.product.salesEndAt,
  ]);

  const salesPeriod = useMemo(() => {
    let _salePeriod = null;
    if (beforeSalesStartAt) {
      return <SetDate format="fullja" date={props.product.salesStartAt} txt=" から販売開始" />;
    } else if (beforeSalesEndAt) {
      _salePeriod = <SetDate format="fullja" date={props.product.salesEndAt} txt=" に販売終了" />;
    } else if (!!props.product.salesEndAt) {
      _salePeriod = <SetDate format="fullja" date={props.product.salesEndAt} txt=" まで" />;
    }
    return _salePeriod;
  }, [beforeSalesEndAt, beforeSalesStartAt, props.product.salesEndAt, props.product.salesStartAt]);

  const price = (
    <span className="price">
      <span>{props.product.activePricing.displayPaymentAmountWithoutUnit}</span>
      {props.product.activePricing.displayUnit}（税込み）
    </span>
  );

  const profile = activeProfile(context.models);
  const isFreeProfile = profile && isFree(profile);

  const signupLink = useMemo(() => {
    const SIGNUP_PATH = '/static/join/';
    const query = {};
    const pathname = _.get(window, 'location.pathname', '');
    query['return_to'] = pathname;
    const queryStr = _.isEmpty(query) ? '' : `?${queryString.stringify(query)}`;
    // 未ログインと未加入簿場合表示させる
    if (!profile || isFreeProfile) {
      return (
        <div className="plan-info__notice inside-product-card">
          月額契約中の方のみご購入いただけます。ご加入は
          {/*
           // @ts-expect-error TS2322 */}
          <MainViewLink
            href={`${SIGNUP_PATH + queryStr}`}
            className={'accent-color'}
            onClick={() => {
              context.gtmApp.pushDataLayerOnCustomClick(CUSTOM_EVENTS.SUBSCRIPTION_CLICK, {
                click_area: 'エピソード画面S導線 商品ページS導線',
              });
            }}
          >
            こちら
          </MainViewLink>
        </div>
      );
    } else {
      return null;
    }
  }, [context.gtmApp, isFreeProfile, profile]);

  const productCardItem = (
    <div className="plan-info__item ppv">
      <div className="plan-info__item__li product-btn">
        {props.isProductPage && props.showSubOnlyTvodUI && <div className="subscription-flow">{signupLink}</div>}
        <div className="plan-info__item__li__name">
          {props.product.name}
          {(props.isDisabled || props.product.salesLimitExceeded) && (
            <div className="plan-info__item__li__sub">※販売上限数に達しましたため、ご購入いただけません。</div>
          )}
        </div>
        <div className="plan-info__item__li__price">
          <div className="price">
            <span>{props.product.activePricing.displayPaymentAmountWithoutUnit}</span>
            {props.product.activePricing.displayUnit}（税込み）
          </div>
          {!props.multi ? (
            <div className="plan-info__item__li__price__btn-box">
              {!props.isProductPage ? (
                // @ts-expect-error TS2322
                <MainViewLink
                  to={routes.product}
                  params={{ id: props.product.id }}
                  className={'btn btn-fill grey-color program-btn'}
                  onClick={onClickTargetEpisode}
                >
                  商品詳細
                </MainViewLink>
              ) : null}
              {props.isDisabled ||
              props.product.salesLimitExceeded ||
              (props.product.salesEndAt && datetime() >= datetime(props.product.salesEndAt)) ? (
                <button type="button" className="btn btn-fill grey-color purchase-btn disabled">
                  販売終了しました
                </button>
              ) : beforeSalesStartAt ? (
                <button type="button" className="btn btn-fill purchase-btn disabled">
                  販売開始前です
                </button>
              ) : !authContext && !props.showSubOnlyTvodUI ? (
                // @ts-expect-error TS2322
                <MainViewLink href={'/login'} className="btn btn-fill purchase-btn" onClick={onClickLoginPurchase}>
                  {`ログインして${label}`}
                </MainViewLink>
              ) : (
                <button
                  type="button"
                  onClick={onClickPurchase}
                  className={classnames('btn btn-fill purchase-btn', {
                    disabled: !authContext || (isFreeProfile && props.showSubOnlyTvodUI) || !props.product.onSale,
                  })}
                >
                  {`${label}する`}
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="plan-info__item__li date">
        {salesPeriod ? (
          <div className="plan-info__item__li__date">
            <span>販売期間</span>
            {salesPeriod}
          </div>
        ) : null}
        {props.product.rights && (
          <div className="plan-info__item__li__date">
            <span>{props.product.rights[0].displayLimitType}</span>
            {props.product.rights[0].activeLimitType == 'viewTerm' ? <React.Fragment>{label}後</React.Fragment> : null}
            {props.product.rights[0].displayLimitValue}
            {props.product.rights[0].displayLimitUnit}
          </div>
        )}
        {props.product.productType == 'product_type_rental' && _.get(props.product, 'rights.0.exerciseTerm') ? (
          <div className="plan-info__item__li__date">
            <span>{props.product.rights[0].exerciseTermType}</span>
            {props.product.rights[0].exerciseTermFrom}
            {props.product.rights[0].exerciseTerm}
            {props.product.rights[0].exerciseTermUnit}
          </div>
        ) : null}
      </div>
      {!props.spMode && props.isProductPage && props.product.productDescription ? (
        <div className="plan-info__item__li">
          <div className="btn-container text-right">
            <button type="button" className="plan-description-btn" onClick={onClickProductDetail(props.product)}>
              <i className="fa-info-circle"></i>商品詳細
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
  if (props.multi) {
    return (
      <div className="sub-only-tvod-plan__item__inner__radio">
        <div className="radio-box">
          <input
            id={`product__${props.product.id}`}
            type="radio"
            value={`${props.product.id}`}
            name="check"
            className="radio-button"
            onChange={handleRadioChange}
            disabled={!profile || isFreeProfile}
            checked={
              props.selectedProduct
                ? profile && !isFreeProfile && props.product.id == props.selectedProduct.id
                : profile && !isFreeProfile && props.index == 0
            }
          />
          <label
            htmlFor={`product__${props.product.id}`}
            className={classnames('radio-button-label', { disabled: !profile || isFreeProfile })}
          >
            {productCardItem}
          </label>
        </div>
        {!props.isProductPage ? (
          // @ts-expect-error TS2322
          <MainViewLink
            to={routes.product}
            params={{ id: props.product.id }}
            className={'btn btn-fill grey-color program-btn'}
            onClick={onClickTargetEpisode}
          >
            商品詳細
          </MainViewLink>
        ) : null}
      </div>
    );
  } else {
    return productCardItem;
  }
};

ProductCard.contextTypes = {
  getModelData: PropTypes.func,
  routeHandler: PropTypes.object,
  models: PropTypes.object,
  gtmApp: PropTypes.object,
};

export default withModalContext(ProductCard);
