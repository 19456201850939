import React, { Component } from 'react';
import Falcor from 'falcor';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';
import GalleryContent from './GalleryContent';
import EpisodeCard from './EpisodeCard';
import TitleCard from './TitleCard';
import { CardContextProvider } from '../../../common/context/CardContext';

const indexes = function(models, options, props) {
  return {
    from: _.get(props, 'fromNum', 0),
    to:
      props && props.toNum
        ? props.toNum - (props.fromNum || 0)
        : options && options.numItems
        ? options.numItems
        : props.perPage - 1,
  };
};

type ProductEpisodeRowProps = {
  rightIds: number[];
  model: Falcor.Model;
  perPage?: number;
  fromNum?: number;
  toNum?: number;
  checkLinkMetas?: any;
  tvodDisplayUnit?: string;
};

export default class ProductEpisodeRow extends Component<ProductEpisodeRowProps> {
  private _isMounted: boolean;
  private totalCount: number;
  private numRowsToFetch: number;
  private galleryRef = React.createRef<HTMLDivElement>();
  private model: Falcor.Model;
  private items: any;

  static getPaths = function(models, options, props) {
    if (_.size(props.rightIds) <= 0) {
      return [];
    }
    const rootPath = this.getRootPath(models, options, props);
    let paths = [];
    paths = paths.concat([rootPath.concat(['length'])]);
    paths = paths.concat([
      rootPath.concat([
        indexes(models, options, props),
        [
          'id',
          'name',
          'shortName',
          'thumbnailUrl',
          'header',
          'free',
          'memberOnly',
          'entitledOnly',
          'titleMetaId',
          'resumePoint',
          'schemaId',
          'type',
        ],
      ]),
    ]);

    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (_.size(props.rightIds) > 0) {
      // @ts-ignore TS2339
      const rightIds = _.join(props.rightIds, ',');
      // @ts-ignore TS2339
      return ['rights', rightIds, 'metas', props.tvodDisplayUnit];
    }

    return [];
  };

  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      model: PropTypes.func.isRequired,
      rightIds: PropTypes.array.isRequired,
      perPage: PropTypes.number,
      fromNum: PropTypes.number,
      toNum: PropTypes.number,
      checkLinkMetas: PropTypes.func,
      tvodDisplayUnit: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      perPage: 60,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.galleryRef = React.createRef();

    this.sendToGtm = this.sendToGtm.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.model = props.model;
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    const metas = this.model.getSync(rootPath) || {};
    this.items = _.compact(_.values(_.omit(metas, ['$__path', 'length']))) || [];
    this.totalCount = metas.length;
    this.numRowsToFetch = props.perPage;

    this.state = {
      fetchingMoreRows: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (_.isEmpty(this.items)) {
      this.fetchData();
    } else if (this.props.checkLinkMetas) {
      this.props.checkLinkMetas(_.size(this.items));
    }

    browserEvents.addEventListener('scroll', this.onScroll);
  }
  componentWillUnmount() {
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();

    browserEvents.removeEventListener('scroll', this.onScroll);
  }

  isLoading() {
    // @ts-ignore TS2339
    return !!this.state.dispose;
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // this.context.gtmApp.pushDataLayerOnContentPageClick();
  }

  fetchData(props = this.props) {
    const fromNum = props.fromNum ? props.fromNum : 0;
    const pathProps = Object.assign({}, props, {
      fromNum: fromNum,
      toNum: this.numRowsToFetch - 1,
    });

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    this.setState({ dispose });
    const newState = {
      dispose: null,
      fetchingMoreRows: false,
    };
    evaluator
      .then(res => {
        const metas = _.get(res, ['json'].concat(rootPath)) || {};
        if (metas.hasOwnProperty('length')) {
          this.totalCount = metas.length;
        }

        const newMeta = _.omit(metas, ['$__path', 'length']);
        // @ts-ignore TS2339
        const uniqConcatMetas = _.uniqBy(_.concat(this.items, _.compact(_.values(newMeta))), 'id');
        // @ts-ignore TS2339
        this.items = _.map(uniqConcatMetas, data => _.omit(data, ['$__path'])) || [];
        // @ts-ignore TS2339
        this.numRowsToFetch += props.perPage;
        if (this.numRowsToFetch > this.totalCount) this.numRowsToFetch = this.totalCount;

        // @ts-ignore TS2339
        newState.fetchDataError = null;
        // @ts-ignore TS2339
        newState.generation = props.model.getVersion(paths);
        if (props.checkLinkMetas) {
          props.checkLinkMetas(_.size(this.items));
        }
      })
      .catch(e => {
        console.error(e);
        // @ts-ignore TS2339
        newState.fetchDataError = e;
        newState.dispose = null;
      })
      .finally(() => {
        delete this.state[JSON.stringify(paths)];
        this.setState(newState);
      });
  }

  fetchMoreRows() {
    // @ts-ignore TS2339
    if (this.items && this.totalCount > this.items.length && !this.state.fetchingMoreRows) {
      this.setState({ fetchingMoreRows: true }, () => {
        this.fetchData();
      });
    }
  }

  onScroll(e) {
    if (this.isWithinDistanceBuffer(300)) {
      this.fetchMoreRows();
    }
  }

  getChildContext() {
    return {
      textArea: 'description',
    };
  }

  isWithinDistanceBuffer(distanceBuffer) {
    if (!this.galleryRef) return;
    const el = this.galleryRef.current;
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }

  render() {
    if (_.isEmpty(this.props.rightIds) || _.isEmpty(this.items)) return null;

    const title = '対象番組';
    const cards = _.compact(
      _.map(this.items, (item, index) => {
        if (!item.id) return;
        if (this.props.tvodDisplayUnit == 'episode') {
          return (
            // @ts-ignore TS2322
            <EpisodeCard model={this.model} key={`episode_${item.id}_${index}`} rankNum={index} episodeId={item.id} />
          );
        }
        return (
          <TitleCard
            // @ts-ignore TS2322
            titleId={item.id}
            model={this.model}
            listCard={false}
            key={`title-${item.id}-${index}`}
            enableCardClick={true}
          />
        );
      }),
    );

    return (
      <div className="canvas-row on-card" ref={this.galleryRef}>
        <CardContextProvider value={this.getChildContext()}>
          {/*
           // @ts-expect-error TS2322 */}
          <GalleryContent
            header={
              <div className="gallery-header">
                <div className="title">
                  <span className="gallery-title">{title}</span>
                </div>
              </div>
            }
            model={this.model}
            // @ts-ignore TS2322
            titleSmallSize={true}
            hideAllWhenNoResults={true}
            showPopCardMylistButton={true}
            titleDisplaySetting={'media_only'}
          >
            {cards}
          </GalleryContent>
        </CardContextProvider>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.fetchingMoreRows && (
          <div className="gallery-spin-loader">
            <div className="loader-cont"></div>
          </div>
        )}
      </div>
    );
  }
}
