import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import WatchPartyApp from '../../../../common/WatchPartyApp';

class UserCount extends React.Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.count = 0;
    this.countUpdate = this.countUpdate.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.startInterval();
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    this.stopInterval();
  }
  startInterval() {
    // @ts-ignore TS2339
    this.interval = setInterval(this.countUpdate, 1000);
  }
  stopInterval() {
    // @ts-ignore TS2339
    if (this.interval) {
      // @ts-ignore TS2339
      clearInterval(this.interval);
      // @ts-ignore TS2339
      delete this.interval;
    }
  }
  countUpdate() {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    // @ts-expect-error TS2339
    const count = _.get(this.context.watchPartyApp, 'room.customData.userCount', 0);
    // @ts-ignore TS2339
    if (count !== this.count) {
      // @ts-ignore TS2339
      this.count = count;
      this.forceUpdate();
    }
  }
  render() {
    // @ts-ignore TS2339
    return this.count;
  }
}

class AudienceButton extends React.PureComponent {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      watchPartyApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    this._handleAudienceClick = this._handleAudienceClick.bind(this);

    this.state = {
      show: false,
    };
  }

  _handleAudienceClick() {
    // @ts-ignore TS2339
    if (this.state.show) {
      this.setState({ show: false });
    } else {
      // @ts-expect-error TS2339
      this.context.watchPartyApp
        .getUsers()
        .then(users => {
          this.setState({ show: true, users: users });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
  render() {
    // @ts-expect-error TS2339
    const userAttendLimit = this.context.watchPartyApp.getUserAttendLimit();

    return (
      <div className="view-element audience-wrapper" aria-label="視聴者数">
        <span onClick={this._handleAudienceClick}>
          視聴者数：
          <UserCount />/{userAttendLimit}
          {/*
           // @ts-ignore TS2339 */}
          <i className={classnames({ 'fa-caret_down': !this.state.show, 'fa-caret_up': this.state.show })}></i>
        </span>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.show ? (
          <div className="audience-list">
            {/*
             // @ts-ignore TS2339 */}
            {_.map(this.state.users, user => {
              return (
                <div key={`user-${user.id}`} className="audience-list-item">
                  {user.name}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default AudienceButton;
