import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import RoomClosed from './RoomClosed';
import RoomCreate from './RoomCreate';
import RoomEnd from './RoomEnd';
import RoomEntried from './RoomEntried';
import RoomCreated from './RoomCreated';
import RoomJoin from './RoomJoin';
import NoVacancy from './NoVacancy';

export default class WatchPartyModal extends Component {
  static get propTypes() {
    return {
      player: PropTypes.object,
      closeModal: PropTypes.func,
      showModal: PropTypes.func,
      handleWatchPartyModeChange: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      watchPartyApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this._handleRoomClosed = this._handleRoomClosed.bind(this);
    this._handleNoVacancy = this._handleNoVacancy.bind(this);
    this._handleDuplicateEntry = this._handleDuplicateEntry.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._roomCreatedBtn = this._roomCreatedBtn.bind(this);

    this.state = {
      roomClosed: false,
      noVacancy: false,
      duplicateEntry: false,
      isSubmittable: true,
    };
  }

  _handleRoomClosed() {
    this.setState({ roomClosed: true });
  }

  _handleNoVacancy() {
    this.setState({ noVacancy: true });
  }

  _handleDuplicateEntry() {
    this.setState({ duplicateEntry: true });
  }

  _handleClose() {
    // @ts-ignore TS2339
    this.props.closeModal();
  }

  // RoomCreatedで「終了」を押下した場合、主催者と参加者で出すモーダルが違う
  _roomCreatedBtn() {
    // @ts-ignore TS2339
    this.props.closeModal();
    setTimeout(() => {
      if (true) {
        // 主催者の場合はルーム作成、シェアを表示
        // @ts-ignore TS2339
        this.props.showModal(
          <RoomCreate
            // @ts-ignore TS2339
            closeModal={this.props.closeModal}
            // @ts-ignore TS2339
            linearModel={this.props.linearModel}
            // @ts-ignore TS2339
            titleModel={this.props.titleModel}
            // @ts-ignore TS2339
            seriesModel={this.props.seriesModel}
            // @ts-ignore TS2339
            handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
            // @ts-ignore TS2339
            watchPartyMode={this.props.watchPartyMode}
          />,
          { classes: 'watchParty-modal' },
        );
      } else {
        // 参加者の場合はルーム参加を表示
        // @ts-ignore TS2339
        this.props.showModal(
          <RoomJoin
            // @ts-ignore TS2339
            player={this.props.player}
            handleRoomClosed={this._handleRoomClosed}
            handleNoVacancy={this._handleNoVacancy}
            handleDuplicateEntry={this._handleDuplicateEntry}
            // @ts-ignore TS2339
            titleModel={this.props.titleModel}
            // @ts-ignore TS2322
            linearModel={this.props.linearModel}
            // @ts-ignore TS2339
            seriesModel={this.props.seriesModel}
            // @ts-ignore TS2339
            closeModal={this.props.closeModal}
            // @ts-ignore TS2339
            handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
          />,
          { classes: 'watchParty-modal' },
        );
      }
    }, 100);
  }

  render() {
    let content = null;
    // const userState = 'master'; // 主催者かどうか（仮）
    // @ts-ignore TS2339
    const userState = this.props.userState;

    // WatchParty使用不可
    // @ts-ignore TS2339
    if (!this.props.titleModel.wpEnableFlag) {
      content = (
        <React.Fragment>
          <div className="modal-title">ウォッチパーティを開始できません</div>
          <p className="modal-title text-left">
            申し訳ございませんが、こちらの作品は権利の関係により、ウォッチパーティの対象外となっております。
          </p>
          <div className="form-btn-block">
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this._handleClose}>
              閉じる
            </a>
          </div>
        </React.Fragment>
      );
    } else {
      // @ts-expect-error TS2339
      this.context.watchPartyApp.init();
      if (false) {
        // これは判断できない
        // すでに開始している、参加しているWatchPartyがあるか
        // 主催か参加者かはRoomCreatedで出し分け
        content = (
          <RoomCreated
            // @ts-ignore TS2339
            titleModel={this.props.titleModel}
            // @ts-ignore TS2339
            seriesModel={this.props.seriesModel}
            // @ts-ignore TS2322
            linearModel={this.props.linearModel}
            // @ts-ignore TS2339
            closeModal={this.props.closeModal}
            roomCreatedBtn={this._roomCreatedBtn}
            // @ts-ignore TS2339
            handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
            userState={userState}
          />
        );
        // @ts-ignore TS2339
      } else if (!this.props.watchPartyMode && userState == 'master') {
        // 主催者の場合
        // ルーム情報入力
        // ルームシェア
        content = (
          <RoomCreate
            // @ts-ignore TS2339
            titleModel={this.props.titleModel}
            // @ts-ignore TS2339
            seriesModel={this.props.seriesModel}
            // @ts-ignore TS2339
            linearModel={this.props.linearModel}
            // @ts-ignore TS2339
            closeModal={this.props.closeModal}
            // @ts-ignore TS2339
            handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
            // @ts-ignore TS2322
            userState={userState}
          />
        );
        // @ts-ignore TS2339
      } else if (!this.props.watchPartyMode && userState !== 'master') {
        // 参加者の場合
        // @ts-ignore TS2339
        if (this.state.roomClosed) {
          // ルームが終了した時
          // @ts-ignore TS2339
          content = <RoomClosed closeModal={this.props.closeModal} />;
          // @ts-ignore TS2339
        } else if (this.state.noVacancy) {
          // ルームへの参加（人数制限がすでに超えている）
          // @ts-ignore TS2322
          content = <NoVacancy room={this.context.watchPartyApp.room} closeModal={this.props.closeModal} />;
          // @ts-ignore TS2339
        } else if (this.state.duplicateEntry) {
          // すでにルームに参加した時
          content = (
            <RoomEntried
              // @ts-ignore TS2322
              room={this.context.watchPartyApp.room}
              // @ts-ignore TS2339
              closeModal={this.props.closeModal}
              userState={userState}
            />
          );
        } else {
          // ルームへの参加（ルームが終了していない、人数制限が超えていない）
          content = (
            <RoomJoin
              // @ts-ignore TS2339
              player={this.props.player}
              handleRoomClosed={this._handleRoomClosed}
              handleNoVacancy={this._handleNoVacancy}
              handleDuplicateEntry={this._handleDuplicateEntry}
              // @ts-ignore TS2339
              titleModel={this.props.titleModel}
              // @ts-ignore TS2339
              seriesModel={this.props.seriesModel}
              // @ts-ignore TS2322
              linearModel={this.props.linearModel}
              // @ts-ignore TS2339
              closeModal={this.props.closeModal}
              // @ts-ignore TS2339
              handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
            />
          );
        }
        // @ts-ignore TS2339
      } else if (this.props.watchPartyMode) {
        // 終了
        // 主催によるWatchParty終了と参加者による終了はRoomEndで出し分け
        content = (
          <RoomEnd
            // @ts-ignore TS2339
            closeModal={this.props.closeModal}
            // @ts-ignore TS2339
            handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
          />
        );
      }
    }

    return <React.Fragment>{content}</React.Fragment>;
  }
}
