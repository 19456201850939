import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import RowHeader from './RowHeader';
import Row from './Row';
import LoadingRow from './../loader/LoadingRow';
import LoadingTitle from './../loader/LoadingTitle';
import EventCard from './Epg/EventCard';

export default class SearchEventRow extends React.Component {
  static getPaths = function(models, options, props) {
    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum - (props.fromNum || 0)
            : options && options.numItems
            ? options.numItems
            : 11,
      };
    };
    // @ts-ignore TS2339
    return EventCard.getPaths()
      .map(path => {
        return this.getRootPath(models, options, props)
          .concat([indexes(models, options, props)])
          .concat(path);
      })
      .concat([this.getRootPath(models, options, props).concat(['length'])]);
  };

  static getRootPath = function(models, options, props) {
    const rootPath = [];
    if (props.keyEventPrefix) rootPath.push(props.keyEventPrefix);
    if (!!props.id) rootPath.push(props.id);
    if (!!props.term) {
      rootPath.push('byTerm');
      rootPath.push(`|${props.term}`);
    }
    if (props.filterType) rootPath.push(props.filterType);
    if (props.sortOrder) rootPath.push(props.sortOrder);
    if (props.searchFilter) rootPath.push([props.searchFilter]);
    return rootPath;
  };

  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    popType: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.maxItem = 40;

    // @ts-ignore TS2339
    this.isFirstSetOfResults = true;
    this.handleSliderMove = this.handleSliderMove.bind(this);

    this.state = {
      dispose: null,
      generation: -1,
      fetchDataError: null,
    };

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    const items = props.model.getSync(rootPath) || {};
    // @ts-ignore TS2339
    this.items = _.values(
      _.omitBy(items, (val, key) => {
        return key.indexOf('$') === 0 || key === 'length';
      }),
    );
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (nextProps.term !== this.props.term) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  handleSliderMove(e) {
    this.fetchData(this.props);
    // @ts-ignore TS2339
    if (this.props.onSliderMove) {
      // @ts-ignore TS2339
      this.props.onSliderMove(e);
    }
  }

  fetchData(props, context = this.context) {
    const pathProps = _.clone(props);
    // @ts-expect-error TS2339
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (this.items) {
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        // スマホの場合は全件なので分割して取得する
        // @ts-ignore TS2339
        pathProps.toNum = Math.min(this.items.length + 11, this.totalItems);
      } else {
        // @ts-ignore TS2339
        pathProps.toNum = this.maxItem - 1;
      }
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    evaluator
      .then(res => {
        const length = _.get(res, ['json'].concat(rootPath).concat('length'));
        // @ts-ignore TS2339
        this.items = _.clone(
          _.values(
            _.omitBy(_.get(res, ['json'].concat(rootPath)), (val, key) => {
              return key.indexOf('$') === 0 || key === 'length';
            }),
          ),
        );
        // スマホの場合は全件
        if (browserInfo.isIOS || browserInfo.isAndroid) {
          // @ts-ignore TS2339
          this.totalItems = length;
        } else {
          // @ts-ignore TS2339
          this.totalItems = Math.min(this.maxItem, length);
        }
        // @ts-ignore TS2339
        this.isFirstSetOfResults = false;
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState({
            fetchDataError: null,
            dispose: null,
            // @ts-ignore TS2339
            generation: this.state.generation + 1,
            loading: false,
          });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }

  render() {
    // @ts-ignore TS2339
    if (!this.items || _.isNil(this.items.length) || this.items.length == 0) {
      // @ts-ignore TS2339
      if (!this.state.dispose) {
        return null;
      }
      return <LoadingRow />;
    }
    // @ts-expect-error TS2339
    const userInfo = this.context.getModelData('userInfo');

    // @ts-ignore TS2339
    let totalItems = this.totalItems;

    const headerProps = {};
    // @ts-ignore TS2339
    headerProps.title = '同時配信';
    // headerProps.linkLabel = "すべて見る";
    // headerProps.query = {q: this.props.term};
    // headerProps.listContext = 'search';

    // @ts-ignore TS2339
    let items = this.items;
    let ignoredCount = 0;
    // @ts-ignore TS2339
    if (_.isNil(this.items) || items.length == 0) {
      return null;
    }

    // @ts-ignore TS2339
    if (!this.items || this.items.length === 0) {
      // @ts-ignore TS2339
      if (!this.state.dispose) {
        return null;
      }
      return <LoadingRow />;
    }

    const titles = _.compact(
      items.map((item, index) => {
        if (!item.uniqueId) return;
        return (
          <EventCard
            // @ts-ignore TS2322
            model={this.props.model}
            key={`event_${item.uniqueId}_${index}_${index}`}
            rankNum={index}
            // ellipsized={listContext !== 'nowonair'}
            rowNum={index}
            eventId={item.uniqueId}
            // @ts-expect-error TS2339
            popType={this.context.popType}
            itemData={item}
          />
        );
      }),
    );
    return (
      <div className={'canvas-row'}>
        <RowHeader {...headerProps} />
        <Row
          totalItems={totalItems}
          rowNum={1}
          handleSliderMove={this.handleSliderMove.bind(this)}
          // @ts-ignore TS2322
          listContext={'search'}
          loadingComponent={<LoadingTitle />}
          enablePaginationIndicator={false}
          enablePeek={true}
          enableLooping={false}
          enablePushOut={false}
        >
          {titles}
        </Row>
      </div>
    );
  }
}
